@font-face {
  font-family: 'sot-icom';
  src: url('../fonts/sot-icom.eot?tjopmw');
  src: url('../fonts/sot-icom.eot?tjopmw#iefix') format('embedded-opentype'),
    url('../fonts/sot-icom.ttf?tjopmw') format('truetype'),
    url('../fonts/sot-icom.woff?tjopmw') format('woff'),
    url('../fonts/sot-icom.svg?tjopmw#sot-icom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='sot_icon_'],
[class*=' sot_icon_'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sot-icom' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sot_icon_Bag:before {
  content: '\e900';
  /*color: #494b4c;*/
}
.sot_icon_Cash:before {
  content: '\e901';
  /*color: #494b4c;*/
}
.sot_icon_Location-Arrow-Circle:before {
  content: '\e902';
  /*color: #494b4c;*/
}
.sot_icon_Location-Pin:before {
  content: '\e903';
  /*color: #494b4c;*/
}
.sot_icon_Right-Chevron:before {
  content: '\e904';
  /*color: #494b4c;*/
}
.sot_icon_Scooter:before {
  content: '\e905';
  /*color: #494b4c;*/
}
.sot_icon_Search:before {
  content: '\e906';
  /*color: #494b4c;*/
}
.sot_icon_Store:before {
  content: '\e907';
  /*color: #494b4c;*/
}
.sot_icon_Table:before {
  content: '\e908';
  /*color: #494b4c;*/
}
.sot_icon_X:before {
  content: '\e909';
  /*color: #494b4c;*/
}
