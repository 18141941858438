.tabsSlider2--menuWrapper {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  /* padding: 0 16px; */
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.tabsSlider2--menuList {
  padding: 0;
  height: 46px;
  line-height: 56px;
  margin: 0;
  list-style: none;
  /* padding-left: 0px;
  margin-bottom: 0px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  display: flex; */
}

.tabsSlider2--menuUnderline {
  height: 2px;
  background-color: var(--second-theme-color);
  width: 0;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s,
    width 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  /* margin-left: 12.5px; */
}



.tabsSlider2--tabsWrapper {
  width: 100%;
  height: 100%;
  transform: translateY(50px);
  padding: 10px 0px;
  /* position: absolute; */
  /* top: 48px; */
  display: flex;
}

/* .tabsSlider2--tabsWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  margin: auto;
  padding-top: 100px;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}

@media (max-width: 960px) {
  .tabsSlider2--tabsWrapper {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
} */

.tabsSlider2--tab {
  overflow: hidden;
  width: 100%;
  /* height: calc(100% - 50px); */
  /* transform: translateY(-50px); */
}

.tabsSlider2--tab-transition {
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

/* @media (min-width: 768px) {
  .tabsSlider2--menuUnderline {
    margin-left: 12.5px;
  }
} */
/*
@media all and (min-width: 500px) and (max-width: 769px) {
  .tabsSlider2--menuUnderline {
    margin-left: 12.5px;
  }
} */


/* .itemDet.orderBtnP.modiferSection {
  padding-bottom: 66px;
}

.itemDet.modiferSection {
  padding-bottom: 80px;
} */
@media all and (max-width: 769px) {
  .singleSize.itemDet.modiferSection {
    bottom: -48px;
  }
  .itemDet.singleSize.orderBtnP.modiferSection {
    bottom: 0px;
  }
}
@media all and (min-width: 769px) {
  .itemDet.orderBtnP.modiferSection {
    padding-bottom: 66px;
  }

  .itemDet.modiferSection {
    padding-bottom: 80px;
  }
  .itemDet.orderBtnP.modiferSection .proceedOrder {
    /* padding-top: 4px; */
    padding-bottom: 22px;
  }
}

.Collapsible__trigger.is-open::after {
  display: none;
}

.itemDet:not(.modiferSection) {
  padding-top: 30px;
}

@media all and (min-width: 1023px) {
  .itemDet:not(.modiferSection) .qViewItem:last-child {
    margin-bottom: 0;
  }
}
.itemDet:not(.modiferSection) .quantityView {
  /* margin-top: 26px; */
}
