@tailwind base;
@tailwind components;

@import './UI/GeosuggestUI/geosuggest-ui-style.css';

@tailwind utilities;

@layer base {
  body {
    font-family: 'Inter', Arial, sans-serif;
  }

  @import '@next-order/next-order-components/dist/preset/theme-cw.css';
}
