:root {
  --main-theme-color: #e25a28;
  --second-theme-color: #5e7462;

  --main-text-color: #4b4a4a;
  --main-font: 'Inter', sans-serif;
  --old-font-Roboto: var(--main-font);
  --old-font-HelveticaNeue: var(--main-font);
  --old-font-LucidaConsole: var(--main-font);
  --old-font-Tahoma: var(--main-font);
  --old-font-Menlo: var(--main-font);

  --old-font-thin: 100;
  --old-font-extralight: 200;
  --old-font-light: 300;
  --old-font-normal: 400;
  --old-font-medium: 500;
  --old-font-semibold: 600;
  --old-font-bold: 700;
  --old-font-black: 900;

  /* by how many units should Inter letter spacing be reduced so that it corresponds to Robot */
  --tracking-offset-Roboto-thin: 0.03em; /* Roboto 1  -> Inter -0.03em */
  --tracking-offset-Roboto-light: 0.035em;
  --tracking-offset-Roboto-normal: 0.034em;
  --tracking-offset-Roboto-medium: 0.034em;
  --tracking-offset-Roboto-bold: 0.043em;
  --tracking-offset-Roboto-black: 0.052em;

  --tracking-offset-Tahoma-bold: 0.015em;

  --tracking-offset-HelveticaNeue-light: 0.037em;
  --tracking-offset-HelveticaNeue-normal: 0.03em;
  --tracking-offset-HelveticaNeue-medium: 0.018em;
  --tracking-offset-HelveticaNeue-bold: 0.014em;

  --current-tracking-Roboto: 0em;
  --current-tracking-HelveticaNeue: 0em;
  --current-tracking-LucidaConsole: 0em;
  --current-tracking-Tahoma: 0em;
  --current-tracking-Menlo: 0em;

  /*

    --old-font-Roboto:  'Roboto', Arial, sans-serif;
  --old-font-HelveticaNeue: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --old-font-LucidaConsole: 'Lucida Console', Monaco, monospace;
  --old-font-Tahoma: Tahoma, Verdana, sans-serif;
  --old-font-Menlo: Menlo, Monaco, Consolas, 'Courier New', monospace;

    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-thin, 0em));
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em));
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em));
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em));
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em));
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-black, 0em));

    letter-spacing: calc(var(--current-tracking-Tahoma) + var(--tracking-offset-Tahoma-bold, 0em));

    letter-spacing: calc(var(--current-tracking-HelveticaNeue) - var(--tracking-offset-HelveticaNeue-light, 0em));
    letter-spacing: calc(var(--current-tracking-HelveticaNeue) - var(--tracking-offset-HelveticaNeue-normal, 0em));
    letter-spacing: calc(var(--current-tracking-HelveticaNeue) - var(--tracking-offset-HelveticaNeue-medium, 0em));
    letter-spacing: calc(var(--current-tracking-HelveticaNeue) - var(--tracking-offset-HelveticaNeue-bold, 0em));
     */
}

.header-link {
  margin: 0px 5px;
  font-size: 13.42px;
}

a:focus,
a:hover {
  text-decoration: none;
}

html {
  /*height: 100%;*/
  margin: 0;
  padding: 0 0 0 0;
  font-size: 10px;
  /*overflow: hidden;*/
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  /*height: 100%;*/
  /*overflow: hidden;*/
  font-size: 1em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  margin-bottom: 3px;
}

#root {
  /*height: 100%;*/
}

#root > div {
  /*height: 100%;*/
}

#root > div > div {
  /*height: 100%;*/
  /*display: flex;*/
  /*flex-flow: column;*/
}

#root > div.dinein {
  height: auto;
}

.robMedium {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.robLight {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

/* .datefield-wrapper {
  position: relative;
} */

.datefield-wrapper input {
  width: 100%;
}

input#actual-date {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

nav.navbar.topnav.navbar-fixed-top.bgwhite {
  position: relative;
  /* padding: 19px 0 0; */
  background-color: #fff;
  border: 0 none;
}

.logoWrap {
  /* position: relative; */
  /* top: -10px; */
  width: 200px;
  max-width: 200px;
  height: 48px;
}

img.logo {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: left center;
}

.nav a.headimg {
  max-width: 98px;
  padding: 0;
}

.nav li a:focus,
.nav li a:hover {
  background-color: transparent;
}

.headimg img {
  width: 100%;
  height: auto;
  display: block;
}

.navbar-collapse.collapse {
  visibility: unset;
}

ul.nav.pull-right {
  font-size: 1.342em;
  margin-top: 3px;
  transition: margin-top ease 0.5s;
}

.nav.pull-right li a {
  color: #4b4a4a;
  --current-tracking-Roboto: 0.039em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.nav.pull-right li.dropdown a {
  padding: 0;
  margin-left: 29px;
}

li.dropdown {
  margin-top: 6px;
}

.navWrap ul li a {
  text-transform: uppercase;
  font-size: 1.11em;
  --current-tracking-Roboto: 0.039em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  border: 0 none;
  padding: 0;
  margin: 0;
  color: #4b4a4a;
  transition: all 0.5s ease 0s;
  padding-bottom: 13px;
  position: relative;
}

.navWrap ul li a:after {
  content: '';
  background: var(--second-theme-color);
  position: absolute;
  width: 100%;
  left: 0px;
  height: 2px;
  bottom: -2px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}

.navWrap ul li a:hover:after,
.navWrap ul li.active a:after {
  transform: scale(1);
}

.navWrap ul.nav {
  border: 0 none;
  float: left;
}

.navWrap ul.nav li {
  margin: 0 29px 0 0;
  float: left;
  width: auto;
}

.navWrap ul.nav li:last-child {
  margin: 0;
}

.navWrap ul.nav li a:focus,
.navWrap ul.nav li.active a:focus {
  border: 0 none;
  /* color: var(--second-theme-color); */
  background-color: transparent;
  border-radius: 0;
}

.navWrap ul li.active a,
.navWrap ul li a:hover,
.nav-tabs li.active a,
.nav-tabs li.active a:hover {
  border: 0 none;
  color: var(--second-theme-color);
  background-color: transparent;
}

.itemWrap {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  /* margin-top: 26px */
}

.col-md-8.customWidth {
  width: 67.9356%;
  /* transition: width 0.5s ease-out; */
  overflow: hidden;
  z-index: 1;
}

.tabsSlider--tab .boxWrapper.itemBoxes,
.itemBoxes {
  padding: 0 5px;
  float: left;
  width: 20%;
  min-height: 200px;
  cursor: pointer;
  background-color: #fff;
  margin-top: 0px !important;
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-size: 10px;
}

img.proimg {
  width: 100%;
  height: auto;
  display: block;
  /* min-height: 150px; */
  visibility: hidden;
  max-width: 100%;
}

.imgWrap {
  position: relative;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top left;
  transition: background 0.6s ease-in-out;
}

.imgWrap:after {
  content: '';
  display: block;
}

.prodInfo {
  bottom: 0;
  padding: 10px 5px 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 96%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 96%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 96%,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
}

.prodPrice {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  font-size: 10.77px;
  background-color: #fff;
  display: inline-block;
  padding: 1px 2px 0px;
  border-radius: 4px;
  line-height: 11px;
  --current-tracking-Roboto: 0.013em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  margin: 0 0 5px 0;
  border: 1px solid #fff;
}

.prodname {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-black);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.8em;
  line-height: 18.5px;
  padding-bottom: 5px;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-black, 0em)
  );
}

.extras-title {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-black);
  color: #4b4a4a;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 18.5px;
  padding-top: 15px;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-black, 0em)
  );
}

span.innerIng {
  font-size: 1.18em;
  line-height: normal;
  padding-bottom: 21px;
  display: block;
  padding-top: 6px;
  min-height: 57px;
  --current-tracking-Roboto: 0.35px;
  word-break: break-word;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.col-md-4.customBar {
  width: 31.805%;
  box-shadow: -2px -2px 4px 0 rgba(0, 0, 0, 0.1);
  right: 0;

  position: fixed;
  height: 100%;
  z-index: 2;
}

.stickyTitle {
  font-size: 3em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  line-height: normal;
  --current-tracking-Roboto: 0.039em;
  display: inline-block;
  margin-bottom: 18px;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
}

.fright li {
  list-style-type: none;
  display: inline-block;
  margin-bottom: 17px;
  margin-right: 23px;
}

ul.fright {
  float: right;
  padding: 0;
  margin-bottom: 0;
  margin-top: 5px;
  position: relative;
}

.fright__item {
  font-size: 1.191em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  text-transform: uppercase;
  line-height: normal;
  color: #4b4a4a;
  --current-tracking-Roboto: 0.039em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  padding-bottom: 11px;
  position: relative;
  display: block;
}

.fright__item:hover,
.fright li.active .fright__item {
  color: var(--second-theme-color);
}

.fright__item:hover svg path,
.fright li.active .fright__item svg path {
  fill: var(--second-theme-color);
}

.fright__item .sideicon {
  font-size: 19px;
  margin-right: 6px;
  position: relative;
  top: 4px;
}

.fright__item .sideicon.svgicon {
  top: 0px;
}

.fright li:last-of-type {
  margin-right: 0;
}

.pickupbox {
  font-size: 1.1em;
  clear: both;
  float: left;
  width: 100%;
  border: 1px solid #dbdbdb;
  --current-tracking-Roboto: 0.039em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  border-radius: 4px;
  line-height: normal;
  margin-bottom: 22px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.75s ease 0s;
  position: relative;
  z-index: 1;
}

.pickupboxInnerRight {
  width: calc(100% - 123px);
  float: left;
  display: table;
  padding: 8px 7px 9px 6px;
}

.pickupboxInnerLeft {
  float: left;
  padding: 8px 7px 6px 10px;
  display: table;
  width: 123px;
  border-left: 1px solid #dbdbdb;
  position: relative;
}

.pickupboxInnerRight > div,
.pickupboxInnerLeft div.pickImg,
.pickupboxInnerLeft div.pickName {
  display: table-cell;
  vertical-align: middle;
}

.pickOrOrderWrap {
  clear: both;
}

.pickImg {
  display: flex;
  font-size: 1.545em;
  padding-left: 3px;
  width: 26px;
}

.pickImg span {
  display: block;
}

.cItemImg img {
  width: 100%;
  height: auto;
  min-width: 59px;
  display: block;
  pointer-events: none;
}

td.cItemName {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 0 10px;
  width: 34.5%;
}

span.cItemPrice {
  display: block;
  font-size: 0.855em;
  margin-top: 7px;
  color: #4b4a4a;
}

td.cItemImg {
  width: 15.83%;
}

td.cItemQuantity {
  width: 49.67%;
  text-align: right;
}

.cItemMoreInfo > div {
  display: table-cell;
  vertical-align: middle;
}

tr.cartRow > td {
  padding-bottom: 24px;
  line-height: normal;
  vertical-align: top;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.btnGropWrap > * {
  display: inline-block;
}

.cItemCategory {
  font-size: 1.4em;
  width: 53.85%;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

span.cartCount {
  font-size: 1.2em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  width: 30px;
  text-align: center;
  position: relative;
}

.btnGropWrap {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  padding-left: 22px;
  white-space: nowrap;
}

.cItemMoreInfo:not(:only-child):not(:first-child) {
  margin-top: 9px;
}

.sidefixe1 {
  overflow-y: auto;
}

.cItemMoreInfo {
  display: flex;
  width: 100%;
}

.totalbottom {
  padding-top: 17px;
  padding-bottom: 13px;
}

.spName,
.spPrice {
  font-size: 1.551em;
  display: inline-block;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  line-height: normal;
}

span.spPrice {
  float: right;
}

span.spName {
  float: left;
}

.spWrap,
.spTotal {
  float: left;
  width: 100%;
  padding: 0 13px;
}

.spTotal > span.spName {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
}

.spTotal > span.spPrice {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.confirmBtn {
  display: block;
  width: 100%;
  font-size: 1.713em;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  background-color: var(--main-theme-color);
  clear: both;
  color: #fff;
  text-align: center;
  line-height: normal;
  padding: 10px 5px;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.diableConfirm {
  opacity: 0.3;
  cursor: not-allowed;
}

.spWrap {
  margin-bottom: 14px;
}

.spTotal {
  margin-bottom: 23px;
}

.shadowIt {
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1); */
}

.fRight {
  float: right;
  margin-right: 1px;
}

.fIcon {
  margin-right: 13px;
}

a.term {
  color: #4b4a4a;
  margin-top: 1px;
  display: inline-block;
}

a.fb {
  display: inline-block;
  margin-left: 9px;
}

li.pr0:last-child {
  margin-left: 1px;
}

.pr0 img.hicon {
  margin-top: -2px;
}

.customBar.tempClass {
  top: 0 !important;
}
/* =============> need to remove <============= */
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease;
}

.example-exit {
  opacity: 0;
}

.example-exit.example-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease;
}

.deliverySearchBox input {
  border: 0 none;
  display: inline-block;
  line-height: normal;
  font-size: 1.273em;
  --current-tracking-Roboto: 0.032em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  width: 100%;
  padding: 4px 44px 4px 5px;
  color: #4b4a4a;
  text-overflow: ellipsis;
}

input:focus {
  outline: none;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #4b4a4a;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #4b4a4a;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: #4b4a4a;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #4b4a4a;
}

.pickupboxInnerLeft > div.accordionMain {
  width: auto;
  right: -1px;
  z-index: 1;
  display: block;
  position: absolute;
  top: 100%;
  height: 0;
  transition: all 0.5s ease 0s;
  background-color: #fff;
  visibility: hidden;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  left: -1px;
  border-radius: 0 0 4px 4px;
}

.pickupboxInnerLeft > div.accordionMain:after,
.searchBar:after {
  display: block;
  content: '';
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 10px;
  bottom: 0;
  right: 0;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.54) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.54) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.54) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#8affffff', GradientType=0);
}

.deliveryAccordionWrap {
  cursor: pointer;
}

.pickupboxInnerLeft > div.accordionMain.active {
  height: 225px;
  visibility: visible;
}

.pickupbox.active {
  border-radius: 4px 4px 0 4px;
}

span.rotateAccordion {
  width: 15px;
  height: 15px;
  overflow: hidden;
  float: right;
  top: -8px;
  position: relative;
  right: -5px;
  transform: rotate(180deg);
  transition: transform 0.5s ease 0s;
  font-size: 8px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  border: 0px solid transparent;
  color: #4b4a4a;
}

span.rotateAccordion i.thickcon-Up-Chevron {
  position: relative;
  top: 3px;
}

span.rotateAccordion img {
  width: 100%;
  height: auto;
}

.deliveryWrap .pickupboxInnerRight {
  padding-top: 9px;
}

.deliveryWrap .pickupboxInnerLeft {
  width: 123px;
  padding-right: 0;
  padding-bottom: 6px;
}

.active span.rotateAccordion,
span.rotateAccordion--active {
  transform: rotate(0deg);
}

.tab-content {
  height: 0;
  overflow: hidden;
  background: #fff;
  transition: height 0.35s ease;
}

.tab input:checked ~ .tab-content {
  height: auto;
}

.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tab label {
  text-align: center;
  width: 100%;
  margin-bottom: 0;
  border-bottom: 1px solid #f9f9f9;
  position: relative;
  padding: 12px 0 10px 0;
  cursor: pointer;
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.tab {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  padding: 0 6px;
  font-size: 1.283em;
  line-height: normal;
}

.tab:last-child label {
  border-bottom: 0 none;
}

.tab i {
  margin-left: 7px;
  position: absolute;
  top: 16px;
  font-size: 12px;
  transition: all 0.5s ease 0s;
}

.tab input:checked ~ label i {
  transform: rotate(180deg);
}

ul.subTime {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.subTime li {
  padding: 12px 0 10px 0;
  border-bottom: 1px solid #f9f9f9;
  cursor: pointer;
}

.subTime a {
  color: #4b4a4a;
}

.subTime div {
  color: #4b4a4a;
}

.tabWrapMain::-webkit-scrollbar,
.searchBar .geosuggest__suggests::-webkit-scrollbar {
  display: none;
}

.tabWrapMain {
  overflow-y: scroll;
  height: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
}

div.accordionMain.active .tabWrapMain {
  visibility: visible;
  height: 223px;
}

.tab:last-child input:checked ~ label {
  border-bottom: 1px solid #f2f2f2;
}

.deliveryWrap .pickupboxInnerRight .pickImg {
  padding-left: 3px;
  width: 24px;
}

.deliveryAccordionWrap .pickImg {
  font-size: 1.818em;
  width: 27px;
}

.loginWrapPop,
.reviewWrapPop,
.reservationWrapPop {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -9;
  width: 282px;
  background-color: #fff;
  right: 14px;
  border: 1px solid #dbdbdb;
  min-height: 297px;
  top: -9999px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.loginWrapPop.active,
.reviewWrapPop.active,
.reservationWrapPop.active {
  visibility: visible;
  opacity: 1;
  z-index: 2;
  top: 30px;
}

.loginWrapPop:before,
.loginWrapPop:after,
.reviewWrapPop:before,
.reviewWrapPop:after,
.reservationWrapPop:after,
.reservationWrapPop:before {
  right: 13px;
  left: auto;
  border-bottom: 10px solid #dbdbdb;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: '';
  display: inline-block;
  position: absolute;
  top: -10px;
}

.dropdown .loginWrapPop:after,
.dropdown .reviewWrapPop:after,
.dropdown .reservationWrapPop:after {
  top: -9px;
  border-bottom-color: white;
}

button#onebtn {
  border: 0 none;
  background-color: #f54c02;
  width: 36px;
  height: 36px;
  position: absolute;
  border-radius: 18px;
  right: 16px;
  bottom: 13px;
}

img.btnimg {
  width: 12px;
  display: inline-block;
  top: -1px;
  position: relative;
  right: -1px;
}

.loginTitle {
  font-size: 1.49031em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: 26px;
  margin-bottom: 42px;
  --current-tracking-Roboto: 0.031em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

div#one {
  padding: 37px 17px;
  position: relative;
  height: 295px;
}

.mobileTitle {
  font-size: 0.81967em;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 27px;
  --current-tracking-Roboto: 0.04em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

input.loginTextbox {
  border-width: 0 0 1px 0;
  width: 100%;
  border-style: solid;
  border-color: #f2f2f2;
  padding: 0 0 3px;
  font-size: 1.993em;
  text-align: left;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  --current-tracking-Roboto: 0.07em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

button:focus {
  outline: 0 none;
}

.sec2 {
  height: 100%;
  width: 100%;
  padding: 37px 17px;
  position: absolute;
  right: 0;
  background-color: #fff;
  top: 0;
  z-index: -15;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.sec2.active {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.sec2 form span {
  display: block;
  float: left;
  padding: 0 4.5px;
  width: 33.33%;
}

.sec2 form > div {
  overflow: hidden;
  width: 50%;
  float: left;
}

.sec2 form > div:first-child {
  padding-right: 11px;
}

.sec2 form > div:nth-child(2) {
  padding-left: 11px;
}

.sec2 .loginTitle {
  margin-bottom: 79px;
}

.reviewInner img:first-child {
  margin-left: 11px;
}

.reviewInner img {
  width: 15px;
  height: auto;
  margin-left: 4px;
  margin-top: -3px;
}

.reviewInner > span {
  font-size: 1.49031em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: 26px;
  --current-tracking-Roboto: 0.031em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.reviewInner {
  padding: 36px 16px 0 14px;
}

.reviewWrapPop {
  right: 3px;
}

.reviewScroller {
  font-size: 0.931445em;
  line-height: normal;
  margin-top: 18px;
  height: 215px;
  overflow-y: auto;
}

.commentTitle span {
  font-size: 0.88em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  margin-left: 9px;
}

.commentTitle {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.031em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  margin-bottom: 7px;
}

.commentDesc {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  --current-tracking-Roboto: 0.025em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  padding-bottom: 9px;
  line-height: 16.5px;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}

.commentWrap:last-child .commentDesc {
  margin-bottom: 0;
  border-bottom: 0 none;
}

.autoSuggestionWrap {
  position: absolute;
  right: -1px;
  border-radius: 0 0 4px 4px;
  top: 39px;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  border: 1px solid #dbdbdb;
  left: -1px;
  background-color: #fff;
  transition: opacity 0.5s ease;
  padding: 0px 12px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

ul.autoSuggestionWrap {
  list-style-type: none;
  margin: 0;
}

ul.autoSuggestionWrap li:first-child {
  padding-top: 14px;
}

.pickupboxInnerRight ul.autoSuggestionWrap li:first-child:before {
  top: 14px;
}

ul.autoSuggestionWrap li {
  font-size: 1.273em;
  line-height: normal;
  --current-tracking-Roboto: 0.032em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  padding-bottom: 20px;
  cursor: pointer;
  position: relative;
  padding-left: 23px;
}

ul.autoSuggestionWrap li:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  content: '\e90e';
  margin-right: 9px;
  margin-bottom: 0px;
  position: absolute;
  left: 0;
  top: 2px;
}

ul.autoSuggestionWrap li span.icon-Location-Pin {
  margin-right: 5px;
  position: relative;
  top: -1px;
}

ul.autoSuggestionWrap li:last-child {
  padding-bottom: 15px;
}

/*-- Mozilla Specific css fixes--*/

@-moz-document url-prefix() {
  .prodPrice {
    line-height: 10px;
    padding: 1px 2px;
  }
}

.autoSuggestionWrap.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
  visibility: hidden;
  opacity: 0;
  top: -9999px;
}

.orderViews {
  position: absolute;
  top: 0;
  background-color: #fff;
  z-index: 99;
  padding-top: 96px;
  bottom: 2px;
  right: auto;
  /* left: 100%; */
  left: auto;
  transform: translateX(100%);
  /* transition: all 0.2s ease 0.2s; */
  transition: transform 0.2s ease 0.2s;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.orderViews.smallHeader {
  padding-top: 78px;
}

.orderViews.active {
  /* left: 0; */
  transform: translateX(-20px);
}

.imgView img {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgView {
  height: calc(100% - 240px);
}

.itemDet {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  position: absolute;
  bottom: -81px;
  width: 100%;
  background-color: #fff;
  min-height: 265px;
  z-index: 1;
  padding-bottom: 99px;
  transition: all 0.1s ease;
}
.quantityView {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

span.nameView,
span.ingView {
  display: block;
}

span.nameView {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  text-transform: capitalize;
  line-height: 28px;
  font-size: 2.542em;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  margin-bottom: 3px;
  /* font-weight: normal; */
}

span.ingView {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  font-size: 1.534em;
  line-height: 20px;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  /* max-width: 270px; */
}

.itemView hr,
.selectSizes hr {
  margin: 0px 0 17px;
  background: #e7e7e7;
}

span.viewTitle {
  display: inline-block;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  font-size: 2.108em;
  line-height: 28px;
  background-color: #fff;
  padding-right: 14px;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.quantityView {
  margin-top: 16px;
}

.qViewItem {
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  line-height: normal;
  float: left;
  width: 100%;
  margin-bottom: 17px;
}

.itemView .btnGropWrap {
  padding-left: 0;
  font-size: 10px;
  float: right;
  position: relative;
  top: -1px;
}

.qViewItem > * {
  float: left;
  font-size: 1.62em;
  line-height: normal;
}

.qCategory.robMedium {
  min-width: 84px;
  padding-right: 10px;
}

.qCategory.robMedium.gFree {
  /* width: 165px; */
}

.itemView span.cartCount {
  font-size: 1.384em;
  width: 50px;
}

.halfBox {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: #fff;
  overflow: hidden;
  transition: all 0.2s ease;
}

.halfHalfTitle {
  margin-left: 42px;
}

.order-items.small-header > div {
  position: absolute;
  width: 100%;
  top: 78px;
  height: calc(100% - 78px);
  background-color: #fff;
  overflow: hidden;
  transition: all 0.2s ease;
}

.order-items > div:not(.example1-exit) {
  z-index: 99 !important;
}

.itemView {
  height: 100%;
}

.example1-enter {
  left: 100%;
  z-index: 99;
}

.example1-enter.example1-enter-active {
  left: 0;
  z-index: 99;
}

.order-items > div.example1-exit {
  opacity: 1;
  transition: all 0.5s ease 0.1s;
}

.order-items > div.example1-exit.example1-exit-active {
  opacity: 0;
}

/*@media all and (max-height: 768px) {
  .imgView img {
    top: -125%;
  }
}*/

.closeBack {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  background-color: rgba(72, 72, 72, 0.52);
  color: #fff;
  font-size: 1.8em;
  line-height: normal;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  top: 21px;
  left: 21px;
  border-radius: 16px;
  padding: 3px;
  --current-tracking-Roboto: 0.017em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.closeBack i.thickcon-X {
  position: relative;
  top: 1px;
  font-size: 17px;
  margin-right: 4px;
  margin-left: 4px;
}

.clickBtn {
  font-size: 1.329em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: 21px;
  display: inline-block;
  border: 1px solid #e1e5e5;
  --current-tracking-Roboto: 0.017em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  padding: 2px 10px;
  margin-right: 15px;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
}

.clickBtn {
  width: 50%;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  padding-top: 12px;
  padding-bottom: 11px;
  text-align: center;
  border-bottom: 0 none;
  border-left: 0 none;
  font-size: 1.628em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}
.clickBtn.full {
  width: 100%;
}

.editIng.clickBtn {
  border-right: 0 none;
}

.customiseView {
  position: relative;
  transition: all 0.2s ease;
  bottom: -19px;
  clear: both;
  margin: 2px -26px 0px;
  border-bottom: 1px solid #e1e5e5;
}

.itemDet.orderBtnP .customiseView {
  bottom: -30px;
}
/* .itemView hr,
.customiseView span.viewTitle {
  display: none;
} */

/* .glutenFree {
  float: right;
  font-size: 1.593em;
  --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em));
  line-height: 22.5px;
  margin-top: 3px;
} */

span.iconWala {
  font-size: 14.5px;
  width: 21px;
  height: 21px;
  text-align: center;
  display: inline-block;
  background-color: #fff;
  border: 1px solid var(--main-theme-color);
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  padding-top: 3px;
  margin-left: 22px;
  position: relative;
  top: -2.5px;
}
.glutenFree > img {
  width: 9px;
  height: auto;
  position: relative;
  top: -1px;
  margin-right: 4px;
  left: 1px;
}

span.iconWala.active {
  color: white;
  background-color: var(--main-theme-color);
}

.orderPrice {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  font-size: 2.1em;
  line-height: normal;
  --current-tracking-Roboto: 0.017em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  min-width: 86px;
  width: 22%;
  /* padding-left: 15px; */
}

.proceedOrder {
  display: table;
  width: 100%;
  padding-top: 15px;
  /* margin-top: 18px; */
  /* border-top: 1px solid #eeeeee; */
  padding-bottom: 25px;
}

.proceedOrder > div {
  display: table-cell;
  vertical-align: middle;
}

.proceedBtn {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: normal;
  font-size: 1.713em;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  background-color: var(--main-theme-color);
  color: #fff;
  text-align: center;
  padding: 9px 0 9px 0;
  border-radius: 8px;
  border: 1px solid var(--main-theme-color);
  cursor: pointer;
}

.proceedWrap {
  position: absolute;
  bottom: -12px;
  right: 20px;
  left: 20px;
  z-index: 110;
  background-color: #fff;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.itemDet.active {
  bottom: 0;
  /* min-height: 330px; */
}

.itemDet.orderBtnP {
  bottom: 0;
}

.reservationWrapPop {
  right: 3px;
}

.dateTimeRes span {
  font-size: 1.23em;
  line-height: normal;
  --current-tracking-Roboto: 0.031em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  display: inline-block;
}

.dateTimeRes {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 7px;
  margin-bottom: 14px;
}

.dateTimeRes span:first-child {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.dateTimeRes span:last-child {
  color: #f54c02;
  float: right;
  cursor: pointer;
  font-size: 1.155em;
  margin-top: 1px;
}

.reservationWrap {
  padding: 43px 17px 17px;
}

.reservationWrap.active {
  padding-bottom: 36px;
}

.resTable {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.noOfPep {
  display: flex;
  flex-flow: row nowrap;
}

.pepDesc,
.pepInc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--main-theme-color);
  color: var(--main-theme-color);
  border-radius: 16px;
  position: relative;
  font-size: 8px;
  cursor: pointer;
}

.pepCount {
  width: 50px;
  text-align: center;
  font-size: 1.379em;
  line-height: 32px;
}

.typeOfPep > span {
  flex-flow: row nowrap;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  font-size: 0.7em;
  --current-tracking-Roboto: 0.052em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  color: rgba(0, 0, 0, 0.6);
  margin-top: 6px;
}

.typeOfPep {
  line-height: normal;
  font-size: 1.5em;
  --current-tracking-Roboto: 0.031em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.resTable:last-child {
  border-bottom: 1px solid #f2f2f2;
}

.dateTimeCon {
  font-size: 1.062em;
  line-height: normal;
  margin-top: 10px;
  cursor: pointer;
  --current-tracking-Roboto: 0.031em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.dateTimeCon > span:last-child {
  float: right;
  color: #f54c02;
}

.reSend {
  font-size: 1.118em;
  line-height: normal;
  color: #5e7361;
  --current-tracking-Roboto: 0.07em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  position: absolute;
  bottom: 21px;
  z-index: 1;
  cursor: pointer;
}
.cMinusPlusBtn > svg,
.cMinusPlusBtn > img {
  width: 15px;
  height: auto;
  position: relative;
  top: 4px;
  right: 4px;
  vertical-align: top;
  display: inline-block;
}

.cMinusPlusBtn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid var(--main-theme-color);
  position: relative;
}

.icon-button-group {
  width: 15px;
  height: auto;
  display: inline-block;
  fill: var(--main-theme-color);
}

.to-right {
  right: 4px;
}

.pepInc img,
.pepDesc img {
  height: auto;
  width: 18px;
  position: relative;
  top: 6px;
  right: 6px;
}

.itemView .cMinusPlusBtn > svg,
.itemView .cMinusPlusBtn > img {
  right: -4px;
}

.extraScreen {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  transition: all 0.3s ease-out;
  z-index: 101;
}

.extraScreen.active {
  top: 0;
}

.extraScreen span.thickcon-Plus {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 2em;
  transform: rotate(135deg);
  margin-top: 26px;
  margin-left: 20px;
  line-height: normal;
  cursor: pointer;
}

.extraScreen h2 {
  margin-left: 20px;
  font-size: 2.542em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  line-height: 20px;
  margin-top: 29px;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
}

.itemView .rc-tabs-tab.rc-tabs-tab-active {
  color: var(--second-theme-color);
}

.itemView .rc-tabs-ink-bar.rc-tabs-ink-bar-animated {
  background-color: var(--second-theme-color);
}

.itemView .rc-tabs-tab:hover {
  color: var(--second-theme-color);
}

.itemView .rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  --current-tracking-Roboto: 0.039em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  line-height: normal;
  font-size: 0.794em;
  text-transform: uppercase;
  display: inline-block;
  flex-shrink: unset;
  flex-basis: unset !important;
  margin: 0 12.5px;
}

.itemView .rc-tabs-nav-wrap {
  margin-left: 8.5px;
  margin-top: 9px;
}

.itemView .rc-tabs-top .rc-tabs-bar {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 0 none;
}

.itemView .rc-tabs-top {
  border-bottom: 0 none;
}

.itemView .rc-tabs-top .rc-tabs-content {
  height: calc(100% - 252px);
  overscroll-behavior-y: contain;
}

.tabContentInner {
  padding: 0px 20px;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.itemView .rc-tabs.rc-tabs-top,
.tabWrapCate {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: calc(100% - 100px);
  /* margin-top: 21px; */
}

.rc-tabs-tab div {
  padding-bottom: 8px;
}

ul.extrasList {
  list-style-type: none;
  padding: 0;
  overflow-y: auto;
  height: calc(100vh - 265px);
  width: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

ul.extrasList::-webkit-scrollbar {
  display: none;
}

.extrasList li {
  display: table;
  vertical-align: middle;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 13px 0 10px;
}

.extrasList li > div {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}

.extrasList li > div:first-child {
  width: 43%;
  font-size: 1.615em;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.extrasList li > div:nth-child(2) {
  font-size: 1.593em;
  --current-tracking-Roboto: 0.014em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.itemView .rc-tabs-content-animated {
  transition: transform 0.5s ease, margin-left 0.5s ease, margin-top 0.5s ease,
    -webkit-transform 0.5s ease;
}

ul.editList {
  list-style-type: none;
  padding: 13px 20px 0;
  margin: 0;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

ul.editList li > span:first-child {
  font-size: 1.609em;
  line-height: normal;
  position: relative;
  top: 4px;
}

span.editBtn {
  cursor: pointer;
  display: inline-block;
  font-size: 1.25em;
  color: var(--main-theme-color);
  line-height: normal;
  width: 75px;
  padding: 4px 0;
  text-align: center;
  border: 1px solid var(--main-theme-color);
  border-radius: 5px;
  float: right;
}

span.editBtn:hover,
span.editBtn.removed {
  color: #fff;
  background-color: var(--main-theme-color);
}

ul.editList li {
  clear: both;
  padding: 9px 0 14px;
  overflow: hidden;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

ul.editList li span.iconWala.thickcon-Checkmark {
  position: relative;
  float: right;
  top: 3px;
}

/* span.iconWala.thickcon-Checkmark:before {
position: relative;
top: -1px;
} */

.remButtonWrap {
  position: absolute;
  bottom: -60px;
  right: 20px;
  padding: 17px 0 0;
  left: 20px;
  border-top: 1px solid #ebebeb;
  transition: bottom 0.2s ease-out;
}

.remButtonWrap.active {
  bottom: 12px;
}

.extraScreen.editIngWrap {
  z-index: 111;
}

.remButtonWrap > span {
  display: block;
  border: 1px solid var(--main-theme-color);
  background-color: var(--main-theme-color);
  color: #fff;
  font-size: 1.713em;
  padding: 9px 0;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  border-radius: 4px;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
}

.remIng {
  height: calc(100% - 105px);
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.remIng.scroll {
  height: calc(100% - 185px);
  overscroll-behavior-y: contain;
}

.itemView .rc-tabs-top .rc-tabs-tabpane-inactive {
  height: auto;
}

.itemView .rc-tabs-top .rc-tabs-nav-swipe {
  transition: all 0.5s ease;
}

.itemView .rc-tabs-nav-wrap,
.itemView .rc-tabs-bar,
.itemView .rc-tabs-nav-container {
  overflow: visible;
  float: left;
  transition: all 0.5s ease;
  width: 100%;
}

.itemView .rc-tabs-top .rc-tabs-ink-bar {
  bottom: -2px;
  transition: all 0.5s ease;
}

.rc-tabs-tabpane.rc-tabs-tabpane-inactive {
  opacity: 0;
  /* transition: all 0.5s ease; */
}

.itemView .rc-tabs-tabpane.rc-tabs-tabpane-active {
  opacity: 1;
}

span.cartCount::selection,
.cMinusPlusBtn > svg::selection,
.cMinusPlusBtn > img::selection,
.pepCount::selection,
.pepInc img::selection,
.pepDesc img::selection {
  background-color: transparent;
}

.cMinusPlusBtn.CMinusBtn.disabled:after,
.cMinusPlusBtn.CPlusBtn.disabled:after,
.pepDesc.disabled:after {
  position: absolute;
  display: block;
  content: '';
  top: -1px;
  left: -1px;
  width: 111%;
  height: 108%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: default;
}

.detailsConfirm.active {
  left: 0;
}

.detailsConfirm {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  top: 0px;
  left: 100%;
  z-index: 1000;
  transition: left 0.2s ease;
  padding: 152px 20px 0 20px;
  padding-top: 14px !important;
}

.backToOrder.thickcon-Left-Chevron {
  font-size: 2em;
  line-height: normal;
  padding-top: 28px;
  margin-left: -5px;
  cursor: pointer;
  position: absolute;
  top: 96px;
  z-index: 1003;
  left: 20px;
  transition: top 0.25s linear;
}

.field-errors {
  font-size: 10px;
  padding-top: 5px;
  text-transform: none;
  color: red;
}

.inputGroup > span,
.mobVerImp > span:first-child {
  display: block;
  text-transform: uppercase;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-size: 1.5em;
  line-height: normal;
}

.inputGroup > label {
  display: block;
  text-transform: uppercase;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-size: 1.5em;
  line-height: normal;
  font-weight: var(--old-font-normal);
  margin-bottom: 0px;
}

.inputGroup > input {
  width: 100%;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #e5e5e5;
  font-size: 2em;
  margin-top: 17px;
  padding-bottom: 9px;
  text-transform: capitalize;
}

.inputGroup {
  clear: both;
  margin-bottom: 42px;
}

.nextDetails {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  font-size: 1.713em;
  position: absolute;
  bottom: 17px;
  background-color: var(--main-theme-color);
  color: #fff;
  line-height: normal;
  padding: 11px 35px 10px 18px;
  border-radius: 10px;
  right: 20px;
  cursor: pointer;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  z-index: 1001;
}

.nextDetails > i {
  position: absolute;
  top: 13px;
  right: 13px;
}

.detailsTitle {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  font-size: 2.542em;
  line-height: 32px;
  padding-bottom: 41px;
  padding-top: 15px;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  padding-right: 50px;
}

.sec2 form {
  margin: 0 -4.5px;
}

.inpGroup6 > div {
  display: block;
  width: 50%;
  float: left;
  padding-left: 14.5px;
}

.inpGroup6 > div:first-child {
  padding-left: 0;
  padding-right: 14.5px;
}

.inpGroup6 > div > div {
  margin: 0 -6px;
}

.inpGroup6 > div > div span {
  display: block;
  float: left;
  width: 33.33%;
  padding: 0 6px;
}

.inpGroup6 > div > div span input {
  width: 100%;
  border: 1px solid #ebebeb;
  text-align: center;
  font-size: 3.4em;
  padding: 4px 0 3px;
  line-height: normal;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  border-radius: 5px;
  height: 53px;
}

.inpGroup6 {
  display: flex;
}

.verification-code-container {
  margin-top: 32px;
}

.verification-otp-input {
  width: 100% !important;
  border: 1px solid #ebebeb;
  text-align: center;
  font-size: 3.4em !important;
  padding: 4px 0 3px;
  line-height: normal;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  border-radius: 5px !important;
  height: 53px;
  margin-right: 10px;
}

.verification-otp-input-focus {
  border: 1px solid #838080;
}

.verification-otp-input-error {
  border: 1px solid red !important;
}

.inpGroup6 {
  margin-top: 32px;
}

.phoneEdit input:disabled {
  background-color: #fff;
  border: 0 none;
}

.phoneEdit input {
  font-size: 1.329em;
  line-height: normal;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  text-align: center;
  padding: 5px 5px;
  width: 119px;
  border: 0 none;
  border-radius: 4px;
}

.phoneEdit span {
  font-size: 1.329em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: normal;
  --current-tracking-Roboto: 0.017em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  width: 63px;
  padding: 5px 0;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border-left: 1px solid #ebebeb;
}

.phoneEdit {
  border: 1px solid #ebebeb;
  clear: both;
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 26px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.phoneRe {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 1.329em;
  line-height: normal;
  width: 88px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  --current-tracking-Roboto: 0.017em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  text-align: center;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  cursor: pointer;
  padding: 5px 0;
  float: right;
  margin-top: 26px;
}

.phoneRe.active {
  background-color: var(--main-theme-color);
  color: #fff;
  border-color: var(--main-theme-color);
}

.inputGroup > input.error {
  border-bottom: 1px solid red;
}

.nextDetails.disabled,
.phoneRe.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.phoneEdit.error {
  border-color: red;
  box-shadow: 0 0 2px rgba(255, 0, 0, 0.2);
  color: red;
}

.phoneEdit.error span {
  border-color: red;
  cursor: default;
}

.detailsConfirmWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  /* left: 100%; */
  transform: translateX(100%);
  display: none;
  z-index: 1001;
  transition: all 0.2s ease;
}

.detailsConfirmWrap.active {
  /* left: 0; */
  display: block;
  transform: translateX(-20px);
}

.detailsConfirm.detailFinal {
  z-index: 1002;
  padding-top: 129px;
}

.detailsTitle .half-half-variant-button span {
  margin: 0px;
  font-size: 0.8em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: normal;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  background-color: transparent;
  color: inherit;
  padding: 0px;
  box-shadow: none;
  border-radius: 30px;
  cursor: pointer;
  z-index: 1;
  padding-left: 0px;
}

.sP.detailsTitle .half-half-variant-button span {
  top: 30px;
  right: 0;
  cursor: pointer;
  padding-left: 0px;
}

.detailsTitle span,
.glutenFree {
  margin: 2px 5px;
  font-size: 0.508em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: normal;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  background-color: #fff;
  color: var(--main-theme-color);
  padding: 4px 8px 3px 6px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  cursor: pointer;
  z-index: 1;
  transition: none;
}

.detailsTitle span i.thickcon-Plus,
.detailsTitle span i.thickcon-Checkmark,
.glutenFree i.thickcon-Plus,
.glutenFree i.thickcon-Checkmark {
  font-size: 11px;
  position: relative;
  top: 0px;
  margin-right: 5px;
  visibility: visible;
  transition: all 0.5s ease;
}

.revieLeft {
  float: left;
  font-size: 1.9em;
  line-height: normal;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.reviewItemO {
  overflow: hidden;
  --current-tracking-Roboto: 0.018em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  margin-bottom: 24px;
}

.revieRight {
  float: right;
  font-size: 1.8em;
  line-height: normal;
}

.revieLeft span {
  display: block;
  font-size: 0.75em;
  margin: 9px 0 0;
}

.reviewOrderWrap {
  height: calc(100% - 310px);
  margin-top: -21px;
  /*transition: all 0.5s ease;*/
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.detailsConfirm.detailFinal .totalbottom {
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 0;
}

.mapAbs {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0;
  bottom: 0;
  width: 100%;
  transition: width 0.3s;
}

.desktopViewOrder {
  width: 75%;
  transition: width 0.3s;
}

.desktopViewOrderContainer {
  width: 0%;
  position: absolute;
  /* right: 0; */
  height: 100%;
  display: none;
}

.rightSideVisibility {
  right: 0;
  /* width: 25%; */
  display: block;
}

.showDesktopViewContainer {
  display: block !important;
  width: 25%;
  transition: width 0.3s;
}

.shadowIt .row,
.col-md-8.customWidth {
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.itemWrap .swiper-container-autoheight {
  padding-left: 19px;
  padding-right: 19px;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.navWrap ul {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.compact .navWrap ul {
  opacity: 0;
  visibility: hidden;
}

.backToOrder.thickcon-Left-Chevron.active {
  top: 83px;
}

.compact ul.nav.pull-right {
  margin-top: 0.5px;
}

.deliveryInfo {
  background-color: #fff;
  display: inline-block;
  position: absolute;
  top: 27px;
  left: 19px;
  padding: 15px 18px 0 18px;
  min-width: 289px;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  /* height: 256px; */
  min-height: 100px;
  z-index: 999;
  transition: min-height 0.5s ease-out, opacity 0.5s ease-out;
}

.deliveryInfo.delivery {
  opacity: 0;
}

.deliveryInfo li {
  font-size: 1.4em;
  line-height: normal;
  --current-tracking-Roboto: 0.034em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  padding-bottom: 33px;
  position: relative;
  padding-left: 30px;
}

.deliveryInfo li:last-child {
  padding-bottom: 25px;
}

.deliveryInfo ul {
  list-style-type: none;
  padding: 16px 0 0;
  margin: 0;
}

p.titleInfo {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  line-height: normal;
  font-size: 2em;
  --current-tracking-Roboto: 0.04em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  padding: 0 0 8px;
  margin: 0;
  border-bottom: 1px solid #ebebeb;
}

.deliveryInfo li i {
  position: absolute;
  left: 0;
  top: -1px;
  font-size: 17px;
}

.detailsConfirm .spTotal > span.spName {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.detailsConfirm span.spPrice {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.deliveryInfo li strong {
  /* font-weight: normal; */
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.searchBar.active,
.dateBar.active {
  top: 0;
  visibility: visible;
  z-index: 1;
}

.searchBar,
.dateBar {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: -1;
  visibility: hidden;
  min-height: 100%;
  background-color: #fff;
  transition: all 0.5s ease;
  padding: 19px 18px 0 20px;
}

ul.autoSuggestionWrap li div span {
  color: #807c7c;
}

.searchBar span.icon-Search {
  position: absolute;
  top: 23px;
  font-size: 16px;
}

.searchBar .deliverySearchBox input {
  font-size: 1.4em;
  --current-tracking-Roboto: 0.034em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  padding-bottom: 13px;
  padding-left: 30px;
  border-bottom: 1px solid #ebebeb;
  padding-right: 20px;
}

.searchBar span.icon-X {
  position: absolute;
  top: 24px;
  font-size: 15px;
  right: 18px;
  cursor: pointer;
}

.searchBar ul.geosuggest__suggests.autoSuggestionWrap {
  left: 18px;
  right: 18px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  border: 0 none;
  border-radius: 0;
  top: 55px;
  height: 201px;
  overflow-y: auto;
}

.searchBar ul.geosuggest__suggests.autoSuggestionWrap li {
  margin-bottom: 0;
  padding-left: 32px;
  font-size: 1.4em;
  line-height: normal;
  border-bottom: 1px solid #ebebeb;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  --current-tracking-Roboto: 0.034em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  padding-bottom: 13px;
  padding-top: 14px;
}

.searchBar ul.autoSuggestionWrap li:before {
  top: 12px;
  font-size: 19px;
}

li.searchAdress {
  cursor: pointer;
}

ul.geosuggest__suggests.autoSuggestionWrap:not(.removeStar)
  li:first-child:before {
  content: '\e91d';
}

li.apartment input,
li.oNotes input {
  border: 0 none;
  padding: 0;
  width: 100%;
  font-size: 100%;
}

.searchBar
  ul.geosuggest__suggests.autoSuggestionWrap:not(.removeStar)
  li:first-child
  span {
  color: #4b4a4a;
}

.pickupboxInnerRight ul.autoSuggestionWrap li:before {
  font-size: 16px;
  top: 0;
  left: -2px;
}

.detailsConfirm.detailPayment {
  z-index: 1002;
}

.detailsConfirm.detailFinal.detailPayment .detailsTitle div.img {
  position: absolute;
  right: -5px;
  top: 15px;
}

.detailsConfirm.detailFinal.detailPayment .detailsTitle div.img img {
  width: 41px;
  margin-right: 5px;
}

.detailsConfirm.detailFinal.detailPayment .detailsTitle {
  position: relative;
  padding-bottom: 3px;
}

.cardCommon {
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;
  min-height: 60px;
  width: 100%;
  border: 1px solid #ebebeb;
  padding: 5px 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}

.cardCommon.cash i {
  color: #28692b;
  /*float: right;*/
  font-size: 39px;
  position: relative;
  /*top: -1px;*/
}

.cardCommon.cash .icon-Receipt {
  width: 33px;
}

.cardCommon.cash {
  cursor: pointer;
  font-size: 1.68em;
  line-height: normal;
  padding: 8px 8px 8px 10px;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  margin-bottom: 20px;
}

.detailsConfirm.detailPayment > div {
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  overscroll-behavior-y: contain;
}

.detailsConfirm.detailPayment.moveNext11 > div {
  height: auto;
}

.cardCommon.cash > span {
  display: inline-block;
  /*margin-top: 9px;*/
}

.cardCommon.cash > span.credit-card-span {
  /*margin-bottom: 10px;*/
}

div.cardCommon.cash .img {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.cardCommon.cash .img > img {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

span.cardLegend {
  display: block;
  text-transform: uppercase;
  font-size: 1em;
  line-height: normal;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
}

.cardCommon.card {
  padding: 7px 10px 9px;
}

.cardNumber {
  width: 100%;
  padding: 0 0 10px;
  margin-bottom: 9px;
  border-bottom: 1px solid #ebebeb;
}

.cardCommon.card input {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  font-size: 1.8em;
  line-height: normal;
  border: 0 none;
  padding: 0;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.cardNumber img {
  margin-top: 5px;
  margin-right: 9px;
  max-width: 46px;
}

.cardCommon.card .cardNumber input {
  position: relative;
  top: 7px;
}

.cBot {
  float: left;
  width: 50%;
}

.cardMonth.cBot {
  padding: 0 7.5px 0 0;
}

.cardCVV.cBot {
  padding: 0 0 0 15.5px;
}

.cBot span.cardLegend {
  margin-bottom: 10px;
}

.cardCommon.card input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc7c7;
}

.cardCommon.card input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc7c7;
}

.cardCommon.card input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc7c7;
}

.cardCommon.card input:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc7c7;
}

.detailFinal .confirmBtn {
  z-index: 1003;
  position: relative;
}

.detailsTitle span.active i.thickcon-Plus,
.detailsTitle span.active i.thickcon-Checkmark {
  visibility: hidden;
  font-size: 0;
}

.detailsConfirm:not(.detailFinal) .detailsTitle span.active.voucher {
  padding-left: 4px;
}

.cardCommon.cash.active {
  border-color: var(--main-theme-color);
  box-shadow: 0 0 2px rgba(var(--main-theme-color), 0.5);
}

.cardCommon.card.active {
  border-color: var(--main-theme-color);
  box-shadow: 0 0 2px rgba(var(--main-theme-color), 0.5);
}

.voucherBox {
  transition: all 0.5s ease;
}

.voucherBox {
  transition: all 0.5s ease;
  height: 0;
  overflow: hidden;
  opacity: 0;
  position: relative;
}

input.voucherInp {
  border: 1px solid #ebebeb;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1.7em;
  opacity: 0;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  padding: 8px 30px 7px 8px;
  text-transform: uppercase;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
}

.voucherBox.active {
  overflow: visible;
  opacity: 1;
  height: 43px;
}

.voucherBox.active ~ .reviewOrderWrap {
  margin-top: 10px;
  height: calc(100% - 257px);
  overscroll-behavior-y: contain;
}

.active > input.voucherInp {
  opacity: 1;
}

.voucherBox span {
  position: absolute;
  display: block;
  right: 8px;
  top: 12px;
  max-width: 18px;
}

.voucherBox span img {
  width: 100%;
}

.voucherBox i.thickcon-X {
  color: #fc3d38;
  font-size: 17px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.voucherBox .voucherInp.match ~ span i.thickcon-X {
  color: #53d769;
}

.voucherBox .voucherInp.nomatch ~ span i.thickcon-X,
.voucherBox .voucherInp.match ~ span i.thickcon-X {
  visibility: visible;
  opacity: 1;
}

.voucherBox .voucherInp.match ~ span i.thickcon-X:before {
  visibility: visible;
  opacity: 1;
  content: '\e917';
}

.codeSuccess p {
  margin: 0;
  display: inline-block;
  font-size: 1.6em;
  line-height: normal;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.codeSuccess p span {
  display: block;
  margin-top: 3px;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.codeSuccess span.sucBtn {
  right: 0;
  position: absolute;
  text-transform: uppercase;
  background-color: var(--main-theme-color);
  color: #fff;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.024em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  font-size: 1.3em;
  line-height: normal;
  top: 7px;
  padding: 3px 11px;
  border: 1px solid var(--main-theme-color);
  border-radius: 12px;
  cursor: pointer;
}

.codeSuccess {
  position: relative;
  transition: all 0.25s ease;
  top: 17px;
  opacity: 0;
  top: 23px;
  visibility: hidden;
  padding-bottom: 17px;
  border-bottom: 1px solid #ebebeb;
}

.codeSuccess.match {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease 0.15s;
  top: 5px;
}

.codeSuccess.match ~ .reviewOrderWrap.match {
  margin-top: 37px;
  height: calc(100% - 366px);
  overscroll-behavior-y: contain;
}

#sticky .totalbottom {
  bottom: 9px;
  position: absolute;
  left: 20px;
  right: 20px;
  background-color: #fff;
}

#sticky div.stWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pickUpWrap,
.cart-items,
div#sticky,
.innerSticky,
.cart-items > div,
.deliveryWrap {
  height: 100%;
}

.pickOrOrderWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 242px);
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.container-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 19px 14px 0px;
}

.container-menu-item > ul {
  display: flex;
  flex-flow: row wrap;
}

.powered-by-container {
  padding: 20px 0px 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svgicon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deliveryInfo li strong {
  cursor: pointer;
}

.dateBar > span {
  position: absolute;
  top: 22px;
  font-size: 16px;
}

input.inpC {
  font-size: 1.4em;
  border-style: solid;
  border-color: #ebebeb;
  border-width: 0 0 1px 0;
  line-height: normal;
  --current-tracking-Roboto: 0.034em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  width: 100%;
  padding: 3px 25px 13px 30px;
}

.dateBar > span.icon-X {
  right: 21px;
  cursor: pointer;
}

.laCampaPicker .react-datepicker__current-month {
  font-size: 1.6em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  line-height: normal;
  --current-tracking-Roboto: 0.034em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  color: #4b4a4a;
}

.react-datepicker.laCampaPicker {
  width: 100%;
  border: 0 none;
  font-size: inherit;
  border-radius: 0;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.laCampaPicker .react-datepicker__triangle {
  display: none;
}

.laCampaPicker .react-datepicker__month-container {
  width: 100%;
}

.laCampaPicker .react-datepicker__day-name,
.laCampaPicker .react-datepicker__day,
.laCampaPicker .react-datepicker__time-name {
  width: 14.28%;
  margin: 0;
  font-size: 1.2em;
  line-height: normal;
  --current-tracking-Roboto: 0.034em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  color: #4b4a4a;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.laCampaPicker .react-datepicker__day-names {
  margin-top: 18px;
}

.laCampaPicker .react-datepicker__header {
  background: 0 none;
  border-bottom: 0 none;
  padding-top: 11px;
}

.laCampaPicker .react-datepicker__day {
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #ebebeb;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 1.369em;
}

.laCampaPicker .react-datepicker__week {
  border-left: 1px solid #ebebeb;
}

.laCampaPicker .react-datepicker__week:first-child {
  border-left: 0 none;
  text-align: right;
}

.laCampaPicker .react-datepicker__week:last-child {
  text-align: left;
}

.laCampaPicker .react-datepicker__day--selected {
  background-color: var(--main-theme-color);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  color: #fff;
  border-radius: 0;
}

.laCampaPicker .react-datepicker__day--today {
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.laCampaPicker .react-datepicker__day--outside-month {
  display: none;
}

.deliveryInfo.dateActive {
  min-height: 313px;
}

.deliveryInfo.dateActive.true {
  overflow: visible;
}

.laCampaPicker .react-datepicker__week:first-child .react-datepicker__day {
  border-top: 1px solid #ebebeb;
  border-bottom: 0 none;
}

.laCampaPicker .react-datepicker__week:nth-child(2) {
  border-top: 1px solid #ebebeb;
}

.laCampaPicker
  .react-datepicker__week:first-child
  .react-datepicker__day[aria-label='day-1'] {
  border-left: 1px solid #ebebeb;
  width: 14.5%;
  margin: 0 0 0 -3px;
}

.laCampaPicker .react-datepicker__day--selected:hover {
  border-radius: 0;
  background-color: var(--main-theme-color);
}

.laCampaPicker button.react-datepicker__navigation {
  border: 1px solid #ddd;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  width: 23px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 23px;
  border-radius: 12px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  top: 8px;
}

.laCampaPicker button.react-datepicker__navigation:before {
  content: '\e90c';
  color: #707070;
}

.laCampaPicker
  button.react-datepicker__navigation.react-datepicker__navigation--previous:before {
  content: '\e909';
}

.laCampaPicker .react-datepicker__day.react-datepicker__day--disabled {
  color: #ccc;
}

.laCampaPicker .react-datepicker__day:hover {
  border-radius: 0;
}

li.dateEdit {
  cursor: pointer;
  display: inline-block;
}

.itemWrap .slick-track {
  transition: all 0.3s ease 0.1s;
}

.itemWrap .slick-slide {
  opacity: 0;
  transition: all 0.4s ease 0;
}

.itemWrap .slick-slide.slick-active.slick-current {
  opacity: 1;
  transition: opacity 0s ease;
}

.specialsSlide .itemBoxes,
.specialsSlide .itemBoxes.boxWrapper {
  width: 33.33%;
  /* padding: 0 11px; */
}

.slick-prev,
.slick-next {
  display: none !important;
}

.specialRow {
  overflow: hidden;
  margin: 0 -6px;
}

.specialsSlide .imgWrap {
  border-radius: 3px;
  overflow: hidden;
  /* max-height: 180px; */
}

.specialsSlide .prodInfo {
  padding-top: 25px;
  padding-right: 20px;
  padding-left: 6px;
}

.specialsSlide .prodname {
  font-size: 1.7em;
}

.specialsSlide span.innerIng {
  padding-top: 7px;
  padding-bottom: 13px;
  min-height: 28px;
  display: inline-block;
}

.specialsSlide .prodPrice {
  margin-bottom: 3px;
}

.slidebar,
.slidebarInner,
.slidebarInner.swiper-slide,
.swipeBar {
  position: absolute;
  height: 2px;
  background: var(--second-theme-color);
  bottom: -2px;
  transform: translateZ(0);
  transition: all 0.2s ease-in 0.2s;
}

.swipeBar {
  left: 0;
  bottom: 15px;
  width: 70px;
}

ul.nav.nav-tabs {
  position: relative;
}

.navWrap ul li.active a:after,
.navWrap ul li a:hover:after {
  display: none;
}

.specialViewI {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  height: calc(100% - 47px);
}
.specialViewI.halfBox {
  height: 100%;
}

.detailsTitle.sP {
  padding-top: 22px;
  padding-bottom: 21px;
}

.sP.detailsTitle span {
  top: 30px;
  right: 0;
  cursor: default;
  padding-left: 9px;
}

.boxesSP {
  margin-left: -14px;
  margin-right: -14px;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.bSp {
  float: left;
  width: 50%;
  padding: 0 14px;
  min-height: 170px;
  overflow: hidden;
  margin-bottom: 26px;
}

.innerSP {
  height: 170px;
  vertical-align: middle;
  background-color: #cbc7c6;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 12px 10px;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-black);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-black, 0em)
  );
  color: #fff;
  position: relative;
  overflow: hidden;
}

.innerSP > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}

.catFil {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.catFil::-webkit-scrollbar {
  display: none;
}

.innerSP:hover,
.active > .innerSP {
  background-color: var(--second-theme-color);
}

.innerSP > span {
  font-size: 2.219em;
  line-height: normal;
  --current-tracking-Roboto: 0.04em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  text-transform: uppercase;
}

.innerSP > div {
  font-size: 2.352em;
  line-height: normal;
  --current-tracking-Roboto: 0.013em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  position: absolute;
  left: 10px;
  right: 10px;
  top: 64px;
}

.spBtn {
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 17px;
  padding-top: 19px;
  border-top: 1px solid #ebebeb;
  background-color: #ffffff;
}

.innerSP > span img {
  width: 16px;
  margin-right: 7px;
  top: -2px;
  position: relative;
}

.confirmBtn .rb {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
}

.proceedWrap.special .orderPrice {
  display: none;
}

.proceedWrap.extra {
  opacity: 0;
  visibility: hidden;
}

span.reddf {
  position: absolute;
  top: 23.5px;
  right: 20px;
  color: #fff;
  background-color: rgba(var(--main-theme-color), 0.5);
  /* border: 1px solid rgba(226,90,40,0.5); */
  text-transform: uppercase;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  font-size: 1.292em;
  --current-tracking-Roboto: 0.024em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  padding: 4px 14px;
  display: block;
  line-height: normal;
  border-radius: 18px;
}

.tabSUL,
.tabSUL.swiper-slide {
  /* margin: 0px 12.5px 0px; */
  margin-right: 25px;
  padding-top: 8px;
  padding-bottom: 16px;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  --current-tracking-Roboto: 0.039em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  line-height: normal;
  font-size: 1.1em;
  text-transform: uppercase;
  cursor: pointer;
  width: auto;
}

.tabSUL:first-child {
  margin-left: 20px;
}

.itemView span.swiper-slide.slidebarExtras,
.swiperWeek span.swiper-slide.slidebarExtras {
  position: absolute;
  width: 20px;
  height: 2px;
  background: var(--second-theme-color);
  bottom: -2px;
  left: 20px;
  transition: all 0.3s linear 0s;
}

.active.tabSUL,
.tabSUL:hover {
  color: var(--second-theme-color);
}

.itemView .swiper-container.swiper-container-horizontal:first-child {
  overflow: visible;
  cursor: default !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px;
}

.itemView
  .swiper-container.swiper-container-horizontal:first-child
  .swiper-wrapper {
  transition: all 0.5s ease !important;
}

.itemView
  .swiper-container.swiper-container-horizontal:last-child
  .swiper-slide.tabContentInner.swiper-slide-active {
  opacity: 1;
}

.itemView
  .swiper-container.swiper-container-horizontal:last-child
  .swiper-slide {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.col-md-8 .itemWrap2 {
  min-height: 90vh;
}

/* .slick-list {overflow: visible !important;} */

.itemView .swiper-container.swiper-container-horizontal:last-child {
  overflow-y: auto;
  height: calc(100% - 153px);
  overscroll-behavior-y: contain;
}

.itemView
  .tabWrapCate.scroll
  .swiper-container.swiper-container-horizontal:last-child {
  /* height: calc(100% - 234px); */
  /* overscroll-behavior-y: contain; */
}

.half span.voucher {
  white-space: nowrap;
  top: 67px;
  height: 23px;
  margin-top: 5px;
  margin-right: 5px;
}

.halfDiv {
  width: 164px;
  height: 337px;
  /* background-color: #cbc7c6; */
  float: left;
  /* border-radius: 5px 163px 163px 5px; */
  position: relative;
  /* cursor: pointer; */
  margin-top: 36px;
}

.halfDivs {
  overflow-y: auto;
  text-align: center;
  height: calc(100% - 263px);
  max-width: 356px;
  margin: 0 auto;
  overscroll-behavior-y: contain;
}

.halfDiv:first-child {
  margin-right: 11px;
  /* border-radius: 163px 5px 5px 163px; */
}

.halfDiv:first-child > div > img,
.halfDiv:last-child .halfHalfwImage {
  transform: rotateY(180deg);
}

.halfDiv:last-child .halfHalfwImage span {
  transform: rotateY(180deg);
}

.halfDiv span {
  font-size: 3.8em;
  text-transform: uppercase;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-black);
  color: #fff;
  top: 43.5%;
  left: 23px;
  --current-tracking-Roboto: 0.04em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-black, 0em)
  );
  position: absolute;
  z-index: 9;
  cursor: pointer;
}

.halfDiv span i.thickcon-Plus {
  font-size: 25px;
  position: relative;
  top: -7px;
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  margin-right: 8px;
}

.sizesI > div {
  border: 1px solid var(--main-theme-color);
  display: inline-block;
  font-size: 1.292em;
  --current-tracking-Roboto: 0.024em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  text-transform: uppercase;
  padding: 4px 17px 3px;
  margin-top: 10px;
  border-radius: 15px;
  line-height: normal;
  cursor: pointer;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  color: var(--main-theme-color);
}

.sizesI > div:not(:last-child) {
  margin-right: 20px;
}

.sizesI > div.default,
.sizesI > div:hover {
  background-color: var(--main-theme-color);
  color: #fff;
}

.selectSizes {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  background-color: #fff;
}

.disableIt {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #ccc !important;
  cursor: default !important;
}

.addToHalf {
  margin-top: 20px;
  margin-bottom: 20px;
}

.detailsTitle.sP.half > strong {
  display: inline-block;
  white-space: nowrap;
}

.detailsTitle.sP.half {
  display: flex;
  flex-flow: row wrap;
  font-size: 3em;
}

.detailsTitle.sP.half span.voucher {
  display: flex;
  font-size: 0.431em;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-right: 10px;
}

.activeCount {
  position: absolute;
  top: 3px;
  right: 23px;
  color: #fff;
  line-height: normal;
  background-color: var(--main-theme-color);
  border: 1px solid var(--main-theme-color);
  border-radius: 25px;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 11px;
  padding: 2px 0 0;
}

.itemDet.orderBtnP.temp {
  transition: bottom 0s ease 0s;
}

.proceedWrap.ex {
  transition: bottom 0.2s ease-in-out;
  bottom: -80px;
}

.proceedWrap.ex.active {
  bottom: -12px;
}

.proceedOrder > div {
  transition: all 0.2s linear;
}

.ex .orderPrice {
  opacity: 1;
}

.ex .zero .orderPrice {
  opacity: 0;
}

.ex .zero .proceedBtn {
  width: 100%;
}

.ex .proceedBtn {
  top: 19px;
  right: 10px;
}

.ex .proceedOrder {
  padding-top: 12px;
  margin-top: 0;
}

a.confirmBtn {
  display: block;
}

a.confirmBtn:hover {
  color: #fff;
}

.col-md-4.customBar {
  transition: transform 0.5s linear;
}

.col-md-4.customBar.hideIt {
  transform: translateX(100%) !important;
}

.customWidth.noPad {
  padding-left: 0;
  padding-right: 0;
}

.deliveryWrapFinal {
  position: absolute;
  opacity: 0;
  bottom: -200px;
  left: 28px;
  right: 28px;
  min-height: 121px;
  background-color: white;
  border-radius: 5px;
  z-index: -99;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-out;
  visibility: hidden;
}

.deliveryWrapFinal.dine-in {
  box-shadow: none;
}

.deliveryWrapFinal.active {
  opacity: 1;
  z-index: 99999;
  visibility: visible;
  bottom: 0;
  transform: translateY(-75%);
}

.deliveryWrapFinal > div {
  float: left;
  width: 33.33%;
}

.oneDel > p:first-child {
  font-size: 2em;
  padding-bottom: 10px;
  line-height: normal;
}

.oneDel > p:last-child {
  font-size: 1.899em;
  margin-bottom: 0;
  line-height: normal;
  text-align: left;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.oneDel {
  padding-left: 22px;
  --current-tracking-Roboto: 0.018em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  padding-top: 20px;
}

.oneDel > p:last-child > i {
  position: relative;
  top: 1px;
  margin-right: 8px;
}

.twoDel > p {
  text-align: center;
  font-size: 1.903em;
  line-height: normal;
  padding-top: 39px;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.twoDel > p > i {
  margin-right: 12px;
  position: relative;
  top: 1px;
}

.threeDel > span {
  cursor: pointer;
  display: inline-block;
  color: var(--main-theme-color);
  font-size: 1.4em;
  line-height: normal;
  --current-tracking-Roboto: 0.017em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  border: 1px solid var(--main-theme-color);
  border-radius: 5px;
  padding: 5px 11px 4px;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: normal;
}

.threeDel > span {
  float: right;
}

.threeDel > span:first-child {
  margin-left: 15px;
}

.threeDel {
  padding-top: 35px;
  padding-right: 22px;
}

.deliveryWrapFinal > div.progressIcons {
  position: absolute;
  width: 100%;
  top: -24px;
  text-align: center;
}

.progressIcons > div {
  display: inline-block;
  width: 45px;
  height: 45px;
  border: 1px solid var(--main-theme-color);
  border-radius: 25px;
  font-size: 26px;
  padding: 1px 0 0;
  color: var(--main-theme-color);
  background-color: #fff;
  position: relative;
  text-align: center;
}

.progressIcons > div.moped {
  margin-left: 130px;
  margin-right: 131px;
}

.progressIcons > div.active,
.progressIcons > div.store.del3,
.progressIcons > div.store.del4,
.progressIcons > div.store.del25,
.progressIcons > div.store.del12,
.progressIcons > div.store.del13 {
  background-color: var(--main-theme-color);
  color: #fff;
}

.progressIcons > div.store.del3:after,
.progressIcons > div.store.del4:after,
.progressIcons > div.store.del25:after,
.progressIcons > div.store.del12:after,
.progressIcons > div.store.del13:after {
  content: '';
  display: block;
  height: 2px;
  width: 131px;
  background-color: var(--main-theme-color);
  position: absolute;
  left: 100%;
  top: 20px;
}

.deliveryWrapFinal > div.re-center {
  position: absolute;
  right: 0;
  width: 47px;
  background-color: #fff;
  height: 47px;
  text-align: center;
  border: 1px solid #fff;
  top: -61px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 25px;
  padding-top: 4px;
}

.pickupbox .deliveryAccordionWrap .pickImg {
  font-size: 1.545em;
}

.deliveryInfo li i.icon-Write-Message {
  left: 1px;
}

div ~ .slidebar {
  display: none;
}

.navWrap .swiper-container {
  overflow: visible;
}

.visible-xs.bubble {
  position: absolute;
  bottom: calc(-100vh + 211px);
  /* width: 100%; */
  left: calc(50% - 56px);
  /* right: 0; */
  text-align: center;
}

.visible-xs.bubble ul {
  max-width: 298px;
  width: 100%;
  background-color: #fff;
  float: none;
  margin: 0 auto;
  padding: 0px 4px 0px 0;
  border-radius: 27px;
  border: 1px solid #e4e4e4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.visible-xs.bubble ul li {
  display: inline-block;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  text-transform: uppercase;
  font-size: 1.176em;
  padding: 10px 10px 12px;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  line-height: normal;
}

.visible-xs.bubble ul li i {
  font-size: 17px;
  position: relative;
  margin-left: 13px;
  top: 2px;
}
.visible-xs.bubble ul li:nth-child(2) {
  position: relative;
}
.visible-xs.bubble ul li:nth-child(2):before {
  display: block;
  content: '';
  height: 26px;
  width: 1px;
  position: absolute;
  left: 0;
  top: 7px;
  background-color: #ebebeb;
}

.loyalty-list:before {
  display: block;
  content: '';
  height: 26px;
  width: 1px;
  position: absolute;
  margin-left: -10px;
  top: 7px;
  background-color: #ebebeb;
}

/* .visible-xs.bubble ul li:first-child {
  padding-right: 5px;
} */

.visible-xs.bubble ul li:first-child i {
  font-size: 16px;
}

.visible-xs.bubble ul li:nth-child(2) i {
  left: -10px;
  font-size: 16px;
  margin-left: 22px;
  top: 3px;
}

/* .visible-xs.bubble ul li:last-child {
  border-left: 1px solid #ebebeb;
  margin-left: 3px;
  width: 32.3%;
} */

input.error.loginTextbox {
  border-bottom-color: red;
}

.halfDiv span img {
  width: 26px;
  height: auto;
  position: relative;
  top: -3px;
  margin-right: 9px;
}

i.delivery svg,
i.delivery img {
  width: 32px;
  height: auto;
  position: absolute;
  top: -15px;
  left: -2px;
}

.itemWrap2 .swiper-slide:not(.swiper-slide-visible) {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.itemWrap2 .swiper-slide.swiper-slide-active {
  opacity: 1;
}

/* .itemWrap2 .swiper-container-autoheight .swiper-wrapper{transition: all 0.5s ease !important;} */

button#onebtn.disabled {
  opacity: 0.6;
}

.react-swipeable-view-container > div:nth-child(2) > div {
  overflow: hidden;
}

.itemWrap .swiper-container-autoheight,
.itemWrap .swiper-container-autoheight .swiper-slide {
  min-height: calc(100vh - 120px);
}

.itemWrap2 .swiper-slide.swiper-slide-visible {
  opacity: 1;
}

.progressIcons > div.pickup.active:after {
  display: none;
}

.gluteWrap {
  float: right;
}

.spItemD .quantityView .gluten-free-half-special-view {
  position: absolute;
  top: 21px;
  right: 20px;
  width: 110px;
}

.halfD .quantityView .gluten-free-half-special-view {
  position: absolute;
  top: 21px;
  right: 20px;
  width: 110px;
}

.fixHeightIssue {
  height: 100%;
}

.fixHeightIssue.delcost .pickOrOrderWrap {
  height: calc(100% - 274px);
  overscroll-behavior-y: contain;
}

span.orderBubble {
  display: inline-block;
  padding: 10px 20px 9px;
  font-size: 1.4em;
  position: relative;
  border: 1px solid #ebebeb;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  top: -1px;
  cursor: pointer;
}

span.orderBubble > i {
  font-style: normal;
  height: 25px;
  margin-left: 8px;
  background-color: #e25f28;
  color: #fff;
  border-radius: 24px;
  width: 25px;
  display: inline-block;
  text-align: center;
  padding-top: 3px;
}

.orderViews.specialView,
.orderViews.halfView {
  z-index: 98;
}

span.progressbar {
  position: absolute;
  max-width: 131px;
  height: 2px;
  background-color: #999;
  left: calc(50% + 22px);
  top: 23px;
  visibility: hidden;
  width: 100%;
  transition: visibility 0.5s linear;
}

.progressbar i {
  background-color: var(--main-theme-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: width 0.5s linear;
  height: 100%;
  max-width: 100%;
}

span.progressbar.active {
  visibility: visible;
}

.grecaptcha-badge {
  display: none;
}

.detailsTitle i {
  font-style: normal;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='tel'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

input[type='text'] {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  /* border-radius: 0; */
}

input#apartment,
input.verification-otp-input:focus,
input#geosuggest__input:focus,
input#firstName:focus,
textarea[name='addNotes']:focus,
input#emailAddress:focus,
input#mobileNumber:focus,
input#lastName:focus {
  --tw-ring-color: transparent;
  border-color: inherit;
}

.nextDetails.wrongCode,
.nextDetails.wrongCode:hover {
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
  opacity: 0.5;
}

.pickupbox.error {
  border: 1px solid red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

.pickName.robMedium.error {
  color: red;
}

.cardNumber.error {
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
  border-bottom-color: red;
  color: red;
}

.cardMonth.error,
.cardCVV.error {
  color: red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.inpGroup6.error input {
  border: 1px solid red;
}

.fright li a {
  cursor: pointer;
}

ul.fright span.swipeBar.hidden-xs {
  transition: all 0.3s linear 0.1s;
}

li.pr0 {
  display: none;
}

ul.nav.navbar-nav.pull-right li:first-child,
li.pr0 {
  /* display: none; */
}

.deliveryCost {
  font-size: 1.068em;
  line-height: normal;
  position: absolute;
  color: #fff;
  right: 13px;
  top: 14px;
  border-radius: 3px;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  padding: 3px 5px 2px;
  background-color: var(--second-theme-color);
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
}

.detailsTitle.sP.half img {
  max-width: 10px;
  height: auto;
  position: relative;
  margin-right: 5px;
}

.detailsTitle.sP.half span.voucher {
  width: auto;
  cursor: pointer;
}

.detailsTitle.sP.half span.voucher i.thickcon-Checkmark {
  font-size: 11px;
  position: relative;
  /* top: -2px; */
}

.nextDetails > img {
  width: 24px;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 9px;
}

.nextDetails {
  width: 91px;
  height: 42px;
}

.col-md-8.customWidth .mapAbs > .leaflet-container {
  pointer-events: none;
}

.halfDiv > div > img {
  cursor: pointer;
}

.halfHalfwImage {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-size: 210% auto;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 334px 0 0 334px;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.halfHalfwImage span {
  font-size: 1.8em;
  text-align: center;
  width: 100%;
  left: 0;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  height: 75px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+50,1e5799+50,000000+50,000000+51,000000+51,000000+51,000000+51,000000+51,000000+100&0+0,0.55+45,0.6+49,0.8+50,0.8+50,0.8+50,0.6+50,0.8+50,0.8+50,0.8+50,0.8+50,0.6+50,1+50,0.6+51,0.6+52,0.8+57,0.8+57,0.6+57,0+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 45%,
    rgba(0, 0, 0, 0.6) 49%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.6) 51%,
    rgba(0, 0, 0, 0.6) 52%,
    rgba(0, 0, 0, 0.6) 57%,
    rgba(0, 0, 0, 0) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 45%,
    rgba(0, 0, 0, 0.6) 49%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.6) 51%,
    rgba(0, 0, 0, 0.6) 52%,
    rgba(0, 0, 0, 0.6) 57%,
    rgba(0, 0, 0, 0) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 45%,
    rgba(0, 0, 0, 0.6) 49%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.6) 51%,
    rgba(0, 0, 0, 0.6) 52%,
    rgba(0, 0, 0, 0.6) 57%,
    rgba(0, 0, 0, 0) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
}

ul.nav.pull-right li a {
  cursor: pointer;
}

.nav.pull-right li.dropdown:nth-child(4) a.dropdown-toggle {
  margin-right: 0;
}

.deliveryCost i {
  font-style: normal;
}

input[name='apartment']::-webkit-input-placeholder,
input[name='flat']::-webkit-input-placeholder,
input[name='notes']::-webkit-input-placeholder {
  color: #8e8e8e;
}

.orderViews.itemV.active {
  z-index: 110;
}

.orderViews.itemV {
  transition: transform 0.2s ease 0.2s, -webkit-transform 0.2s ease 0.2s,
    z-index 0.2s ease;
}

.pickupbox.error input::-webkit-input-placeholder {
  color: red;
}

.codeSuccess > div {
  display: none;
}

.codeSuccess.match > div {
  display: block;
}

tr.cartRow > td.cItemImg {
  vertical-align: top;
}

tr.cartRow > td.cItemQuantity {
  vertical-align: middle;
}

/*
tr.cartRow>td.cItemName {
  vertical-align: top;
  padding-top: 10px;
} */

/*.imgView img {
  visibility: hidden;
}*/

.imgView {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

tr.halfHalfOK.cartRow > td.cItemName {
  vertical-align: middle;
}

.col-xs-12.customWidth.noPad .itemBoxes,
.rendering .itemBoxes:not(.loadPlace),
.col-xs-12.customWidth.noPad .itemBoxes.boxWrapper,
.rendering .itemBoxes.boxWrapper:not(.loadPlace) {
  max-width: 182px;
  overflow: hidden;
}

.redSpan {
  color: red;
}

.revieRight {
  text-align: right;
}

.reviewItemO {
  display: table;
  width: 100%;
}

.reviewItemO > div {
  display: table-cell;
  float: none;
  vertical-align: middle;
}

span.textSpacer {
  margin-left: 0 !important;
}

.visible-xs.addressMobileBar {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  margin-right: -20px;
  margin-left: -20px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
}

.visible-xs.addressMobileBar > div {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  line-height: normal;
  --current-tracking-Roboto: 0.01em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  font-size: 1.6em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
}

.addB1 {
  border-bottom: 1px solid #dbdbdb;
  padding-top: 15px;
  padding-bottom: 21px;
}

.addB2 input {
  border: 0 none;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  padding-left: 56px;
  padding-right: 20px;
  font-size: 16px;
}

.addB2 {
  position: relative;
  height: 58px;
}

.addB2 i.icon-Home {
  position: absolute;
  z-index: 9;
  top: 18px;
}

.visible-xs.addressMobileBar .addB2 {
  padding-left: 19px;
}

.visible-xs.addressMobileBar > div i {
  font-size: 20px;
}

.visible-xs.addressMobileBar > div.addB1 i {
  margin-right: 16px;
  position: relative;
  font-size: 22px;
  top: 3px;
}

.addB2.pick {
  height: auto;
  padding-top: 14px;
  padding-bottom: 19px;
  color: #8e8e8e;
}

.visible-xs.addressMobileBar > div.pick i {
  font-size: 22px;
  margin-right: 16px;
  margin-left: 1px;
  position: relative;
  top: 3px;
}

.visible-xs.reviewBarM {
  font-weight: 400;
  position: absolute;
  bottom: 75px;
  left: 0;
  right: 0;
  overflow: hidden;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.visible-xs.reviewBarM.message {
  bottom: 125px;
}

.comM {
  width: 50%;
  float: left;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  font-size: 1.45em;
  line-height: normal;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 15px;
  --current-tracking-Roboto: 0.015em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  background-color: #fff;
}

.comM.notesM {
  border-right: 1px solid #ebebeb;
}

.comM > i {
  font-size: 20px;
  position: relative;
  top: 4px;
}

.comM i {
  margin-right: 7px;
}

.timeDisc {
  text-align: center;
}

.reviewItemO > div.revieRight {
  vertical-align: top;
}

.addB2.pick i {
  color: #4b4a4a;
}

/* body.android .stWrap,body.android .orderViews,body.android .detailsConfirm {
  touch-action: none !important;
  height: 100vh !important;
} */

.cardCommon.cash > img {
  max-width: 39px;
  float: right;
  padding-top: 7px;
  padding-bottom: 8px;
}

.itemWrap .swiper-slide.swiper-slide-prev,
.itemWrap .swiper-slide.swiper-slide-next {
  pointer-events: none;
}
.itemInnerWrap {
  position: relative;
}

.itemInnerWrap > a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  text-indent: -9999px;
}

.fTime.robMedium {
  text-align: left;
}

.re-center i {
  position: relative;
  top: 4.5px;
}

.progressIcons > div.store i,
.progressIcons > div.moped i,
.progressIcons > div.delivered i {
  position: relative;
  top: 5px;
}

.innerSP > div.activeName {
  font-size: 1.8em;
  line-height: normal;
  text-transform: uppercase;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 10px 5px;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    color-stop(75%, rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0.5))
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.imgWrap.swiper-lazy.swiper-lazy-loading,
.loadBoxWrap > div > div,
.loadBoxWrap > div > div:after,
.loadBoxWrap > div > div:before {
  background-color: #ebebeb;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

.loadPlace.itemBoxes {
  background-size: 0 0;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loadBoxWrap {
  /* padding: 0 0 0 19px;
  margin-left: 19px; */
  float: left;
  width: 100%;
  position: absolute;
}

.loadBoxWrap > div > div {
  margin-bottom: 57px;
  position: relative;
}

.loadBoxWrap > div > div img {
  width: 100%;
  visibility: hidden;
  height: auto;
  display: block;
  min-height: 150px;
  visibility: hidden;
}

.loadBoxWrap > div > div:after,
.loadBoxWrap > div > div:before {
  content: '';
  width: 100%;
  height: 10px;
  display: block;
  top: calc(100% + 9px);
  position: absolute;
}

.loadBoxWrap > div > div:before {
  top: calc(100% + 24px);
  width: 50%;
}
.container-fluid.shadowIt {
  height: 100%;
}

.thickcon-Left-Chevron.comGo {
  width: 38px;
  height: 35px;
  float: left;
  margin-left: 20px;
  margin-top: 2px;
  font-size: 30px;
  cursor: pointer;
}

.Home.comGo {
  border: 1px solid var(--main-theme-color);
  color: var(--main-theme-color);
  width: auto;
  margin-top: 8px;
  text-align: center;
  font-size: 1.2em;
  padding: 3px 10px 2px;
  border-radius: 4px;
  float: left;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 20px;
  cursor: pointer;
}

.comGo {
  margin-top: 5px;
  padding: 0;
  font-size: 2.2em;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.comGo i {
  margin-right: 2px;
  font-size: 28px;
}

table.cartTable {
  overflow: hidden;
}

.fine td.cItemName {
  word-wrap: break-word;
  padding-right: 28px;
}

.halfDiv:first-child > div > img {
  float: right;
}

.notesOverlay {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  transition: transform 0.2s ease;
  visibility: hidden;
}

.notesOverlay.active {
  transform: translateY(0%);
  visibility: visible;
}

.detailsConfirm.detailFinal.true1 {
  z-index: 1004;
}

.notesOverlay .detailsTitle {
  padding-top: 59px;
  padding-left: 20px;
}

.notesOverlay textarea {
  width: 100%;
  border: 0 none;
  font-size: 1.623em;
  padding: 15px 20px;
  border-top: 1px solid #ebebeb;
  height: 75%;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
}

#sticky .notesOverlay .totalbottom {
  bottom: auto;
  top: calc(100% - 50px);
}

textarea:focus {
  outline: 0;
}

textarea::placeholder {
  color: #8e8e8e;
}

li.geosuggest__item.geosuggest__item--active,
li.geosuggest__item.geosuggest__item--active span {
  color: #4b4b4a;
}

.halfD .glutenFree {
  display: none !important;
}

.swiper-slide.subTimeXS > input {
  display: none;
}

.halfDiv:first-child .halfHalfwImage.half1:not(.false) {
  background: 0 none !important;
}

.halfDiv:first-child .halfHalfwImage.half1:not(.false) span {
  background: 0 none;
  left: 7%;
  max-width: 90%;
}

.halfDiv:not(:first-child) .half2 img {
  transform: rotateY(180deg);
  float: right;
}

.halfDiv:not(:first-child) .halfHalfwImage.half2:not(.false) {
  background: 0 none !important;
}

.halfDiv:not(:first-child) .halfHalfwImage.half2:not(.false) span {
  left: 2%;
  background: 0 none;
}

.detailsTitle.sP.half img.tick {
  top: 0px;
}

.detailsTitle:not(.sP) span.voucher img {
  width: 12px;
  margin-right: 4px;
  transition: width 0.3s ease;
}

.detailsTitle:not(.sP) span.active.voucher img,
.detailsTitle:not(.sP) span.active.voucher img.plus {
  width: 0;
}

.detailsTitle:not(.sP) span.voucher > * {
  display: inline-block;
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.detailsTitle:not(.sP) span.voucher img.plus {
  width: 11px;
  top: -1px;
  position: relative;
}
.reservationWrap .dateBar {
  height: 100%;
  font-size: 10px;
}
.timeSelection {
  position: absolute;
  top: 100%;
  z-index: 1;
  left: 20px;
  right: 20px;
  height: calc(100% - 70px);
  background-color: #fff;
  transition: top 0.5s ease;
}
.active.timeSelection {
  top: 53px;
}
.timeSelection .mbsc-fr-w {
  min-width: auto;
  max-width: 100%;
  font-family: var(--old-font-Roboto);
  background-color: #fff;
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}
.timeSelection .mbsc-mobiscroll .mbsc-sc-whl-l {
  border-bottom-color: #ebebeb;
  border-top-color: #ebebeb;
}
.timeSelection .mbsc-mobiscroll .mbsc-sc-itm.mbsc-active {
  background-color: transparent;
}
.timeSelection .mbsc-mobiscroll .mbsc-sc-itm {
  font-size: 19px;
  padding: 0;
  line-height: 48px !important;
}
.timeSelection .mbsc-mobiscroll .mbsc-sc-whl-gr {
  padding: 0;
}
.timeSelection .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover {
  background: transparent;
}
.selectTime.confirmBtn {
  font-weight: var(--old-font-normal);
  --current-tracking-Roboto: 0.034em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-size: 1.4em;
  padding-bottom: 8px;
  margin-top: 15px;
}

.timeSelection
  .mbsc-fr.mbsc-mobiscroll.mbsc-fr-inline.mbsc-sc.mbsc-sel.mbsc-fr-liq.mbsc-no-touch.mbsc-fr-nobtn {
  border-bottom: 1px solid #f6f6f6;
  position: relative;
}
.reservationWrap.active.true {
  padding-bottom: 73px;
}
.timeSelection
  .mbsc-fr.mbsc-mobiscroll.mbsc-fr-inline.mbsc-sc.mbsc-sel.mbsc-fr-liq.mbsc-no-touch.mbsc-fr-nobtn:after,
.timeSelection
  .mbsc-fr.mbsc-mobiscroll.mbsc-fr-inline.mbsc-sc.mbsc-sel.mbsc-fr-liq.mbsc-no-touch.mbsc-fr-nobtn:before {
  content: '';
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 90px;
  margin: 0 auto;
  right: 0;
  z-index: 9;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}
.timeSelection
  .mbsc-fr.mbsc-mobiscroll.mbsc-fr-inline.mbsc-sc.mbsc-sel.mbsc-fr-liq.mbsc-no-touch.mbsc-fr-nobtn:after {
  bottom: 0;
  top: auto;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.mouseWheel {
  width: 7px;
  height: 63px;
  background-color: #a2a2a2;
  position: absolute;
  top: calc(50% - 56.5px);
  z-index: 999999;
  right: -10px;
  border-radius: 7px;
}

.false > img[src$='plus-white.svg'] {
  display: inline-block;
}

.true > img[src$='plus-white.svg'] {
  display: none;
}

.false > img[src$='(1).svg'] {
  display: none;
}

.true > img[src$='(1).svg'] {
  display: inline-block;
  margin-right: 4px;
}
.glutenFree {
  width: max-content;
  font-size: 1.2em;
  top: 116px;
  --current-tracking-Roboto: 0;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 4px;
  padding-right: 6px;
  top: 1px;
  right: 0;
}
.quantityView.active div:not(.customiseView) hr {
  width: calc(100% - 114px);
  visibility: hidden;
}
.orderViews.spItemD .customiseView hr {
  width: calc(100% - 114px);
  visibility: hidden;
}
.loggedIn {
  display: none;
}

.loggedIn ~ .userReviewWrap {
  right: 322px;
}
.reservationWrapPop .LoginWrap {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  height: 100%;
  width: 100%;
  transition: left 0.5s ease;
  padding-top: 20px;
}

.reservationWrapPop .LoginWrap.true {
  left: 0;
}
.LoginWrap .backToOrder {
  top: -10px;
  font-size: 19px;
  left: 18px;
}
.reservationWrapPop .LoginWrap div#one {
  height: 275px;
}

/*----new element style--*/
/* .mbsc-scv, .mbsc-scv-sc, .mbsc-scv-item {
  display: block !important;
} */

.itemWrap .mbsc-scv-c,
.itemWrap .mbsc-lv-cont {
  font-size: 10px !important;
  font-family: inherit;
}

/* .md-page {
  padding-left:20px;
} */
.itemWrap .mbsc-scv-item {
  max-width: 1450px;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 19px;
  /* transition: opacity 0.5s ease 0.5s, height 0.5s ease 0.5s; */
  transition: opacity 0.5s ease, height 0.5s ease;
  /*width: calc(100vw - 496px) !important; */
}
.itemWrap .mbsc-lv-mobiscroll,
.itemWrap .mbsc-scv-c {
  font-family: inherit;
}
/* .itemWrap .mbsc-scv-sc{
  transition: transform 0.35s linear 0s !important;
} */
/* .mbsc-scv-item:not(.md-0) {
  padding-left: 20px;
} */

.mbsc-scv-item {
  height: calc(100vh - 118px) !important;
  /* overflow: hidden; */
}
.itemWrap .mbsc-scv,
.itemWrap .mbsc-scv-sc,
.itemWrap .mbsc-scv-item {
  height: auto;
}

/* active class*/
.active.mbsc-scv-item {
  /* opacity: 1; */
  height: auto !important;
  /* 'transition-delay: 0s;' */
}

/* Previous to active*/
/* div:not(.active) + .active.mbsc-scv-item {
  opacity: 1 !important;
  height: auto !important;
  transition-delay: 0s;
} */

/* div.sibling {
  opacity: 1 !important;
  transition: opacity 0s ease 0.8s !important;
} */

/* For next div to active*/
/* .active.mbsc-scv-item + div {
  opacity: 1;
  height: auto !important;
  transition-delay: 0s;
} */

/* All other divs except next to active*/
/* .mbsc-scv-item:not(.active):not(.sibling) {
  opacity: 0 !important;
} */

div.md-content div.sibling:first-of-type {
  opacity: 1;
  transition-delay: 0s;
}

.alignConfirmInfo {
  display: flex !important;
  justify-content: space-between;
}

.alignConfirmInfoSpan {
  font-size: 0.706em;
  --current-tracking-Roboto: 0.052em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  margin-top: 6px;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-light);
}

.bookingContent {
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.resetMargin {
  margin: 0px;
}

.marginTop {
  margin-top: 2rem;
}

.bookingValue {
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  max-width: 50%;
}

.action-button-container {
  display: flex;
  justify-content: space-around;
  font-size: 1.8rem;
  margin-top: 8rem;
}

.general-button {
  border-radius: 10px;
  color: #fff;
}

.approve-action {
  background-color: #008e00;
  color: white;
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.decline-action {
  background-color: #ef4e39;
  color: white;
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.shadowMobileBar {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05) !important;
}

.align-confirmation-buttons {
  position: fixed !important;
  bottom: 0 !important;
  left: 0;
  right: 0;
}

.thickcon-Left-Chevron-Confirmation {
  font-size: 2em;
  line-height: normal;
  padding-top: 28px;
  margin-left: -5px;
  cursor: pointer;
  position: absolute;
  top: 96px;
  z-index: 1003;
  left: 20px;
}

.null-transistion {
  transition: none !important;
}

.ligature-fix {
  font-variant-ligatures: no-common-ligatures;
}

@-webkit-keyframes slide {
  0% {
    transform: translate(100%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@-moz-keyframes slide {
  0% {
    transform: translate(100%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@-o-keyframes slide {
  0% {
    transform: translate(100%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes slide {
  0% {
    transform: translate(100%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes reverse-slide {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(100%, 0%);
  }
}

.reservation-animation {
  animation: slide 300ms forwards !important;
  z-index: 99999 !important;
}

.reverse-reservation-animation {
  animation: reverse-slide 400ms forwards !important;
}

.actual-date-span {
  font-size: 18px;
}

.placeholder-date-span {
  font-size: 18px;
  color: #a9a9a9;
  opacity: 0.7;
}

input#actual-date-month {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.date-months-wrapper {
  position: relative;
}

.date-spans-wrapper {
  position: relative;
  margin-top: -2px;
}

span.actual-date-span {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.date-years-wrapper {
  position: relative;
}

input#actual-date-year {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

@media all and (max-width: 320px) {
  .datefield-wrapper {
    width: 58% !important;
  }
}

@media all and (max-width: 375px) and (min-width: 321px) {
  .datefield-wrapper {
    width: 48% !important;
  }
}

@media all and (max-width: 414px) and (min-width: 376px) {
  .datefield-wrapper {
    width: 44% !important;
  }
}

@media all and (max-width: 768px) and (min-width: 700px) {
  .datefield-wrapper {
    width: 22% !important;
  }
}

.datefield-wrapper {
  display: flex;
  width: 38%;
}

.hyphen-span-20 {
  margin-left: -24px;
}

.hyphen-span-10 {
  margin-left: -14px;
}

.redFont {
  color: red;
}

.soldOutTxt {
  color: var(--second-theme-color);
  font-size: 14px;
  font-family: var(--old-font-Roboto);
}

.soldoutOk {
  color: var(--second-theme-color);
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 5px 1px 5px;
  border: 1px solid;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  border-radius: 12px;
  cursor: pointer;
  min-width: 90px;
  position: relative;
}

.soldoutOkContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.disable-click {
  pointer-events: none;
}

@media all and (max-width: 991px) {
  ul.extrasList {
    overflow: auto;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}

.timeDisc span.error {
  border: 1px solid red;
  color: red;
  display: inline-block;
  -webkit-animation: shake 0.82s linear both;
  animation: shake 0.82s linear both;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.timeDisc .time-info.error,
.icon-Clock.error {
  color: red;
  display: inline-block;
  -webkit-animation: shake 0.82s linear both;
  animation: shake 0.82s linear both;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.deliveryWrapFinal .viewOrderContainer {
  width: 100%;
  text-align: center;
  padding-bottom: 2rem;
}

.deliveryWrapFinal .viewOrderButton {
  cursor: pointer;
  display: inline-block;
  color: var(--main-theme-color);
  font-size: 1.4em;
  line-height: normal;
  --current-tracking-Roboto: 0.017em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  border: 1px solid var(--main-theme-color);
  border-radius: 5px;
  padding: 5px 11px 5px;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: normal;
}

.orderViewTracking .fixedDiv {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: 46px 20px 0;
}

.orderViewTracking .visible-xs.reviewBarM {
  /*bottom: 0;*/
}

.aboutWrap.orderViewTracking .visible-xs.close-x.thickcon-X {
  padding-left: 20px;
}

.aboutWrap.orderViewTracking .detailsTitle {
  padding-bottom: 18px;
}

.orderTrackerInput input {
  border: 0;
  width: 100%;
}

.desktopViewOrderContainer .reviewBarM {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #ebebeb;
}
.desktopViewOrderContainer .reviewOrderWrapper {
  padding: 2rem 3rem;
  min-width: 25vw;
  height: calc(100vh - 400px);
  overflow-y: auto;
}

.desktopViewOrderContainer .addressMobileBar {
  min-width: 25vw;
}

.desktopViewOrderContainer .reviewBarM {
  min-width: 25vw;
}

.showViewClosingAnimation {
  right: -25%;
  transition: right 0.6s !important;
}

.map-items {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-flow: column;
}

.map-items .showFullWidth {
  width: 100%;
}

.desktopViewOrderContainer .notesOverlay .detailsTitle {
  padding-top: 66px;
}

.desktopViewOrderContainer .notesOverlay .close-x.thickcon-X {
  position: absolute;
  font-size: 2em;
  top: 0;
  left: -2px;
  padding: 26px 25px 20px 20px;
  cursor: pointer;
}

.desktopViewOrderContainer .notesOverlay.active {
  top: 1%;
}

@media all and (max-width: 1500px) {
  .showDesktopViewContainer {
    width: 495px;
  }

  .desktopViewOrder {
    width: calc(100% - 495px);
  }
}

@media all and (max-width: 1500px) and (min-width: 1300px) {
  .showDesktopViewContainer {
    width: 416px;
  }

  .desktopViewOrder {
    width: calc(100% - 416px);
  }
}

@media all and (max-width: 1300px) and (min-width: 991px) {
  .showDesktopViewContainer {
    width: 380px;
  }

  .desktopViewOrder {
    width: calc(100% - 380px);
  }
}

@media all and (min-width: 992px) {
  .oneDel {
    position: relative;
    top: 18px;
  }
  .deliveryWrapFinal.dine-in .oneDel {
    top: 0;
    width: 100%;
    text-align: center;
    padding: 0;
  }
}

.width100 {
  width: 100%;
}

.Collapsible {
  background-color: white;
  border-bottom: none;
}

.h-unset .Collapsible__contentInner {
  max-height: unset;
}

.Collapsible__contentInner {
  padding: 10px 20px 0;
  border-top: 0;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Collapsible__trigger {
  display: block;
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  text-decoration: none;
  position: relative;
  padding: 12px 20px;
  font-size: 1.95em;
  cursor: pointer;
}

.Collapsible:last-child .Collapsible__trigger {
  border-bottom: 0.1rem solid #dbdbdb;
}

.Collapsible__trigger.is-open {
  background: var(--main-theme-color);
  color: white;
}

.Collapsible__trigger.is-closed {
  background: #fafafa;
  color: #484848;
  border-top: 0.1rem solid #dbdbdb;
  /* border-bottom: 0.1rem solid #dbdbdb; */
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  line-height: 1.25;
}

.Collapsible__trigger:after {
  font-family: 'sot-icom' !important;
  content: '\e904';
  position: absolute;
  right: 1.8rem;
  top: 9px;
  padding-right: 10px;
  display: block;
  width: 4.2rem;
  height: 4.2rem;
  font-size: 2.6rem;
  text-align: center;
  line-height: 4.2rem;
  transition: transform 300ms;
  transform: rotate(90deg);
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(270deg);
  padding-left: 10px;
}

.subModifer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.6rem;
}

.subModifier-radio-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  top: -0.7rem;
  margin-right: 1rem;
}

.subModifier-radio-container span {
  position: relative;
  z-index: 3;
  display: block;
  width: 1.8rem;
  height: 2.8rem;
  pointer-events: none;
  cursor: pointer;
}

.subModifier-radio-container span::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1.3rem;
  left: 0;
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  border: 0.1rem solid var(--main-theme-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  color: var(--main-theme-color);
  text-align: center;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 50% 45%;
  background-size: 1.8rem;
  --current-tracking-Roboto: -0.01em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  -webkit-box-shadow: inset 0 0.025rem 0.4rem 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: inset 0 0.025rem 0.4rem 0 rgba(0, 0, 0, 0.19);
  box-shadow: inset 0 0.025rem 0.4rem 0 rgba(0, 0, 0, 0.19);
}

.subModifier-radio-container.checkbox span::before {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.subModifier-radio-container input[type='radio']:checked + span:before {
  background-color: var(--main-theme-color);
  border-color: var(--main-theme-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_1' data-name='Layer 1' viewBox='0 0 25 25'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ffffff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3ECheckmark%3C/title%3E%3Cpath class='cls-1' d='M8.15,22.16a1.49,1.49,0,0,1-1.06-.44L-.06,14.57l2.11-2.11,6.1,6.1L22.83,3.87,24.94,6,9.2,21.72A1.49,1.49,0,0,1,8.15,22.16Z'/%3E%3C/svg%3E");
}

.subModifier-radio-container input[type='checkbox']:checked + span:before {
  background-color: var(--main-theme-color);
  border-color: var(--main-theme-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_1' data-name='Layer 1' viewBox='0 0 25 25'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ffffff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3ECheckmark%3C/title%3E%3Cpath class='cls-1' d='M8.15,22.16a1.49,1.49,0,0,1-1.06-.44L-.06,14.57l2.11-2.11,6.1,6.1L22.83,3.87,24.94,6,9.2,21.72A1.49,1.49,0,0,1,8.15,22.16Z'/%3E%3C/svg%3E");
}

.subModifier-radio {
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: -440px;
  width: 480px;
  height: 100%;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
  opacity: 0;
}

@media screen and (min-width: 991px) {
  .subModifier-radio-container {
    margin-right: 0.6rem;
  }
}

@media screen and (min-width: 767px) {
  .Collapsible__contentOuter {
    /*margin-right: 5px;*/
  }
}

@media screen and (min-width: 767px) {
  .accordion-main-container {
    /* height: 40vh;
    overflow-y: auto; */
  }
}

@media screen and (max-width: 320px) {
  .subModifer-container p {
    font-size: 1.65em !important;
  }
}

.arrow-remove .Collapsible__trigger::after {
  display: none;
}

.gpay-button {
  background-image: url(https://www.gstatic.com/instantbuy/svg/dark/en.svg) !important;
  height: 40px;
  width: 100%;
  background-color: #000 !important;
  box-shadow: none;
  padding: 12px 24px 10px;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  background-origin: content-box;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.gpay-container {
  height: 64px;
  width: 100%;
}

.gpay-border .gpay-button {
  border: 1px solid var(--main-theme-color);
}

/* GLOBAL MODAL */

.global-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}

.global-modal-wrapper--active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s ease-in 0s;
}

.global-modal-wrapper--active .global-modal {
  transform: translate(-50%, -50%);
}

.global-modal-wrapper__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.16);
  z-index: 100;
}

.global-modal {
  position: absolute;
  transform: translate(-50%, -200%);
  top: 50%;
  left: 50%;
  width: 408px;
  min-height: calc(301px - (27px + 27px));
  padding: 27px 21px 22px;
  color: var(--main-text-color);
  font-family: var(--old-font-HelveticaNeue);
  --current-tracking-HelveticaNeue: 0em;
  letter-spacing: calc(
    var(--current-tracking-HelveticaNeue) -
      var(--tracking-offset-HelveticaNeue-normal, 0em)
  );
  border-radius: 27px;
  background-color: white;
  text-align: center;
  z-index: 101;
  transition: transform 0.15s ease-in 0s;
}

.global-modal__close {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  z-index: 1;
}

.global-modal__title {
  font-size: 30px;
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-HelveticaNeue) -
      var(--tracking-offset-HelveticaNeue-medium, 0em)
  );
  margin: 18px 0 19px 0;
}

.notification-modal__header {
  margin: 18px 0 19px 0;
}

.global-modal__desc {
  font-size: 20px;
  font-weight: var(--old-font-light);
  line-height: 34px;
  padding: 0 0 17px 0;
  --current-tracking-HelveticaNeue: 0.025em;
  letter-spacing: calc(
    var(--current-tracking-HelveticaNeue) -
      var(--tracking-offset-HelveticaNeue-light, 0em)
  );
}

.global-modal__desc strong {
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-HelveticaNeue) -
      var(--tracking-offset-HelveticaNeue-normal, 0em)
  );
}

.global-modal-btn {
  width: 100%;
  height: 53px;
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-HelveticaNeue) -
      var(--tracking-offset-HelveticaNeue-medium, 0em)
  );
  padding: 11px 0;
  font-size: 21px;
  text-align: center;
  border-radius: 13px;
  color: white;
  background-color: var(--main-theme-color) !important;
}

.global-modal__body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.notification-modal {
  display: flex;
  flex-direction: column;
}

.notification-modal__desc {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.notification-modal-content-wrapper {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notification-img-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.notification-img-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.notification-img {
  margin-bottom: 10px;
}

.notification-modal .global-modal__footer {
  padding: 0;
  margin-top: auto;
}

@media (max-width: 767px) {
  .global-modal {
    width: 100%;
    top: auto;
    left: auto;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: translate(0, -300%);
    padding-left: 17px;
    padding-right: 17px;
  }
  .global-modal-wrapper--active .global-modal {
    transform: translate(0, 0);
  }
  .global-modal__title {
    font-size: 28px;
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-HelveticaNeue) -
        var(--tracking-offset-HelveticaNeue-medium, 0em)
    );
  }
  .global-modal__desc {
    font-size: 18.5px;
  }
  .global-modal__footer {
    padding: 0 4px;
  }
  .notification-modal .global-modal__footer {
    padding: 0;
  }
}

.tablesBoxInner {
  width: 100%;
  display: flex;
  padding: 8px 7px 9px 6px;
  position: relative;
}

.tablesBoxInner__no-tables {
  font-size: 1.5rem;
}

.tablesAccordionWrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 27px;
  cursor: pointer;
}

.tablesAccordionWrap__table {
  margin: 0;
  padding: 0 37px;
}

.tablesAccordionWrap__floor-item {
  list-style: none;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 2px;
  font-size: 1.5em;
  color: gray;
  margin-left: -15px;
}

.tablesAccordionWrap__table-item {
  list-style: none;
  padding: 10px 0;
  font-size: 1.5em;
  cursor: pointer;
}

.tablesAccordionWrap__table-item--selected,
.tablesAccordionWrap__table-item:hover {
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
}

.tablesName {
  width: 100%;
  padding-left: 6px;
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-size: 1.5em;
}

.tablesName.error {
  color: red;
}

.tablesAccordionWrap .rotateAccordion {
  top: 2px;
  right: 5px;
}

.tablesBoxInner > div.accordionMain {
  width: auto;
  right: -1px;
  z-index: 1;
  display: block;
  position: absolute;
  top: 100%;
  height: 0;
  transition: all 0.5s ease 0s;
  background-color: #fff;
  visibility: hidden;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  left: -1px;
  border-radius: 0 0 4px 4px;
}

.tablesBoxInner > div.accordionMain.active {
  height: 225px;
  visibility: visible;
}

.lottie-animation {
  display: flex;
  width: 100%;
  align-items: center;
}

.status-text {
  font-size: 3em;
  padding: 5% 8% 0%;
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  text-align: center;
}
.userReviewWrap.true {
  left: 0;
}

.userReviewWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 100%;
  z-index: 99999;
  transition: left 0.3s ease;
  padding: 0 20px;
  overscroll-behavior-y: contain;
  /* -webkit-overflow-scrolling: touch; */
}

.detailsTitle.uR {
  padding-top: 68px;
  padding-bottom: 12px;
}

.urLeftSec img {
  width: 7px;
  height: auto;
  margin-left: 3px;
  float: right;
}

.uRstarC {
  overflow: hidden;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
}

.uRleftCv {
  float: left;
}

.uRrightC {
  float: right;
  width: calc(100% - 71px);
}

.uRleftC {
  float: left;
}

.uRleftC > div:first-child {
  font-size: 4.75em;
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  line-height: normal;
  text-align: center;
}

.uRleftC > div:last-child {
  text-align: center;
  color: #918c8c;
  font-size: 1.375em;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  font-weight: var(--old-font-medium);
  margin-top: 2px;
  margin-left: -1px;
}

.urRow {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.urRow > div {
  float: left;
}

.urLeftSec {
  width: 55px;
}

.urRightSec {
  width: calc(100% - 61px);
  float: right !important;
  padding-left: 5px;
}

.progressBar {
  width: 100%;
  background-color: #f1f1f1;
  height: 4px;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.uRstarMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 53px;
  margin-bottom: 6px;
}

.prgFill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 3px;
  background-color: #dbdbdb;
}

.uRRev {
  float: right;
  font-size: 1.309em;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.rlrating > img {
  width: 13px;
  height: 13px;
  margin-right: 5px;
}

.rlTitle {
  font-size: 1.6em;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  margin-bottom: 4px;
}

/* @media all and (min-width: 768px) {
  .rlTitle {
    font-size: 1.2em;
  }

  .rlDate {
    font-size: 1.1em !important;
  }

  .rlRating {
    font-size: 1.2em !important;
  }
} */

.rlDate {
  font-size: 1.4em;
  line-height: normal;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  float: right;
  margin-top: 2px;
}

.rlTitleWrap {
  float: left;
}

.rlrating {
  display: flex;
}

.rlRating {
  font-size: 1.5em;
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  --current-tracking-Roboto: 0.02em;
  line-height: 25px;
  float: left;
  margin-top: 6px;
  width: 100%;
}

.reviewListItem {
  overflow: hidden;
  border-top: 1px solid #ebebeb;
  padding: 12px 8px 8px 2px;
  background: #fff;
}

.reviewListItem:first-child {
  border-top: 0 none;
}

.reviewListItem:last-child {
  border-bottom: 1px solid #ebebeb;
}

@media all and (max-width: 768px) {
  .reviewListWrap {
    height: calc(100% - 200px);
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    /* background: url(https://assets.nextorder.co/assets/loading.svg)
      center center no-repeat; */
  }
}

.reviewListWrap.loading {
  background: url(https://assets.nextorder.co/assets/loading.svg) center center
    no-repeat;
}

div:not(.rightSecA) > .ratingStars {
  display: none;
}

.ratingStars-looper {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.ratingStars > div {
  display: inline-block;
  position: relative;
  width: 16px;
  margin-left: 5px;
}

@media all and (min-width: 992px) {
  .ratingStars > div {
    display: inline-block;
    position: relative;
    width: 16px;
    margin-left: 5px;
  }
  /* .ratingStars {
    float: right;
    width: 38%;
    position: absolute;
    top: 25px;
    right: 17px;
    display: block;
  } */
  /* .uRstarC {
    display: none;
  } */
  .ratingStars > div > div {
    position: absolute;
    top: 0;
    overflow: hidden;
  }
  .ratingStars > div > div > img {
    width: 16px;
  }
  .userReviewWrap {
    position: absolute;
    width: 282px;
    height: 296px;
    left: auto !important;
    right: 178px;
    top: 59px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #dbdbdb;
    padding: 0 17px;
    opacity: 0;
    visibility: hidden;
    z-index: -999;
  }
  .userReviewWrap:before,
  .userReviewWrap:after {
    right: 13px;
    left: auto;
    border-bottom: 10px solid #dbdbdb;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: '';
    display: inline-block;
    position: absolute;
    top: -10px;
  }
  .userReviewWrap:after {
    top: -9px;
    border-bottom-color: white;
  }
  .userReviewWrap.true {
    left: auto !important;
    opacity: 1;
    visibility: visible;
    z-index: 99999;
    position: absolute;
    right: 0;
    top: calc(100% + 20px);
  }
  .detailsTitle.uR {
    padding-top: 17px;
    font-size: 1.5em;
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
  }
  .userReviewWrap .backToOrder.thickcon-Left-Chevron {
    display: none;
  }
  /* .reviewListWrap {
    height: calc(100% - 61px);
    overflow-y: auto;
  } */
  div:not(.rightSecA) > .ratingStars {
    display: block;
  }
}

@media all and (min-width: 1024px) and (max-width: 1024px) and (min-height: 1250px) {
  body.iPadPro .userReviewWrap.true {
    left: 0 !important;
  }
  body.iPadPro .userReviewWrap .backToOrder.thickcon-Left-Chevron,
  body.iPadPro .userReviewWrap .backToOrder.thickcon-Left-Chevron.active {
    display: block;
  }
  body.iPadPro .userReviewWrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 100%;
    z-index: 99999;
    transition: left 0.3s ease;
    padding: 0 20px;
    overscroll-behavior-y: contain;
    /* -webkit-overflow-scrolling: touch; */
  }
  body.iPadPro .detailsTitle.uR {
    padding-top: 68px;
    padding-bottom: 12px;
  }
  body.iPadPro .urLeftSec img {
    width: 7px;
    height: auto;
    margin-left: 3px;
    float: right;
  }
  body.iPadPro .ratingStars {
    float: none;
    width: auto;
    position: static;
    top: auto;
    right: auto;
  }
  body.iPadPro .uRstarC {
    overflow: hidden;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebebeb;
    display: block;
  }
  body.iPadPro .uRleftCv {
    float: left;
  }
  body.iPadPro .uRrightC {
    float: right;
    width: calc(100% - 71px);
  }
  body.iPadPro .uRleftC {
    float: left;
  }
  body.iPadPro .uRleftC > div:first-child {
    font-size: 4.75em;
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
    --current-tracking-Roboto: 0.02em;
    line-height: normal;
    text-align: center;
  }
  body.iPadPro .uRleftC > div:last-child {
    text-align: center;
    color: #918c8c;
    font-size: 1.375em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
    font-weight: var(--old-font-medium);
    margin-top: 2px;
    margin-left: -1px;
  }
  body.iPadPro .urRow {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  body.iPadPro .urRow > div {
    float: left;
  }
  body.iPadPro .urLeftSec {
    width: 55px;
  }
  body.iPadPro .urRightSec {
    width: calc(100% - 61px);
    float: right !important;
    padding-left: 5px;
  }
  body.iPadPro .progressBar {
    width: 100%;
    background-color: #f1f1f1;
    height: 4px;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
  }
  body.iPadPro .uRstarMain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 53px;
    margin-bottom: 6px;
  }
  body.iPadPro .prgFill {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 3px;
    background-color: #dbdbdb;
  }
  body.iPadPro .uRRev {
    float: right;
    font-size: 1.309em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  body.iPadPro .rlrating > img {
    width: 13px;
    height: 13px;
    margin-right: 5px;
  }
  body.iPadPro .rlTitle {
    font-size: 1.5em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    margin-bottom: 4px;
  }
  body.iPadPro .rlDate {
    font-size: 1.35em;
    line-height: normal;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    float: right;
    margin-top: 2px;
  }
  body.iPadPro .rlTitleWrap {
    float: left;
  }
  body.iPadPro .rlRating {
    font-size: 1.5em;
    font-weight: var(--old-font-light);
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
    );
    line-height: 25px;
    float: left;
    margin-top: 6px;
    width: 100%;
  }
  body.iPadPro .reviewListItem {
    overflow: hidden;
    border-top: 1px solid #ebebeb;
    padding-top: 12px;
    padding-bottom: 8px;
  }
  body.iPadPro .reviewListItem:first-child {
    border-top: 0 none;
  }
  body.iPadPro .reviewListItem:last-child {
    border-bottom: 1px solid #ebebeb;
  }
  body.iPadPro .reviewListWrap {
    height: calc(100% - 200px);
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
  }
  body.iPadPro div:not(.rightSecA) > .ratingStars {
    display: none;
  }
}

.reviewListWrap::-webkit-scrollbar {
  display: none;
}

.reviewListWrap.loading .reviewListItem:last-child {
  padding-bottom: 60px;
  position: relative;
}

.reviewListWrap.loading .reviewListItem.allReviewsLoaded:last-child {
  padding-bottom: 0 !important;
}

.reviewListWrap.loading .reviewListItem:last-child::after {
  content: '';
  background: url(https://assets.nextorder.co/assets/loading.svg) center center
    no-repeat;
  width: 100%;
  height: 50px;
  display: block;
  position: absolute;
  z-index: 10;
  bottom: 0;
}

.reviewListWrap .reviewListItem.allReviewsLoaded:last-child::after {
  content: none !important;
}

.openHWrap.true {
  left: 0;
}

.openHWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 100%;
  z-index: 99999;
  transition: left 0.3s ease;
  padding: 0 18px;
  overscroll-behavior-y: contain;
  /* -webkit-overflow-scrolling: touch; */
}

.detailsTitle.oH {
  padding-top: 68px;
  padding-bottom: 6px;
}

.openHWrap ul {
  padding: 0;
  list-style-type: none;
  font-size: 1.725em;
  margin-top: 11px;
  height: calc(100% - 124px);
  overflow-y: auto;
  margin-left: -1px;
  overscroll-behavior-y: contain;
}

.openHWrap ul li span {
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  float: right;
}

.openHWrap ul li {
  border-bottom: 1px solid #ebebeb;
  padding: 19px 0;
}

.about-slide-in-animation {
  animation: slide-in-for-about 300ms forwards !important;
}

.swiper-slide-in-animation {
  animation: slide-in-for-about 300ms forwards !important;
}

.about-slide-out-animation {
  animation: slide-out-for-about 300ms forwards !important;
}

@keyframes slide-in-for-about {
  0% {
    transform: translate(100%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes slide-out-for-about {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(100%, 0%);
  }
}

.backIcon {
  padding-top: 2rem;
  font-size: 2em;
  cursor: pointer;
}

.margin-2-rem {
  margin-left: 2rem;
}

.booking-main-section {
  margin: 0rem 0rem 0rem;
  position: relative;
  height: calc(100vh - 96px);
}

.reservation-main-label {
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  font-size: 2.625rem;
  margin: 2.3rem 2.6rem;
}

.address-booking-bar {
  font-size: 2.125rem;
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
}

.address-time-bar {
  color: #8e8e8e;
  padding: 16px 0;
  display: flex;
}

.restaurant-address {
  border-bottom: 1px solid #dbdbdb;
  padding: 18px 0;
  display: flex;
  font-weight: var(--old-font-extralight);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.align-with-label {
  margin-left: 0.6rem;
}

.align-address {
  margin-top: -3px;
  margin-left: 1.5rem;
  font-weight: var(--old-font-extralight);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.zoom-icon {
  font-size: 24px;
  margin-left: 2.26rem;
}

.persons-selection-section {
  margin-top: 2.4rem;
  margin-left: 2.4rem;
  margin-right: 2rem;
}

.persons-selection-label {
  font-size: 2.125rem;
}

.align-persons-section {
  /* padding: 2rem; */
  border-bottom: none !important;
  padding-top: 1.5rem;
}
.align-persons-section .pepCount {
  font-size: 16px;
}

.align-persons-section .pepDesc {
  width: 28px;
  height: 28px;
}

.align-persons-section .pepDesc img {
  top: 4px;
  right: 4px;
}

.align-persons-section .pepInc {
  width: 28px;
  height: 28px;
}

.align-persons-section .pepInc img {
  top: 4px;
  right: 4px;
}

.align-persons-section .pepCount {
  width: 42px;
}

.booking-notes-section {
  position: relative;
  margin: 0rem 2.6rem;
  margin-top: -24px;
}

.notes-label {
  font-size: 2.425rem;
  margin-top: 4rem;
  position: relative;
  z-index: 1;
  display: inline-block;
  background: white;
  float: none;
  padding: 0rem 1rem 0rem 0rem;
}

.notes-input {
  width: 100%;
  border-left: 0px;
  border-right: 0px;
  padding: 2rem 0;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  font-size: 2rem;
  position: relative;
  top: -16px;
}

.visible-md.timeSlots {
  width: 100%;
  position: absolute;
  bottom: -100%;
  z-index: -5;
  left: 0;
  transition: all 0.3s linear;
  background-color: #fff;
}

.visible-md.timeSlots.active {
  bottom: -22px;
  z-index: 9999;
}

.visible-md.overlayTime {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #535151;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  visibility: hidden;
  z-index: -999;
  transition: opacity 0.3s linear;
}
.visible-md.overlayTime.active {
  opacity: 0.24;
  z-index: 1;
  visibility: visible;
}

/* .place-order-button {
  position: absolute;
  width: 100%;
  bottom: 0;
} */

.visible-md .swiperWeek {
  border-bottom: 2px solid #e4e4e4;
}

.visible-md .swiperWeek .swiper-slide.tabSUL.active {
  color: var(--second-theme-color);
}

.visible-md .swiperWeek .tabSUL:first-child {
  margin-left: 15px;
}

.visible-md .swiperWeek .swiper-slide.tabSUL {
  font-size: 1.168em;
  line-height: normal;
  margin-top: 15px;
  padding-bottom: 14px;
  padding-top: 0;
}

.visible-md .swiperWeek .swiper-slide.tabSUL.active {
  color: var(--second-theme-color);
}

.visible-md .swiperWeek .swiper-slide.tabSUL {
  font-size: 1.168em;
  line-height: normal;
  margin-top: 15px;
  padding-bottom: 14px;
  padding-top: 0;
}

.visible-md .swiperWeek .tabSUL:first-child {
  margin-left: 15px;
}

.visible-md .swiperTime .picker-container .picker-inner {
  font-size: 1.873em;
  --current-tracking-Roboto: 0.05em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  -webkit-mask-box-image: none;
}

.visible-md .swiperTime .picker-container .picker-column .picker-scroller {
  transition: transform 0.3s linear 0s;
}

.visible-md .selectTime {
  background-color: var(--main-theme-color);
  color: #fff;
  text-align: center;
  font-size: 1.625em;
  line-height: normal;
  padding: 16px 5px;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  position: relative;
  z-index: 9;
}

.visible-md .swiper-slide.subTimeXS.swiper-slide-active {
  max-height: 175px;
  overflow: hidden;
  min-height: 175px;
}

.visible-md .swiper-slide.tabSUL:hover {
  color: #4b4a4a;
}

.visible-md .swiper-slide .picker-item {
  color: #6f6f6f;
}

.visible-md span.swiper-slide.slidebarExtras,
.swiperWeek span.swiper-slide.slidebarExtras {
  position: absolute;
  width: 20px;
  height: 2px;
  background: var(--second-theme-color);
  bottom: -2px;
  left: 20px;
  transition: all 0.3s linear 0s;
}

.visible-md .mbsc-mobiscroll .mbsc-sc-whl-gr {
  padding: 0 !important;
}
.visible-md .mbsc-mobiscroll .mbsc-sc-itm {
  font-size: 18.73px !important;
  --current-tracking-Roboto: 0.93px;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  color: #4b4a4a;
  line-height: normal !important;
  padding-top: 11px !important;
}
.visible-md .mbsc-mobiscroll .mbsc-fr-w {
  background-color: rgba(0, 0, 0, 0) !important;
}
.visible-md .mbsc-mobiscroll .mbsc-sc-whl-l {
  border-color: rgba(0, 0, 0, 0) !important;
}
.visible-md .mbsc-mobiscroll .mbsc-sc-itm.mbsc-active {
  background-color: rgba(0, 0, 0, 0) !important;
}
.visible-md .mbsc-mobiscroll .mbsc-sc-whl-l:before,
.visible-md .mbsc-mobiscroll .mbsc-sc-whl-l:after {
  position: absolute;
  content: '';
  width: 9999px;
  height: 1px;
  background-color: #ebebeb;
  top: 0;
  left: -999px;
}
.visible-md .mbsc-mobiscroll .mbsc-sc-whl-l:after {
  top: auto;
  bottom: 0;
}

.address-time-bar.pick.true {
  color: red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

.align-persons-section.resTable.true {
  color: red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

.align-confirmation-labels {
  display: flex;
  justify-content: space-between;
}

.pointer-effect {
  cursor: pointer;
}

.visible-md .picker-column:after,
.visible-md .swiper-slide.subTimeXS:after {
  content: '';
  width: 100%;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 90px;
  margin: 0 auto;
  right: 0;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.8) 74%,
    rgba(255, 255, 255, 0.46) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.8) 74%,
    rgba(255, 255, 255, 0.46) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8) 74%,
    rgba(255, 255, 255, 0.46) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=0 );
}
.visible-md .mbsc-w-p.mbsc-sc-whl-gr-c {
  top: -21px;
}
.visible-md .swiperTime .picker-container .picker-column .picker-scroller {
  transition: transform 0.3s linear 0s;
}

.visible-md .swiperWeek .swiper-container {
  overflow: visible !important;
}
.visible-md .swiper-wrapper,
.visible-md .swiper-container {
  transition: transform 0.3s linear !important;
}

.children-label {
  padding-top: 7px;
}

.persons-selection-section .typeOfPep > span {
  margin-top: 7px;
  margin-left: -2px;
}

.infants-label {
  margin-top: -5px;
}

.children-span {
  font-size: 16px;
  margin-left: 2px;
}

#placeBooking.totalbottom {
  bottom: -105px;
  z-index: 2;
  transition: all 0.2s;
}

#placeBooking.totalbottom.true {
  bottom: -25px;
  z-index: 2;
  transition: all 0.2s;
}

#placeBook.totalbottom {
  bottom: -25px;
}

.booking-confirm-button {
  font-size: 1.713em;
  --current-tracking-Roboto: 0.03em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  background-color: var(--main-theme-color);
  clear: both;
  color: #fff;
  text-align: center;
  line-height: normal;
  padding: 10px 5px;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
}

@media all and (min-width: 1025px) and (max-height: 767px) {
  .reservation-main-label {
    margin: 10px 2.6rem;
  }

  .restaurant-address {
    padding: 12px 0;
  }

  .address-time-bar {
    padding: 11px 0;
  }

  .persons-selection-section {
    margin-top: 10px;
  }
  .align-persons-section {
    padding-top: 1.5rem;
  }

  .children-label,
  .infants-label {
    display: inline-block;
  }

  .persons-selection-section .typeOfPep > span,
  .persons-selection-section .typeOfPep > span {
    display: inline-block;
    margin-left: 5px;
  }

  .persons-selection-section .typeOfPep > span::before,
  .persons-selection-section .typeOfPep > span:before {
    content: '(';
  }

  .persons-selection-section .typeOfPep > span::after,
  .persons-selection-section .typeOfPep > span:after {
    content: ')';
  }

  .notes-input {
    padding: 15px 0;
  }

  .totalbottom {
    padding-bottom: 5px;
    border-top: 0px;
  }

  .booking-notes-section {
    margin-top: -36px;
  }
}

.book-views .mbsc-mobiscroll .mbsc-sc-itm.mbsc-disabled {
  visibility: hidden;
}

.book-views .mbsc-mobiscroll .mbsc-sc-whl-gr {
  padding: 0 !important;
}

.book-views .mbsc-mobiscroll .mbsc-sc-itm {
  font-size: 18.73px !important;
  --current-tracking-Roboto: 0.93px;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  color: #4b4a4a;
  line-height: normal !important;
  padding-top: 11px !important;
}

.book-views .mbsc-mobiscroll .mbsc-fr-w {
  background-color: rgba(0, 0, 0, 0) !important;
}

.book-views .mbsc-mobiscroll .mbsc-sc-whl-l {
  border-color: rgba(0, 0, 0, 0) !important;
}

.book-views .mbsc-mobiscroll .mbsc-sc-itm.mbsc-active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.book-views .mbsc-mobiscroll .mbsc-sc-whl-l:before,
.book-views .mbsc-mobiscroll .mbsc-sc-whl-l:after {
  position: absolute;
  content: '';
  width: 9999px;
  height: 1px;
  background-color: #ebebeb;
  top: 0;
  left: -999px;
}
.book-views .mbsc-mobiscroll .mbsc-sc-whl-l:after {
  top: auto;
  bottom: 0;
}

.book-views .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover {
  background: none;
}

.booking-main-section .totalbottom {
  border-top: none;
}

.booking-main-section .close-book-back {
  margin-left: -0.8rem;
  margin-bottom: 1.1rem;
  padding-top: 1.5rem;
}

.book-views {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  z-index: 4;
  padding-top: 14px;
  background-color: #fff;
}
.book-views .card__info-item .book-views-icon {
  position: absolute;
  top: 50%;
  left: 3.25rem;
  font-size: 2.7rem;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.book-views .card__info-item .book-views-icon.icon-Clock {
  font-size: 2.3rem;
}
.book-views .card__info {
  margin: 0 -1.9rem 2.8rem;
  box-shadow: 1rem 0 1.8rem 0 rgba(0, 0, 0, 0.08);
}
.book-views .card__info-item:first-child {
  border-top-color: #dbdbdb;
}
.book-views .card__info-item {
  position: relative;
  color: #484848;
  font-size: 1.95rem;
  font-weight: var(--old-font-light);
  --current-tracking-Roboto: 0em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  word-spacing: 0.08rem;
  border-bottom-color: #dbdbdb;
}
.book-views .card__info-item:last-child {
  border-bottom: 0.1rem solid #dbdbdb;
}
.book-views .card__info-item div {
  padding: 1.95rem 1.9rem 2rem 6rem;
}
.book-views .stickyTitle {
  margin-left: -0.2rem;
  margin-bottom: 2rem;
}
.book-views .close-book-back {
  margin-left: -0.8rem;
  margin-bottom: 1.1rem;
}
.book-views .close-book-back .thickcon-Left-Chevron {
  font-size: 2.3rem;
}
.book-views .cItemName {
  width: 80%;
  padding-left: 0.1rem;
  font-size: 2.15rem;
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  text-transform: initial;
}
.book-views .cItemQuantity {
  width: 20%;
}
.book-views span.cartCount {
  position: relative;
  top: 0.3rem;
  left: -0.1rem;
  font-size: 1.5em;
  width: 3.9rem;
}
.book-views .cartRow {
  height: 6.4rem;
}
tr.cartRow > td.cItemQuantity {
}
.book-views .cItemNameInfo {
  display: block;
  margin-top: 0.6rem;
  font-size: 1.6rem;
  color: #3e3832;
}
.book-views tr.cartRow > td {
  padding-bottom: 3.4rem;
}
.book-views .confirmBtn {
  margin: 0 0.3rem;
}
.book-views .inputGroup {
  border-top: 0.1rem solid #dbdbdb;
  border-bottom: 0.1rem solid #dbdbdb;
  margin-top: 1.6rem;
  margin-left: 0.2rem;
  margin-bottom: 2.5rem;
}

.book-views .inputGroup span {
  float: left;
  margin-top: -1.5rem;
  padding-right: 1.5rem;
  font-size: 2.1rem;
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  text-transform: initial;
  background-color: #fff;
}
.book-views .inputGroup textarea,
.book-views .inputGroup input {
  clear: both;
  width: 100%;
  height: 5.6rem;
  border: none;
  display: block;
  font-weight: var(--old-font-light);
  font-size: 1.9rem;
  word-spacing: 0.02rem;
  --current-tracking-Roboto: 0.03rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  margin-top: 1rem;
  padding: 1.2rem 0.1rem 1rem;
  resize: none;
}
#sticky .book-views .totalbottom {
  padding-top: 0.5rem;
  left: 0;
  right: 0;
  border: none;
}
.book-views .pickOrOrderWrap {
  height: -webkit-calc(100% - 42.5rem);
  height: calc(100% - 42.5rem);
}

.font-100 {
  font-weight: 100;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.card__cntrl__booking {
  position: relative;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.card__cntrl__booking::before {
  content: '';
  position: absolute;
  width: 1.2rem;
  height: 2.1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../../assets/images/l-arrow.svg');
  background-size: cover;
}

.selectTimeDesktop {
  background-color: var(--main-theme-color);
  color: #fff;
  text-align: center;
  font-size: 1.625em;
  line-height: normal;
  padding: 10px 5px;
  --current-tracking-Roboto: 0.014em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  position: relative;
  z-index: 9;
}

.padding-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.book-views #time-select-box .time-box {
  bottom: -1px;
}

.book-views .overlayTime.active {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #b5b1b1;
  opacity: 0.25;
}

.book-views .cMinusPlusBtn.CMinusBtn.disabled:after,
.book-views .cMinusPlusBtn.CPlusBtn.disabled:after,
.pepDesc.disabled:after {
  background-color: transparent;
}

.book-views .card__info {
  background: none;
}

.book-views .card__info-item {
  border-top: 0.1rem solid rgba(181, 177, 177, 0.25);
}

.book-views .calendar .day.event:after {
  margin-left: -3px;
}

.book-views .calendar .day.event.two-digits:after {
  margin-left: -10px;
}

.book-views .addB2.pick.true {
  color: red;
  -webkit-animation: shake 0.82s linear both;
  animation: shake 0.82s linear both;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.addB2.pick.true {
  color: red;
  -webkit-animation: shake 0.82s linear both;
  animation: shake 0.82s linear both;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.book-views .icon-Clock.pick.true {
  color: red;
  -webkit-animation: shake 0.82s linear;
  animation: shake 0.82s linear;
}

@keyframes shakeN {
  10%,
  90% {
    transform: translateX(-1px);
    top: 30%;
    left: 8%;
  }
  20%,
  80% {
    transform: translateX(2px);
    top: 30%;
    left: 8%;
  }
  30%,
  50%,
  70% {
    transform: translateX(-4px);
    top: 30%;
    left: 8%;
  }
  40%,
  60% {
    transform: translateX(4px);
    top: 30%;
    left: 8%;
  }
}

.book-views .address-booking-bar {
  font-size: 1.95rem;
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  word-spacing: 0.08rem;
}

.booking-main-section .inputGroup {
  border-top: 0.1rem solid #dbdbdb;
  border-bottom: 0.1rem solid #dbdbdb;
  margin-top: 1.6rem;
  margin-left: 0.2rem;
  margin-bottom: 2.5rem;
}

.booking-main-section .inputGroup span {
  float: left;
  margin-top: -1.5rem;
  padding-right: 1.5rem;
  font-size: 2.1rem;
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  text-transform: initial;
  background-color: #fff;
}
.booking-main-section .inputGroup textarea,
.booking-main-section .inputGroup input {
  clear: both;
  width: 100%;
  height: 5.6rem;
  border: none;
  display: block;
  font-weight: var(--old-font-light);
  font-size: 1.9rem;
  word-spacing: 0.02rem;
  --current-tracking-Roboto: 0.03rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  margin-top: 1rem;
  padding: 1.2rem 0.1rem 1rem;
  resize: none;
}

.waiting-screen-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  left: 100%;
  z-index: 6;
  transition: left 0.3s ease;
}

.waiting-screen-container.true {
  left: 0;
}

.waiting-screen-container #section-booking .map-box {
  height: 81%;
}

.waiting-screen-container #section-booking .content-box {
  height: 28%;
}

.confirmation-screen-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  left: 100%;
  z-index: 5;
  transition: left 0.3s ease;
}

.confirmation-screen-container.true {
  left: 0;
}

.table-booking-close-animation {
  animation: slide-down 400ms forwards !important;
  margin-left: -10px;
}

.tabsSlider--menuWrapper {
  display: flex;
  flex-direction: column;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  white-space: nowrap;
  /* padding-left: 0px !important; */
}

.tabsSlider--menuList {
  padding: 0;
  /* height: 46px; */
  display: flex;
  line-height: 31px;
  margin: 0;

  margin-top: -8px;
}

.tabsSlider--menuUnderline {
  height: 2px;
  margin-top: 5px;
  width: 53px;
  background-color: var(--second-theme-color);
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s,
    width 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.popular-width-desktop {
  max-width: 53px;
}

.popular-width {
  max-width: 57px;
}

.popular-color {
  color: var(--second-theme-color);
}

.tabsSlider--menu {
  margin: 0 30px 0 0;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
}

.tabsSlider--menu:last-child {
  margin-right: 0;
}

.tabsSlider--menuLink {
  text-decoration: none;
  color: #4b4a4a;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 12px;
  --current-tracking-Roboto: 0.04em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-semibold);
}

.tabsSlider--menuItem-selected .tabsSlider--menuLink,
.tabsSlider--menu .tabsSlider--menuLink:hover,
.tabsSlider--menu .tabsSlider--menuLink:focus,
.tabsSlider--menu .tabsSlider--menuLink:active {
  color: var(--second-theme-color);
}

.tabsSlider--menu-active .tabsSlider--menuLink {
  color: var(--second-theme-color);
}

.tabsSlider--tabsWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  -webkit-overflow-scrolling: touch;
}

.spHide ~ .tabsSlider--menuWrapper {
  transform: none !important;
}

.tabsSlider--tab {
  overflow: auto;
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  font-size: 2em;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  scrollbar-width: none; /* Firefox */
  padding: 19px 14px 0px;
  background-color: white;
  will-change: transform, position;
}

li.itemBoxes.boxWrapper.half-half div.imgWrap,
li.itemBoxes.boxWrapper.half-half div.prodIngredients {
  display: none;
}

@media all and (min-width: 990px) {
  .tabsSlider--tab {
    position: absolute !important;
    overflow-y: scroll;
  }

  .tabsSlider--menuLink {
    font-size: 11px;
  }
}

@media all and (max-width: 767px) {
  .tabsSlider--menuUnderline {
    margin-top: 5px;
  }
}

.tabsSlider--tab::-webkit-scrollbar {
  display: none;
}

.listview-wrapper {
  display: none;
}

.specialsSlide {
  margin-top: -10px;
}

.col-md-8.customWidth {
  z-index: 30;
  background-color: white;
}

.specialsSlide ul {
  padding-left: 0px;
  padding-top: 10px;
}
.aboutWrap.true {
  top: 0;
}

.aboutWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 100%;
  left: 0;
  z-index: 99999;
  transition: top 0.3s ease;
  overflow-y: hidden;
  /* -webkit-overflow-scrolling: touch; */
}

.rightSecA > img {
  width: 20px;
  height: auto;
  display: inline-block;
  margin-left: 5px;
}

.aboutTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 63px;
  padding: 0px 20px;
}

.titleA {
  font-size: 2.05em;
  font-weight: var(--old-font-bold);
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  margin-bottom: 7px;
}

.leftSecA {
  line-height: normal;
}

.subTitleA {
  font-size: 1.25em;
  text-transform: uppercase;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  font-weight: var(--old-font-bold);
  color: #898585;
}

.aboutWrap .visible-xs.close-x.thickcon-X {
  padding-left: 15px;
}

.about1 {
  /* height: calc(100% - 127px); */
  overflow-y: auto;
  margin-top: 16px;
  max-height: 540px;
  width: 100%;
  /*Below properties are added*/
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  overflow: scroll;
}
/* @media all and (min-height: 671px) and (max-width: 767px) {
  .about1 {
    max-height: inherit;
    height: auto;
  }
} */

.mapContainer,
.mapContainer > div {
  height: 220px;
  pointer-events: none;
  overflow: hidden;
}

.aboutContent li {
  font-size: 1.425em;
  border-bottom: 1px solid #ebebeb;
  padding: 16px 15px;
  position: relative;
  cursor: pointer;
}

.aboutContent li:last-child {
  border-bottom: 0 none;
}

.aboutContent ul {
  margin-left: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.aboutContent li i.thickcon-Right-Chevron {
  position: absolute;
  right: 15px;
  top: 17px;
  font-size: 18px;
}

.aboutContent li strong {
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
}

.aboutContent li a {
  font-weight: var(--old-font-medium);
  text-transform: uppercase;
  color: #6fbd54;
  border: 1px solid #6fbd54;
  --current-tracking-Roboto: 0.05em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  border-radius: 4px;
  font-size: 0.842em;
  padding: 2px 8px 0px;
  position: absolute;
  right: 12px;
  top: 17px;
}

.appleDevice .aboutContent li a {
  padding: 1px 8px 1px;
}

.aboutWrap .gm-style-mtc,
.aboutWrap button.gm-control-active.gm-fullscreen-control,
.aboutWrap .gm-svpc,
.aboutWrap .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  display: none;
}

.aboutWrap .ratingStars > div {
  display: inline-block;
  position: relative;
  width: 16px;
  margin-left: 5px;
}

.aboutWrap .ratingStars {
  display: flex;
}

.aboutWrap .ratingStars > div > div {
  position: absolute;
  top: 0;
  overflow: hidden;
}

.aboutWrap .ratingStars > div > div > img {
  width: 16px;
}

@keyframes slide-up {
  0% {
    transform: translate(0%, 100%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes slide-down {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 100%);
  }
}

.about-animation {
  animation: slide-up 250ms forwards !important;
  will-change: transform;
}

.about-close-animation {
  animation: slide-down 300ms forwards !important;
}

@media all and (max-width: 500px) {
  .visible-xs.bubble.white.only-about {
    width: 30%;
  }
}
@media all and (min-width: 500px) and (max-width: 900px) {
  .visible-xs.bubble.white.only-about {
    width: 17%;
  }
}
@media all and (min-width: 900px) and (max-width: 1025px) {
  .visible-xs.bubble.white.only-about {
    width: 12%;
  }
}

.visible-xs.bubble.white.only-about {
  left: calc(50% - 56px);
}

.visible-xs.bubble.white.only-about ul li {
  width: 100%;
  padding: 10px 0px 12px;
}

.visible-xs.bubble.white.only-about ul li i {
  margin-left: 6px;
  top: 1px;
}

.visible-xs.bubble.white.table-booking {
  left: calc(50% - 102px);
}

.visible-xs.bubble.white.loyalty {
  left: calc(50% - 102px);
}

.visible-xs.bubble.white.table-booking ul li {
  width: 50%;
}

.visible-xs.bubble.white.loyalty ul li {
  width: 50%;
}

.visible-xs.bubble.white.table-booking-and-loyalty ul li {
  width: 33%;
}

/*----------------------------------------------------------------------------------*/
/*-------------------------------------SECTION-BOOKING------------------------------*/
/*----------------------------------------------------------------------------------*/

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  .calendar .day.selected:not(.event):after {
    margin-top: -6px !important;
    margin-left: -8px !important;
  }

  /* .calendar ul.days div.week:nth-child(1) span.day.selected:after,
  .calendar ul.days div.week:nth-child(2) span.day.selected:after {
    margin-top: -6px !important;
    margin-left: -5px !important;
  } */

  .calendar ul.days div.week:nth-child(3) span.day.selected:after {
    margin-left: -10px !important;
  }

  .calendar ul.days div.week:nth-child(4) span.day.selected:after,
  .calendar ul.days div.week:nth-child(5) span.day.selected:after {
    margin-left: -12px !important;
  }
}

#calendar-box {
  padding: 0;
  margin: 0;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  color: #484848;
  line-height: 1.2;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#calendar-box div,
#calendar-box p,
#calendar-box a,
#calendar-box span,
#calendar-box em,
#calendar-box strong,
#calendar-box img,
#calendar-box h1,
#calendar-box h2,
#calendar-box h3,
#calendar-box h4,
#calendar-box h5,
#calendar-box h6,
#calendar-box ul,
#calendar-box ol,
#calendar-box li,
#calendar-box dl,
#calendar-box dt,
#calendar-box dd,
#calendar-box table,
#calendar-box td,
#calendar-box tr,
#calendar-box form,
#calendar-box fieldset,
#calendar-box label,
#calendar-box select,
#calendar-box input,
#calendar-box textarea {
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
}
#calendar-box {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#calendar-box:after {
  position: absolute;
  content: '';
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #b5b1b1;
  opacity: 0.25;
}
#calendar-box,
#calendar-box * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#calendar-box .calendar {
  overflow: hidden;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  max-height: 353px;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  -webkit-overflow-scrolling: touch;
}

#calendar-box .week-days-box {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  padding: 0 0.5rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0.628em 0.048em rgba(0, 0, 0, 0.18);
  box-shadow: 0 0 0.628em 0.048em rgba(0, 0, 0, 0.18);
}
#calendar-box .week-days {
  overflow: hidden;
  text-align: center;
  --current-tracking-Roboto: 0.007em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}
#calendar-box .week-days > li {
  display: block;
  float: left;
  width: 14.28%;
  padding: 1.15rem 0;
  font-size: 1.51rem;
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}
#calendar-box .week-days > li span {
  display: block;
  width: 3.55rem;
  margin: 0 auto;
}

#calendar-box .month-list-box {
  overflow-y: auto;
  height: 88%;
  width: 100%;
  padding-bottom: 2rem;
  position: absolute;
  bottom: 0;
}
#calendar-box .month-list {
  margin-top: 1.6rem;
}
#calendar-box .month-name {
  margin-bottom: 0.7rem;
  padding: 0 4vw;
  font-size: 1.7579rem;
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.007rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  text-transform: uppercase;
  text-align: left;
  opacity: 0.5;
}
#calendar-box .current-month .month-name {
  opacity: 1;
}
#calendar-box .days {
  overflow: hidden;
  text-align: left;
  margin-bottom: -0.4rem;
  padding: 0 0.5rem;
}
#calendar-box .days > li {
  position: relative;
  display: block;
  float: left;
  width: 14.28%;
  margin-bottom: 1.55rem;
  font-size: 1.99rem;
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  line-height: 1.1;
  color: #4b4a4a;
  text-align: center;
}
#calendar-box .days > li span {
  overflow: hidden;
  position: relative;
  z-index: 5;
  display: block;
  margin: 0 auto;
  width: 3.6rem;
  height: 3.6rem;
  line-height: 3.6rem;
  text-align: center;
}
#calendar-box .days > li span.day-21 {
  padding-left: 3px;
  --current-tracking-Roboto: 0.06rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}
#calendar-box .days > li.last-month {
  opacity: 0;
}
#calendar-box .days > li.last-day {
  color: #dbdbdb;
}
#calendar-box .days > li.active {
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}
#calendar-box .days > li.active span {
  color: #ffffff;
  background-color: #ff0006;
  border-radius: 20rem;
}
#calendar-box .days > li.event:before {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -0.15rem;
  margin-left: 0.1rem;
  width: 0.6rem;
  height: 0.6rem;
  background-color: #ff0006;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-radius: 20rem;
}
#calendar-box .next-month .days {
  /*margin-top: -2.9rem;*/
  /* margin-top: -3.5rem; */
}
@media all and (min-width: 500px) {
  #calendar-box .month-name {
    padding: 0 5vw;
  }
}
@media all and (min-width: 767px) {
  #calendar-box .month-name {
    padding: 0 6vw;
  }
}

@media all and (min-width: 768px) and (max-width: 4000px) {
  #calendar-box .month-name {
    padding: 0 1.8vw;
  }
}

#rabg {
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0.1;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-calendar {
  display: flex;
  width: 100%;
}
.calendar {
  display: block;
  background: #ffffff;
  width: 300px;
  border: solid 1px #cccccc;
  margin: 10px auto;
  box-shadow: 0 0 15px 0 #c0c0c0;
  font-size: 1.3rem;
  text-align: center;
}

.calendar .day-names {
  color: #2875c7;
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  cursor: default;
  font-size: 1.2rem;
}
.calendar .day-names .day {
  cursor: default;
}
.calendar .day-names .day:hover {
  background: inherit;
}
.calendar .day {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 3.6rem;
  height: 3.6rem;
  font-size: 1.99rem;
  color: #4a4a4a;
  margin-bottom: 1.55rem !important;
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  cursor: pointer;
  transition: all 0.2s;
}
.calendar .day:first-child {
  border-left: none;
}
.calendar .day.today {
  background: #dceaf8;
}

.calendar .day.selected:not(.event) {
  color: #ffffff;
  /* background-color: #ff0006;
  border-radius: 20rem; */
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  position: relative;
}

.calendar .day.selected:not(.event):after {
  content: '';
  width: 3.4rem;
  height: 3.4rem;
  position: absolute;
  background-color: #ff0006;
  z-index: -1;
  margin-left: 34px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-radius: 20rem;
}

.day.past-date {
  color: #dbdbdb;
}

.calendar .day.event:after {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  background-color: #ff0006;
  margin-left: -6px;
  margin-right: 2px;
  margin-top: 3.2rem;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-radius: 20rem;
}

/*----------------------------------------------------------------------------------*/
/*-------------------------------------SECTION-BOOKING------------------------------*/
/*----------------------------------------------------------------------------------*/
#time-select-box {
  padding: 0;
  margin: 0;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  color: #484848;
  line-height: 1.2;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#time-select-box div,
#time-select-box p,
#time-select-box a,
#time-select-box span,
#time-select-box em,
#time-select-box strong,
#time-select-box img,
#time-select-box h1,
#time-select-box h2,
#time-select-box h3,
#time-select-box h4,
#time-select-box h5,
#time-select-box h6,
#time-select-box ul,
#time-select-box ol,
#time-select-box li,
#time-select-box dl,
#time-select-box dt,
#time-select-box dd,
#time-select-box table,
#time-select-box td,
#time-select-box tr,
#time-select-box form,
#time-select-box fieldset,
#time-select-box label,
#time-select-box select,
#time-select-box input,
#time-select-box textarea {
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
}

#time-select-box {
  position: fixed;
  overflow: hidden;
  top: -88px;
  left: 0;
  right: 0;
  bottom: 0;
}
#time-select-box:after {
  position: absolute;
  content: '';
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #b5b1b1;
  opacity: 0.25;
}
#time-select-box,
#time-select-box * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#time-select-box a,
#time-select-box .btn-set-time,
#time-select-box .btn-orange,
#time-select-box .btn {
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
}
#time-select-box .time-box {
  overflow: hidden;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  max-height: 353px;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}

#time-select-box .time-header-box {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0.628em 0.048em rgba(0, 0, 0, 0.18);
  box-shadow: 0 0 0.628em 0.048em rgba(0, 0, 0, 0.18);
}
#time-select-box .time-header-inner {
  display: table;
  width: 100%;
  height: 5.6rem;
  padding: 1.7rem 1.86rem 1.7rem 1.9rem;
}
#time-select-box .time-header-inner .cell {
  display: table-cell;
  padding-right: 0.1rem;
  vertical-align: top;
}
#time-select-box .time-header-inner .change {
  width: 6.6rem;
  padding-left: 0.1rem;
  padding-right: 0;
}
#time-select-box .time-header-inner .text {
  margin-top: 0.2rem;
  font-size: 1.625rem;
  line-height: 1.2;
  font-weight: var(--old-font-normal);
  --current-tracking-Roboto: 0.007rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  text-transform: uppercase;
}
#time-select-box #datetime {
  opacity: 0;
}
#time-select-box .date-time-box {
  position: relative;
  overflow: hidden;
  margin: 5.6rem 0 0;
  height: 24.6rem;
}
#time-select-box .date-time-top,
#time-select-box .date-time-bottom {
  position: absolute;
  z-index: 5;
  right: 0;
  left: 0;
  height: 32px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  pointer-events: none;
}
#time-select-box .date-time-top {
  top: 0;
}
#time-select-box .date-time-bottom {
  bottom: 0;
}
#time-select-box .date-time-top:before,
#time-select-box .date-time-bottom:before {
  position: absolute;
  z-index: 6;
  content: '';
  left: 0;
  width: 100%;
  height: 16px;
  background-color: #ffffff;
}
#time-select-box .date-time-top:before {
  top: 0;
}
#time-select-box .date-time-bottom:before {
  bottom: 0;
}
#time-select-box .mbsc-mobiscroll {
  margin-top: -0.7rem;
}
#time-select-box .mbsc-mobiscroll .mbsc-fr-w {
  background-color: #ffffff;
}

#time-select-box .date-time-box:after {
  top: auto;
  bottom: 0;
}
#time-select-box .date-time-box:after {
}
#time-select-box .date-time-box:before {
}
#time-select-box .mbsc-sc-whl {
}
#time-select-box .mbsc-sc-itm {
  font: var(--old-font-normal) 1.873rem/1 var(--old-font-Roboto);
  color: #444444;
  --current-tracking-Roboto: 0.05rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  word-spacing: 0.15rem;
}
#time-select-box .mbsc-sc-itm.mbsc-sc-itm-sel {
}
#time-select-box .mbsc-w-p .mbsc-sc-whl-l {
}
#time-select-box
  .mbsc-mobiscroll.mbsc-ms-c
  .mbsc-scv-sc
  .mbsc-ms
  .mbsc-ms-item.mbsc-active,
#time-select-box .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
#time-select-box .mbsc-mobiscroll .mbsc-sc-itm:focus {
  background: none;
}
#time-select-box .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-btn:hover:before,
#time-select-box .mbsc-mobiscroll.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
#time-select-box .mbsc-mobiscroll .mbsc-sc-btn.mbsc-active:before,
#time-select-box
  .mbsc-mobiscroll
  .mbsc-segmented
  .mbsc-segmented-item
  input.mbsc-active
  + .mbsc-segmented-content,
#time-select-box .mbsc-mobiscroll .mbsc-btn-flat.mbsc-btn.mbsc-active,
#time-select-box
  .mbsc-mobiscroll.mbsc-lv-cont
  .mbsc-lv-item.mbsc-selected:before,
#time-select-box .mbsc-mobiscroll .mbsc-fr-btn.mbsc-active,
#time-select-box
  .mbsc-mobiscroll.mbsc-no-touch
  .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
  background: none;
}
#time-select-box .mbsc-mobiscroll .mbsc-sc-whl-l {
  height: 46px !important;
  margin-top: -24px !important;
  border-top: 0.1rem solid #dbdbdb;
  border-bottom: 0.1rem solid #dbdbdb;
}
#time-select-box .mbsc-btn-e[tabindex='-1'] {
  color: rgba(68, 68, 68, 0.77);
}

#time-select-box .time-footer-box {
}
#time-select-box a.btn-set-time,
#time-select-box .btn-set-time {
  position: relative;
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2rem;
  font-size: 1.625rem;
  font-weight: var(--old-font-normal);
  --current-tracking-Roboto: 0.014rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  word-spacing: 0.02rem;
  line-height: 1.2;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid var(--main-theme-color);
  background-color: var(--main-theme-color);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#time-select-box a.btn-orange,
#time-select-box .btn-orange {
  position: relative;
  display: inline-block;
  max-width: 100%;
  min-width: 6.538rem;
  margin: 0;
  padding: 0.3rem 0.5rem 0.2rem;
  font-size: 1.131rem;
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.024rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  line-height: 1.4;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  border: 0.1rem solid var(--main-theme-color);
  background-color: var(--main-theme-color);
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  border-radius: 5rem;
}
@media all and (min-width: 769px) {
  #time-select-box a.btn-orange:hover,
  #time-select-box a.btn-orange:focus,
  #time-select-box .btn-orange:hover,
  #time-select-box .btn-orange:focus,
  #time-select-box a.btn-set-time:hover,
  #time-select-box a.btn-set-time:focus,
  #time-select-box .btn-set-time:hover,
  #time-select-box .btn-set-time:focus {
    color: var(--main-theme-color);
    background-color: transparent;
  }
}

@media all and (max-width: 350px) {
  #time-select-box .time-header-inner .text {
    --current-tracking-Roboto: -0.01rem;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    font-size: 1.5rem;
  }
}
@media all and (max-height: 350px) {
  #time-select-box .date-time-box {
    height: 21.3rem;
  }
  #time-select-box .mbsc-mobiscroll {
    margin-top: -2.2rem;
  }
  #time-select-box .date-time-top:before,
  #time-select-box .date-time-bottom:before {
    height: 2px;
  }
  #time-select-box .date-time-top,
  #time-select-box .date-time-bottom {
    height: 15px;
  }
}

.reservationWrap.active.rp1 {
  top: 0;
}

.reservationWrap.rp1 {
  top: 100%;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  padding: 0 20px;
  transition: top 0.3s ease;
}

.reservationWrap.rp1 .detailsTitle {
  padding-top: 64px;
  padding-bottom: 17px;
}

.reservationWrap.rp1 .visible-xs.close-x.thickcon-X {
  padding-top: 25px;
}

.rp1 .typeOfPep {
  font-size: 2.126em;
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.rp1 .typeOfPep > span {
  font-size: 0.706em;
}

.rp1 .pepDesc,
.rp1 .pepInc {
  width: 28px;
  height: 28px;
}

.rp1 .pepInc img,
.rp1 .pepDesc img {
  right: 5px;
  top: 5px;
  width: 16px;
}

.rp1 .resTable {
  padding-bottom: 31px;
}

.rp1 .pepCount {
  font-size: 1.719em;
}

.rp1 .pepCount {
  width: 46px;
}

.rp1 .visible-xs.visible-sm.addressMobileBar {
  margin-bottom: 26px;
}

.rp1 .totalbottom .confirmBtn {
  border-radius: 0;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  font-size: 1.625em;
  padding-top: 16px;
  padding-bottom: 15px;
}

.rp1 .totalbottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0 none;
}

.rp1 .resTable:last-child {
  border-bottom: 0 none;
}

.resTable:last-child {
  border-bottom: 0 none;
}

.rp1 .addB2.pick.true {
  color: red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

.addB2.pick.true {
  color: red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

.rp1 .addB2.pick.true i {
  color: red;
}

.addB2.pick.true i {
  color: red;
}

.rp1 .resTable.true {
  color: red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

.resTable.true {
  color: red;
  animation: shake 0.82s linear both;
  transform: translate3d(0, 0, 0);
}

@media all and (max-width: 320px) {
  .rp1 .selectTime {
    font-size: 1.5em;
  }
}

.waiting-animation {
  z-index: 999999 !important;
  font-size: 16px !important;
}

.waitingWrap.active.rp1 {
  top: 0;
}

.waitingWrap.rp1 {
  top: 100%;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  padding: 0px;
  transition: top 0.3s ease;
}

[class^='icon-'],
[class*=' icon-'] {
}

.icon-calendar-time:before {
  content: '\e900';
  color: #494b4c;
  font-family: 'booking' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-close:before {
  content: '\e901';
  color: #494b4c;
  font-family: 'booking' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-notes:before {
  content: '\e902';
  color: #494b4c;
  font-family: 'booking' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-users:before {
  content: '\e903';
  color: #494b4c;
  font-family: 'booking' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*----------------------------------------------------------------------------------*/
/*-------------------------------------SECTION-BOOKING------------------------------*/
/*----------------------------------------------------------------------------------*/
#section-booking {
  padding: 0;
  margin: 0;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-normal);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  background: #fff;
  color: #4e4d4d;
  line-height: 1.45;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#section-booking div,
#section-booking p,
#section-booking a,
#section-booking span,
#section-booking em,
#section-booking strong,
#section-booking img,
#section-booking h1,
#section-booking h2,
#section-booking h3,
#section-booking h4,
#section-booking h5,
#section-booking h6,
#section-booking ul,
#section-booking ol,
#section-booking li,
#section-booking dl,
#section-booking dt,
#section-booking dd,
#section-booking table,
#section-booking td,
#section-booking tr,
#section-booking form,
#section-booking fieldset,
#section-booking label,
#section-booking select,
#section-booking input,
#section-booking textarea {
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
}
#section-booking :focus {
  outline: none;
}
#section-booking a:link,
#section-booking a:visited {
  font: 1rem var(--old-font-Roboto);
  color: #4e4d4d;
  text-decoration: underline;
}
#section-booking a:hover {
  text-decoration: none;
}

.wrapper {
  width: 100%;
}

#section-booking {
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  height: 100vh;
  min-height: 428px;
  font-size: 16px;
}
#section-booking,
#section-booking * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#section-booking a,
#section-booking .btn {
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
}

#section-booking img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#section-booking .container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.552rem;
}
#section-booking a.btn,
#section-booking .btn {
  position: relative;
  display: inline-block;
  max-width: 100%;
  min-width: 4.72rem;
  margin: 0;
  padding: 0.32rem 0.32rem;
  font-family: var(--old-font-Roboto);
  font-size: 1.1984rem;
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.08rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  color: #6fbd54;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #6fbd54;
  background-color: transparent;
  border-radius: 4px;
}
@media all and (min-width: 769px) {
  #section-booking a.btn:hover,
  #section-booking a.btn:focus,
  #section-booking .btn:hover,
  #section-booking .btn:focus {
    color: #ffffff;
    background-color: #6fbd54;
  }
}

#section-booking .header-box {
  position: relative;
  z-index: 5;
  padding: 1.4rem 0 1.92rem;
  border-bottom: 0.1rem solid #dbdbdb;
  -webkit-box-shadow: 0 0.176rem 0.6144rem 0.1088rem rgba(0, 0, 0, 0.12);
  box-shadow: 0 0.176rem 0.384rem 0.6144rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}
#section-booking .header-box .top-box {
  margin-bottom: 0.88rem;
}
#section-booking .header-box .icon-close {
  display: inline-block;
  font-size: 2.08rem;
  line-height: 1;
}
#section-booking .header-box .title {
  margin-bottom: 0.96rem;
  padding-left: 0.016rem;
  font-size: 2.304rem;
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  line-height: 1.1;
}
#section-booking .header-box .subtitle {
  display: block;
  margin-bottom: 0.96rem;
  padding-left: 0.16rem;
  font-size: 1.4928rem;
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  line-height: 1.1;
  text-transform: uppercase;
  color: #898585;
}
#section-booking .header-box .container > :last-child {
  margin-bottom: 0;
}

#section-booking .map-box {
  position: relative;
  z-index: 1;
  height: 100%;
}
#section-booking .map-box .map {
  position: absolute;
  min-height: 33.536rem;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 280px;
  right: 0;
}
#section-booking .address-box {
  position: relative;
  z-index: 5;
  margin-top: -7.7rem;
  padding: 1.184rem 0.288rem 1.328rem 0.16rem;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #dbdbdb;
  border-left: none;
  border-right: none;
  -webkit-box-shadow: 0 0.176rem 0.6144rem 0.1088rem rgba(0, 0, 0, 0.03);
  box-shadow: 0 0.176rem 0.6144rem 0.1088rem rgba(0, 0, 0, 0.03);
}

#section-booking .address-table {
  display: table;
  width: 100%;
}
#section-booking .address-table .left-box > :last-child,
#section-booking .address-table .right-box > :last-child {
  margin-bottom: 0;
}
#section-booking .address-table .left-box,
#section-booking .address-table .right-box {
  display: table-cell;
  vertical-align: middle;
}
#section-booking .address-table .left-box {
  padding-right: 0.8rem;
}
#section-booking .address-table .right-box {
  padding-left: 0.8rem;
  text-align: right;
}

#section-booking .company-name {
  margin-bottom: 0.224rem;
  font-size: 1.6752rem;
  font-weight: var(--old-font-normal);
  --current-tracking-Roboto: 0.032rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}
#section-booking .address {
  margin-bottom: 0.32rem;
  font-size: 1.6496rem;
  font-weight: var(--old-font-light);
  --current-tracking-Roboto: 0.032rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}
#section-booking .info-box {
  position: relative;
  z-index: 3;
  padding: 2.24rem 0.288rem 2.272rem;
  background-color: #ffffff;
}
#section-booking .info-list {
  text-align: left;
}
#section-booking .info-list > li {
  display: table;
  margin-bottom: 4rem;
  width: 100%;
}
#section-booking .info-list > li:last-child {
  margin-bottom: 0;
}
#section-booking .icon-box,
#section-booking .title-box {
  display: table-cell;
  vertical-align: middle;
}
#section-booking .icon-box {
  width: 3.392rem;
}
#section-booking .icon-box .icon {
  position: relative;
  display: inline-block;
  font-size: 2.288rem;
  line-height: 1;
}
#section-booking .icon-box .icon-calendar-time {
}
#section-booking .icon-box .icon-users {
  top: -0.16rem;
  font-size: 2.32rem;
}
.icon-box .icon-notes {
  top: -0.08rem;
  font-size: 2.432rem;
  padding-left: 0.016rem;
}
#section-booking .icon-box .img-calendar {
  width: 25px;
}
#section-booking .icon-box .img-users {
  width: 25px;
}
#section-booking .icon-box .img-notes {
  width: 25px;
}
#section-booking .title-box {
  padding-left: 0.8rem;
}
#section-booking .title-box > :last-child {
  margin-bottom: 0;
}
#section-booking .title-box p {
  margin-bottom: 0.32rem;
  font-size: 1.7rem;
  font-weight: var(--old-font-light);
  --current-tracking-Roboto: 0.016rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}
#section-booking .content-box {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

/* required styles */

.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  /* min-height: calc(100vh - 77px); */
  min-height: 100%;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* map is broken in FF if you have max-width: 100% on tiles */

.leaflet-container img {
  max-width: none !important;
}

/* stupid Android 2 doesn't understand "max-width: none" properly */

.leaflet-container img.leaflet-image-layer {
  max-width: 15000px !important;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */

.leaflet-overlay-pane svg {
  -moz-user-select: none;
  /* transition: none !important; */
}

.leaflet-tile-pane {
  z-index: 2;
}

.leaflet-objects-pane {
  z-index: 3;
}

.leaflet-overlay-pane {
  z-index: 4;
}

.leaflet-shadow-pane {
  z-index: 5;
}

.leaflet-marker-pane {
  z-index: 6;
}

.leaflet-popup-pane {
  z-index: 7;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 7;
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-clickable {
  cursor: pointer;
}

.leaflet-container {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */

.leaflet-container {
  font: 12px/1.5 var(--old-font-HelveticaNeue);
  --current-tracking-HelveticaNeue: 0em;
  letter-spacing: calc(
    var(--current-tracking-HelveticaNeue) -
      var(--tracking-offset-HelveticaNeue-normal, 0em)
  );
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: var(--old-font-bold) 18px var(--old-font-LucidaConsole);
  text-indent: 1px;
}

.leaflet-control-zoom-out {
  font-size: 20px;
}

.leaflet-touch .leaflet-control-zoom-in {
  font-size: 22px;
}

.leaflet-touch .leaflet-control-zoom-out {
  font-size: 24px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  /* background-image: url(images/layers.png); */
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  /* background-image: url(images/layers-2x.png); */
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  margin: 0 auto;
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px var(--old-font-Tahoma);
  color: #c3c3c3;
  text-decoration: none;
  font-weight: var(--old-font-bold);
  --current-tracking-Tahoma: 0em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) + var(--tracking-offset-Tahoma-bold, 0em)
  );
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)';
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

div#_GMapContainer {
  min-height: 500px;
}

.leaflet-google-layer {
  z-index: 80 !important;
}

.leaflet-map-pane {
  z-index: 100;
}

.leaflet-marker-pane img:not([src="https://assets.nextorder.co/assets/marker.svg"])
{
  transition: transform 2.5s linear;
}

.leaflet-routing-container,
.leaflet-control-attribution {
  display: none;
}

.shadowIt.delivery {
  margin-top: 74px;
}

.scroll-shadow {
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.min-h-auto-container > div {
  min-height: auto;
}

.PaymentRequestButton iframe {
  height: 100% !important;
}

/* ==========================>>><<<============================ */
/* =========================>Transition 1<======================== */
.switch-transition-animation-opacity-enter .switch-transition-opacity {
  transition: opacity 300ms;
  opacity: 0;
}
.switch-transition-animation-opacity-enter-active .switch-transition-opacity {
  opacity: 1;
}
.switch-transition-animation-opacity-exit .switch-transition-opacity {
  opacity: 1;
}
.switch-transition-animation-opacity-exit-active .switch-transition-opacity {
  transition: opacity 300ms;
  opacity: 0;
}

/* =========================>Transition 2<======================== */
.switch-transition-animation-swipe-enter .switch-transition-swipe {
  opacity: 0;
  transform: translateX(-100%);
}
.switch-transition-animation-swipe-enter-active .switch-transition-swipe {
  opacity: 1;
  transform: translateX(0%);
}
.switch-transition-animation-swipe-exit .switch-transition-swipe {
  opacity: 1;
  transform: translateX(0%);
}
.switch-transition-animation-swipe-exit-active .switch-transition-swipe {
  opacity: 0;
  transform: translateX(100%);
}
.switch-transition-animation-swipe-enter-active .switch-transition-swipe,
.switch-transition-animation-swipe-exit-active .switch-transition-swipe {
  transition: opacity 300ms, transform 300ms;
}

.specialViewTopView {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 11px;
  padding-top: 20px;
}
.specialViewTop {
  line-height: 32px;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
  font-weight: var(--old-font-bold);
}
.specialViewTop .voucher {
  margin: 2px 5px;
  font-size: 1.3em;
  font-family: var(--old-font-Roboto);
  font-weight: var(--old-font-medium);
  line-height: normal;
  --current-tracking-Roboto: 0.02em;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  background-color: #fff;
  color: var(--main-theme-color);
  padding: 4px 8px 3px 6px;
  /* border: 1px solid var(--main-theme-color); */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  cursor: pointer;
  z-index: 1;
  /* text-transform: uppercase; */
  transition: all 0.5s ease;
}

.voucherBoxCart {
  transition: all 0.5s ease;
}

.voucherBoxCart {
  transition: all 0.5s ease;
  height: 0;
  overflow: hidden;
  opacity: 0;
  position: relative;
  margin-top: 14px;
}

.voucherBoxCart.active {
  overflow: visible;
  opacity: 1;
  height: 43px;
}

.voucherBoxCart.active ~ .reviewOrderWrap {
  margin-top: 10px;
  height: calc(100% - 257px);
  overscroll-behavior-y: contain;
}

.voucherBoxCart.active > input.voucherInp {
  opacity: 1;
}

.voucherBoxCart span {
  position: absolute;
  display: block;
  right: 8px;
  top: 12px;
  max-width: 18px;
}

.voucherBoxCart span img {
  width: 100%;
}

.voucherBoxCart .voucherImp {
  height: 0px;
}

.voucherBoxCart i.thickcon-X {
  color: #fc3d38;
  font-size: 17px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.voucherBoxCart .voucherInp.match ~ span i.thickcon-X {
  color: #53d769;
}

.voucherBoxCart .voucherInp.nomatch ~ span i.thickcon-X,
.voucherBoxCart .voucherInp.match ~ span i.thickcon-X {
  visibility: visible;
  opacity: 1;
}

.voucherBoxCart .voucherInp.match ~ span i.thickcon-X:before {
  visibility: visible;
  opacity: 1;
  content: '\e917';
}
.voucherApplied {
  display: flex;
  border: 1px dashed #bebfc5;
  padding: 8px 15px;
  color: #686b78;
  align-items: center;
}

.voucherApplied .voucherRemove {
  cursor: pointer;
  margin-left: 8px;
  font-weight: 600;
  color: #282c3f;
  font-size: 14px;
}

.voucherApplied .voucherRemove:hover {
  color: var(--main-theme-color);
}

.voucherDetails {
  flex: 1;
}

.voucherDetails .voucherName {
  font-weight: 500;
  color: #3d4152;
  text-overflow: ellipsis;
}
