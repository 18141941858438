@font-face {
  font-family: 'icomoon';
  src: url('https://storage.googleapis.com/next-order-media/fonts/icomoon.eot')
      format('embedded-opentype'),
    url('https://storage.googleapis.com/next-order-media/fonts/icomoon.ttf')
      format('truetype'),
    url('https://storage.googleapis.com/next-order-media/fonts/icomoon.woff')
      format('woff'),
    url('https://storage.googleapis.com/next-order-media/fonts/icomoon.svg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
[class^='thickcon-'],
[class*=' thickcon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .icon-Star-Location:before {
  content: '\e91d';
} */

.icon-Up-Chevron:before,
.thickcon-Up-Chevron:before {
  content: '\e906';
}

.icon-Left-Chevron:before,
.thickcon-Left-Chevron:before {
  content: '\e909';
}

.icon-Right-Chevron:before,
.thickcon-Right-Chevron:before {
  content: '\e90c';
}

.icon-Checkmark:before,
.thickcon-Checkmark:before {
  content: '\e917';
}

.icon-X2:before,
.thickcon-X:before {
  content: '\e918';
}

.icon-X:before {
  content: '\e919';
}

.icon-Moped:before {
  content: '\e91a';
}

.icon-Delivery:before {
  content: '\e91f';
}

/* .icon-box2-equal:before {
  content: '\e91b';
  color: #4a4c4d;
} */

/* .icon-Car-clock-L2-(1):before {
  content: "\e91c";
   } */

.icon-Scooter:before,
.thickcon-Scooter:before {
  content: '\e900';
}

.icon-Store:before,
.thickcon-Store:before {
  content: '\e901';
}

.icon-Write-Message:before {
  content: '\e902';
}

.icon-Compose-Document:before {
  content: '\e903';
}

.icon-Calendar-Time:before {
  content: '\e904';
}

.icon-Cash:before {
  content: '\e905';
}

.icon-Vehicle:before {
  content: '\e907';
}

.icon-User-Location:before {
  content: '\e908';
}

/* .icon-Star:before {
  content: '\e90a';
} */

.icon-Plus:before,
.thickcon-Plus:before {
  content: '\e90b';
}

/* .icon-Minus:before {
  content: '\e90d';
} */

.icon-Location-Pin:before {
  content: '\e90e';
}

.icon-Location-Arrow:before {
  content: '\e90f';
}

/* .icon-Down-Chevron-Circle:before {
  content: '\e910';
} */

/* .icon-Calendar-Time2:before {
  content: '\e911';
} */

.icon-Home:before {
  content: '\e912';
}

.icon-Clock:before {
  content: '\e913';
}

.icon-Store2:before {
  content: '\e914';
}

.icon-Scooter2:before {
  content: '\e915';
}

.icon-Search:before {
  content: '\e916';
}

.icon-Down-Chevron:before {
  content: '\e91e';
}
