/*------------------------------------*\
    IMPORTS
\*------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=block');

/*------------------------------------*\
  GLOBAL
\*------------------------------------*/
html {
  font-size: 10px;
}

/* html,
body {
  height: 100%;
} */

body {
  font-family: var(--old-font-Roboto);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --current-tracking-Roboto: 0rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
}

.container-temp {
  display: flex;
  height: 100%;
}

/*------------------------------------*\
  SIDEBAR
\*------------------------------------*/
.sidebar {
  position: relative;
  background-color: #fff;
  min-height: 46.6rem;
  width: 100%;
  height: 100%;
}

.sidebar::before {
  content: '';
  position: absolute;
  height: 100%;
  left: -1rem;
  background-image: url('../../assets/images/sidebar-shadow.png');
}

.sidebar__wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 46.6rem;
}

/*------------------------------------*\
  CARD
\*------------------------------------*/
.card__about {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
}

.card__cntrl {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 1.7rem;
  left: 1rem;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
}

.card__cntrl::before {
  content: '';
  position: absolute;
  width: 1.2rem;
  height: 2.1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../../assets/images/l-arrow.svg');
  background-size: cover;
}

.card__map {
  flex-grow: 1;
  /* background-image: url('../../assets/images/map.jpg'); */
  background-size: cover;
  /* min-height: 47vh;  */
}

.card__map img {
  max-width: 100%;
}

.card__name {
  font-size: 2.4rem;
  color: #4e4d4d;
}

.card__category {
  font-size: 1.5rem;
  color: #898585;
  text-transform: uppercase;
}

.card__name,
.card__category {
  font-weight: var(--old-font-bold);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em)
  );
}

.card__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 13.3rem;
  min-height: 13.3rem;
  padding-top: 6rem;
  padding-left: 1.8rem;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.91);
}

.card__header,
.card__info {
  box-shadow: 0 0 1.8rem 0 rgba(0, 0, 0, 0.08);
  line-height: 1.15;
  z-index: 1;
}

.card__info {
  margin-top: auto;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 1.7rem;
  --current-tracking-Roboto: 0.04rem;
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
  );
  color: #4e4d4d;
  background-color: #fff;
  z-index: 2;
  overflow-y: auto;
}

.card__info-item {
  position: relative;
  border-bottom: 0.1rem solid #e9e9e9;
  border-top: 0.1rem solid #fbfbfb;
}

.card__info-item a:hover {
  cursor: pointer;
  color: var(--main-theme-color);
}

.card__info-item div {
  padding: 1.7rem 1.9rem 1.8rem 1.9rem;
}

.card__info-item:first-child {
  border-top: 0.1rem solid #fbfbfb;
}

.card__info-item:last-child {
  border-bottom: 0;
}

.card__info-delete{
  cursor: pointer;
  color: rgba(255, 0, 0, 1);
}
.card__info-delete:hover{
  color: rgba(255, 0, 0, 0.6);
}
.card__call {
  position: absolute;
  top: 50%;
  right: 1.8rem;
  width: 5.6rem;
  line-height: 2.4rem;
  box-shadow: 0 0 0 1.5px #6fbd54;
  color: #6fbd54;
  font-weight: var(--old-font-medium);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
  );
  font-size: 1.5rem;
  border-radius: 0.4rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transform: translateY(-50%);
}

.card__time {
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
}

.card__link {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: #4e4d4d;
  text-decoration: none;
}

.card__link::before {
  content: '';
  position: absolute;
  width: 1.1rem;
  height: 1.9rem;
  top: 50%;
  right: 2.2rem;
  transform: translateY(-50%);
  background-image: url('../../assets/images/r-arrow.svg');
  background-size: cover;
}

.card__rating,
.card__rating span {
  width: 13.8rem;
  height: 2.3rem;
}

.card__link_terms {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: #4e4d4d;
  text-decoration: none;
}

#mapAbout div[tabIndex='0'] {
  transform: translateY(60px);
}

.delivery-areas-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  left: 100%;
  z-index: 3;
  transition: left 0.3s ease;
  padding: 0 18px;
}

.delivery-areas-container ul {
  padding: 0;
  list-style-type: none;
  font-size: 1.725em;
  margin-top: 11px;
  margin-left: -1px;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
}

@supports (-webkit-overflow-scrolling: touch) {
  .delivery-areas-container ul {
    overflow-y: scroll;
  }
}

.delivery-areas-container.true {
  left: 0;
}

.delivery-areas-container ul li span {
  font-weight: var(--old-font-light);
  letter-spacing: calc(
    var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
  );
  float: right;
  text-align: right;
  max-width: 180px;
}

.delivery-areas-container ul li {
  overflow: hidden;
  border-bottom: 1px solid #ebebeb;
  padding: 19px 0;
}

.overflow-y {
  overflow-y: scroll;
}
