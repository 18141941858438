.loyalty-desktop .loyalty-container {
  max-width: 47.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.7rem;
}
/*----------------------------------------------------------------------------------*/
/*-------------------------------------ICOMOON--------------------------------------*/
/*----------------------------------------------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?7cmk4');
  src: url('./fonts/icomoon.eot?7cmk4#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?7cmk4') format('truetype'),
    url('./fonts/icomoon.woff?7cmk4') format('woff'),
    url('./fonts/icomoon.svg?7cmk4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon_loy-'],
[class*=' icon_loy-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_loy-circle_precent:before {
  content: '\e900';
  color: #494b4c;
}
.icon_loy-circle_star:before {
  content: '\e901';
  color: #494b4c;
}
.icon_loy-circle_user:before {
  content: '\e902';
  color: #494b4c;
}

.loyalty-desktop .btn,
.loyalty-desktop a.btn {
  position: relative;
  display: inline-block;
  min-width: 11.7125rem;
  max-width: 100%;
  padding: 0.5rem 1rem;
  font-family: var(--old-font-Roboto);
  font-size: 1.5rem;
  font-weight: var(--old-font-medium);
  line-height: 1;
  --current-tracking-Roboto: 0em;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em));
  text-align: center;
  text-decoration: none;
  color: #fff;
  border: 0.1rem solid var(--main-theme-color);
  background-color: var(--main-theme-color);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  cursor: pointer;
}
/*----------------------------------------------------------------------------------*/
/*-------------------------------------WRAPPER--------------------------------------*/
/*----------------------------------------------------------------------------------*/
.loyalty-desktop .centered-img {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 73.5%;
  background-color: #202020;
}
.loyalty-desktop .centered-img img {
  position: absolute;
  z-index: 3;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: -9999rem;
  left: -9999rem;
  right: -9999rem;
  bottom: -9999rem;
  margin: auto;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
}
.loyalty-desktop .centered-img img.wider {
}
.loyalty-desktop .centered-img img.higher {
  min-width: 100%;
  max-height: initial;
}
.loyalty-desktop #main-content {
  position: relative;
  z-index: 1;
  padding-top: 155px;
}
/*----------------------------------------------------------------------------------*/
/*-------------------------------------HEADER---------------------------------------*/
/*----------------------------------------------------------------------------------*/
.loyalty-desktop #header {
  position: fixed;
  /* top: 96px; */
  z-index: 9999;
  width: 100%;
  padding: 1.5rem 0 1.5rem;
  min-height: 15.5rem;
  color: #fff;
}
.loyalty-desktop #header.red {
  background-color: #e40000;
}
.loyalty-desktop #header .loyalty-container {
  position: relative;
}
.loyalty-desktop #header .top-box {
  padding-bottom: 0.2rem;
}
.loyalty-desktop #header .bottom-box {
}
.loyalty-desktop .header-title {
  margin-bottom: 0.9rem;
  font-size: 2.99rem;
  font-weight: var(--old-font-bold);
  --current-tracking-Roboto: 0.06rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em));
  cursor: default;
}
.loyalty-desktop .header-title span {
  padding-left: 0.7rem;
  font-size: 1.875rem;
  --current-tracking-Roboto: 0.03rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em));
}
.loyalty-desktop #header .top-nav {
  padding-left: 5rem;
  margin-right: -0.2rem;
  text-align: right;
}
.loyalty-desktop #header .top-nav a {
  padding: 0.4rem 0 0.4rem 0.4rem;
  color: #ffffff;
  font-size: 1.514rem;
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.04rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em));
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.loyalty-desktop #header .top-nav a span[class^='icon_loy'] {
  position: relative;
  margin-right: 0.5rem;
  font-size: 1.325em;
  top: 0.3rem;
}
.loyalty-desktop #header .top-nav a span[class^='icon_loy']:before {
  color: #ffffff;
}
.loyalty-desktop #header.gold {
  color: #fff;
  background-color: var(--main-theme-color);
}
.loyalty-desktop #header.gold .top-nav a span[class^='icon_loy']:before,
.loyalty-desktop #header.gold .top-nav a {
  color: #fff;
}
/*----------------------------------------------------------------------------------*/
/*--------------------------------------NAV-----------------------------------------*/
/*----------------------------------------------------------------------------------*/
.loyalty-desktop .mobile-nav {
  display: block;
  /*margin-left: -0.8rem;*/
}
.loyalty-desktop .mobile-nav > ul {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
}
.loyalty-desktop .mobile-nav > ul > li {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.loyalty-desktop .mobile-nav > ul > li:not(:last-child) {
  margin-right: 0.4rem;
}
.loyalty-desktop .mobile-nav > ul > :last-child {
}
.loyalty-desktop .mobile-nav > ul > li a {
  position: relative;
  display: block;
  padding: 0.5575rem 1.2rem 0.5575rem 3.9rem;
  color: #fff;
  font-size: 1.399rem;
  --current-tracking-Roboto: 0.05rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em));
  line-height: 1.6em;
  font-weight: var(--old-font-medium);
  text-transform: uppercase;
  text-decoration: none;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
}
.loyalty-desktop .mobile-nav > ul > li a span[class^='icon_loy'] {
  position: absolute;
  top: 50%;
  left: 2rem;
  font-size: 1.45em;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loyalty-desktop .mobile-nav > ul > li a span[class^='icon_loy']:before {
  position: relative;
  z-index: 10;
  color: #ffffff;
}
.loyalty-desktop .mobile-nav > ul > li a span[class^='icon_loy']:after {
  position: absolute;
  content: '';
  z-index: 5;
  top: 50%;
  left: 50%;
  width: 0.7em;
  height: 0.7em;
  background-color: #e40000;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
}
.loyalty-desktop .mobile-nav > ul > li a span[class^='icon_loy'] {
}
.loyalty-desktop .mobile-nav > ul > li a span[class^='icon_loy'] {
}

.loyalty-desktop .mobile-nav > ul > li a.active {
  color: #e40000;
  background-color: #ffffff;
}
.loyalty-desktop .mobile-nav > ul > li a.active span[class^='icon_loy']:before {
  color: #e40000;
}
.loyalty-desktop .mobile-nav > ul > li a.active span[class^='icon_loy']:after {
  background-color: #ffffff;
}
.loyalty-desktop .mobile-nav > ul > li a.active span[class^='icon_loy'] {
}
.loyalty-desktop .mobile-nav > ul > li a.active span[class^='icon_loy'] {
}

.loyalty-desktop .gold .mobile-nav {
  margin-top: 1.4rem;
}
.loyalty-desktop .gold .mobile-nav > ul > li a span[class^='icon_loy']:before,
.loyalty-desktop .gold .mobile-nav > ul > li a {
  color: #fff;
}
.loyalty-desktop
  .gold
  .mobile-nav
  > ul
  > li
  a.active
  span[class^='icon_loy']:before,
.loyalty-desktop .gold .mobile-nav > ul > li a.active {
  color: #000;
}

.loyalty-desktop
  .gold
  .mobile-nav
  > ul
  > li
  a.active
  span[class^='icon_loy']:after {
  background-color: #ffffff;
}
.loyalty-desktop .gold .mobile-nav > ul > li a span[class^='icon_loy']:after {
  background-color: var(--main-theme-color);
}

.loyalty-desktop .mobile-menu-toggle {
}
.loyalty-desktop .mobile-menu-wrap {
  display: inline-block;
  max-width: calc(100% - 15.4rem);
  text-align: right;
  width: 100%;
  vertical-align: top;
}

.loyalty-desktop .mobile-menu-toggle {
  position: absolute;
  z-index: 9999;
  left: 0.6rem;
  top: -0.9rem;
  height: 4rem;
  width: 4rem;
  vertical-align: middle;
  cursor: pointer;
  font-size: 2.6rem;
  /* background-color: burlywood; */
}
.loyalty-desktop .mobile-menu-toggle span:before,
.loyalty-desktop .mobile-menu-toggle span:after,
.loyalty-desktop .mobile-menu-toggle span {
  position: absolute;
  display: block;
  content: '';
  cursor: pointer;
  height: 0.3rem;
  width: 2.2rem;
  background-color: #ffffff;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.loyalty-desktop .mobile-menu-toggle span {
  z-index: 999999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loyalty-desktop .mobile-menu-toggle span:before {
  top: -0.8rem;
}
.loyalty-desktop .mobile-menu-toggle span:after {
  bottom: -0.8rem;
}
.loyalty-desktop .gold .mobile-menu-toggle.active span,
.loyalty-desktop .mobile-menu-toggle.active span {
  background-color: transparent;
}
.loyalty-desktop .mobile-menu-toggle.active span:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0;
}
.loyalty-desktop .mobile-menu-toggle.active span:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: 0;
}
.loyalty-desktop .gold .mobile-menu-toggle span:before,
.loyalty-desktop .gold .mobile-menu-toggle span:after,
.loyalty-desktop .gold .mobile-menu-toggle span {
  background-color: #292425;
}
/*----------------------------------------------------------------------------------*/
/*-------------------------------------CONTENT--------------------------------------*/
/*----------------------------------------------------------------------------------*/
.loyalty-desktop .section-discount-cards {
  /* padding-bottom: 2.7rem; */
}
.loyalty-desktop .section-discount-cards .loyalty-container {
}
.loyalty-desktop .discount-card-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 2.7rem;
}
.loyalty-desktop .discount-card-list > li:last-child {
  margin-bottom: 2rem;
}
.loyalty-desktop .discount-card-list > li:not(:last-child) {
  margin-bottom: 2.3rem;
}
.loyalty-desktop .discount-card-box {
  display: table;
  height: 23.9rem;
  min-height: 60%;
  width: 100%;
  padding: 1.3rem 1.9rem 1.6rem 1.6rem;
  color: #ffffff;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  border-radius: 0.75rem;
  -webkit-box-shadow: 0 0 0.34rem 0.02rem rgba(0, 0, 0, 0.22);
  box-shadow: 0 0 0.34rem 0.02rem rgba(0, 0, 0, 0.22);
  background-image: -moz-linear-gradient(
    -137deg,
    rgb(138, 116, 98) 0%,
    rgb(181, 156, 132) 52%,
    rgb(138, 116, 98) 100%
  );
  background-image: -webkit-linear-gradient(
    -137deg,
    rgb(138, 116, 98) 0%,
    rgb(181, 156, 132) 52%,
    rgb(138, 116, 98) 100%
  );
  background-image: -ms-linear-gradient(
    -137deg,
    rgb(138, 116, 98) 0%,
    rgb(181, 156, 132) 52%,
    rgb(138, 116, 98) 100%
  );
}
.loyalty-desktop .discount-card-box.bronze {
  background-image: -moz-linear-gradient(
    -137deg,
    rgb(138, 116, 98) 0%,
    rgb(181, 156, 132) 52%,
    rgb(138, 116, 98) 100%
  );
  background-image: -webkit-linear-gradient(
    -137deg,
    rgb(138, 116, 98) 0%,
    rgb(181, 156, 132) 52%,
    rgb(138, 116, 98) 100%
  );
  background-image: -ms-linear-gradient(
    -137deg,
    rgb(138, 116, 98) 0%,
    rgb(181, 156, 132) 52%,
    rgb(138, 116, 98) 100%
  );
}
.loyalty-desktop .discount-card-box.gold {
  background-image: -moz-linear-gradient(
    -137deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 52%,
    rgb(158, 142, 92) 100%
  );
  background-image: -webkit-linear-gradient(
    -137deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 52%,
    rgb(158, 142, 92) 100%
  );
  background-image: -ms-linear-gradient(
    -137deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 52%,
    rgb(158, 142, 92) 100%
  );
}
.loyalty-desktop .discount-card-box {
  background-image: -moz-linear-gradient(
    -137deg,
    rgb(152, 155, 156) 0%,
    rgb(184, 186, 187) 47%,
    rgb(152, 155, 156) 100%
  );
  background-image: -webkit-linear-gradient(
    -137deg,
    rgb(152, 155, 156) 0%,
    rgb(184, 186, 187) 47%,
    rgb(152, 155, 156) 100%
  );
  background-image: -ms-linear-gradient(
    -137deg,
    rgb(152, 155, 156) 0%,
    rgb(184, 186, 187) 47%,
    rgb(152, 155, 156) 100%
  );
}
.loyalty-desktop .discount-card-box .top-box {
  display: table-header-group;
  overflow: hidden;
  width: 100%;
}
.loyalty-desktop .discount-card-box .bottom-box {
  overflow: hidden;
  display: table-cell;
  width: 100%;
  vertical-align: bottom;
  padding: 1rem 0 0;
}
.loyalty-desktop .discount-card-box .card-logo-box {
  max-width: 14.8rem;
  float: left;
}
.loyalty-desktop .discount-card-box .card-logo-box img {
  max-width: 100%;
  width: auto;
  height: auto;
}
.loyalty-desktop .discount-card-box .privileges {
  text-transform: uppercase;
  float: right;
  margin-top: 0.2rem;
  font-size: 1.38rem;
  --current-tracking-Roboto: 0.07rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em));
  max-width: 12rem;
  text-align: right;
  line-height: 1.7;
}

.loyalty-desktop .discount-card-box .inner-table {
  display: table;
  width: 100%;
}
.loyalty-desktop .discount-card-box .inner-table .column {
  display: table-cell;
  vertical-align: bottom;
}
.loyalty-desktop .discount-card-box .inner-table .column.col-name {
  text-align: right;
}
.loyalty-desktop .discount-card-box .card-info {
  text-align: left;
  padding-left: 0.1rem;
}
.loyalty-desktop .discount-card-box .card-info span {
  display: block;
}
.loyalty-desktop .discount-card-box .member-name {
  font-size: 1.88rem;
  margin-bottom: 0.4rem;
  --current-tracking-Roboto: 0.09rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em));
}
.loyalty-desktop .discount-card-box .member-status {
  font-size: 1.38rem;
  --current-tracking-Roboto: 0.07rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em));
}
.loyalty-desktop .discount-card-box .card-name {
  display: inline-block;
  font-size: 2.25rem;
  font-weight: var(--old-font-medium);
  --current-tracking-Roboto: 0.1rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em));
}
.loyalty-desktop .discount-card-box + .next-card-level {
  margin-top: 1.3rem;
}
.loyalty-desktop .next-card-level {
  margin-bottom: -0.5rem;
}
.loyalty-desktop .next-card-level .text {
  padding: 0 0.2rem;
  font-size: 1.47rem;
  --current-tracking-Roboto: 0.05rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em));
  word-spacing: -0.03rem;
  color: #292425;
}
.loyalty-desktop .next-card-level .text span {
  font-weight: var(--old-font-medium);
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em));
}
.loyalty-desktop .card-progress {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0.94rem;
  margin-bottom: 0.7rem;
  background-image: -moz-linear-gradient(
    97deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 100%
  );
  background-image: -webkit-linear-gradient(
    97deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 100%
  );
  background-image: -ms-linear-gradient(
    97deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 100%
  );
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
}
.loyalty-desktop .card-progress .progress-line {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-image: -moz-linear-gradient(
    4deg,
    rgb(152, 155, 156) 0%,
    rgb(184, 186, 187) 100%
  );
  background-image: -webkit-linear-gradient(
    4deg,
    rgb(152, 155, 156) 0%,
    rgb(184, 186, 187) 100%
  );
  background-image: -ms-linear-gradient(
    4deg,
    rgb(152, 155, 156) 0%,
    rgb(184, 186, 187) 100%
  );
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.loyalty-desktop .card-progress .progress-line.end {
  z-index: 2;
  left: auto;
  right: 0;
  background-image: -moz-linear-gradient(
    97deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 100%
  );
  background-image: -webkit-linear-gradient(
    97deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 100%
  );
  background-image: -ms-linear-gradient(
    97deg,
    rgb(158, 142, 92) 0%,
    rgb(190, 180, 116) 100%
  );
}
.loyalty-desktop .section-discount-cards {
}
.loyalty-desktop .section-discount-cards .loyalty-container {
}
.loyalty-desktop .offer-card-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 2.7rem;
}
.loyalty-desktop .offer-card-list > li:last-child {
  padding-bottom: 2rem;
}
.loyalty-desktop .offer-card-list > li:not(:last-child) {
  margin-bottom: 1.8rem;
}
.loyalty-desktop .offer-card-box {
  overflow: hidden;
  position: relative;
  color: #ffffff;
  background-color: #4f6370;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-border-radius: 0.9rem;
  -moz-border-radius: 0.9rem;
  border-radius: 0.9rem;
}
.loyalty-desktop .offer-card-box:after {
  position: absolute;
  content: '';
  z-index: 5;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.15) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.15) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.15) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}
.loyalty-desktop .offer-card-box + .next-card-level {
  margin-top: 1.3rem;
  margin-bottom: 0;
}
.loyalty-desktop .offer-card-box .inner-table {
  position: relative;
  display: table;
  z-index: 10;
  width: 100%;
  height: 24.2rem;
  padding: 0.7rem 1.4rem;
}
.loyalty-desktop .offer-card-box .inner-table .column {
  display: table-cell;
  vertical-align: bottom;
}
.loyalty-desktop .offer-title {
  display: block;
  max-width: 27rem;
  font-size: 2.237rem;
  line-height: 1.3;
  --current-tracking-Roboto: 0.03rem;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-bold, 0em));
  text-transform: uppercase;
  cursor: default;
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: var(--old-font-bold);
}
.loyalty-desktop .offer-card-list .card-progress {
  margin-bottom: 0.9rem;
  background: rgb(149, 149, 149);
}
.loyalty-desktop .offer-card-list .card-progress .progress-line {
  background: var(--main-theme-color);
}
.loyalty-desktop .card-nav-box {
  margin-top: 1.4rem;
}
.loyalty-desktop .card-nav-table {
  display: table;
  width: 100%;
}
.loyalty-desktop .card-nav-table > :last-child:not(:first-child) {
  text-align: right;
}
.loyalty-desktop .card-nav-table .inner-box {
  display: table-cell;
  vertical-align: middle;
}
.loyalty-desktop .card-nav-box .text {
  font-size: 1.625rem;
  font-weight: var(--old-font-medium);
  color: #4b4a4a;
  --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em));
  margin: 0;
  cursor: default;
}

.loyalty-desktop .loyalty-container-overflow {
  overflow: auto;
  height: calc(100vh - 251px);
}

.loyalty-desktop .card__cntrl__white {
  position: absolute;
  top: -0.3rem;
  width: 3rem;
  height: 3rem;
  left: 1rem;
}

.loyalty-desktop .card__cntrl__white::before {
  content: '';
  position: absolute;
  width: 1.2rem;
  height: 2.1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../../assets/images/l-arrow-white.svg');
  background-size: cover;
}

/*----------------------------------------------------------------------------------*/
/*-------------------------------------SIDEBAR--------------------------------------*/
/*----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------*/
/*-------------------------------------FOOTER---------------------------------------*/
/*----------------------------------------------------------------------------------*/
