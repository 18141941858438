/*-- Item photo view media queries --*/

/* html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
} */

/* @media screen and (max-width: 767px) {
  _::-webkit-full-page-media, _:future, :root .safari_only {
      padding-bottom: 65px;
  }
} */

body {
  padding: 0;
}
/*
@media all and (max-height: 768px) {
  .imgView img {
    top: -115%;
    width: 105%;
  }
}

@media all and (max-width: 1175px) and (max-height: 768px) {
  .imgView img {
    top: -120%;
    width: 120%;
  }
}

@media all and (max-width: 1525px) and (min-width: 1350px) and (min-height: 769px) {
  .imgView img {
    width: 115%;
  }
}

@media all and (max-width: 1349px) and (min-width: 1180px) and (min-height: 769px) {
  .imgView img {
    width: 130%;
  }
}

@media all and (max-width: 1179px) and (min-width: 1030px) and (min-height: 769px) {
  .imgView img {
    width: 150%;
  }
}

@media all and (max-width: 1029px) and (min-width: 992px) and (min-height: 769px) {
  .imgView img {
    width: 157%;
  }
}

@media all and (max-width: 1675px) and (min-width: 1350px) and (min-height: 825px) {
  .imgView img {
    width: 125%;
  }
}

@media all and (max-width: 1349px) and (min-width: 1195px) and (min-height: 825px) {
  .imgView img {
    width: 140%;
  }
}

@media all and (max-width: 1194px) and (min-width: 1060px) and (min-height: 825px) {
  .imgView img {
    width: 160%;
  }
}

@media all and (max-width: 1059px) and (min-width: 992px) and (min-height: 825px) {
  .imgView img {
    width: 170%;
  }
}

@media all and (max-width: 1750px) and (min-width: 1425px) and (min-height: 900px) {
  .imgView img {
    width: 130%;
  }
}

@media all and (max-width: 1425px) and (min-width: 1135px) and (min-height: 900px) {
  .imgView img {
    width: 155%;
  }
}

@media all and (max-width: 1134px) and (min-width: 992px) and (min-height: 900px) {
  .imgView img {
    width: 175%;
  }
}*/

/*-- Item photo view media queries --*/

/*---boxes in a row as per screen resolution --*/

@media all and (min-width: 1800px) {
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    width: 14.28%;
    /* max-width: 10.528%; */
    /* max-width: 198px; */
  }
}

@media all and (min-width: 1550px) and (max-width: 1799px) {
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    width: 16.66%;
  }
}

@media all and (min-width: 1100px) and (max-width: 1300px) {
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    width: 25%;
  }
}

@media all and (min-width: 992px) and (max-width: 1099px) {
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    width: 33.33%;
  }
}

/*---boxes in a row as per screen resolution --*/

@media all and (min-width: 1500px) {
  /* .deliveryWrap .pickupboxInnerRight{width:70%}
    .deliveryWrap .pickupboxInnerLeft{width:30%} */
  .specialsSlide .itemBoxes,
  .specialsSlide .itemBoxes.boxWrapper {
    width: 25%;
  }
  .col-md-4.customBar {
    width: 495px;
  }
  .col-md-8.customWidth {
    width: calc(100% - 497px);
    z-index: 30;
    background-color: white;
  }
  .col-md-8.customWidth.mapStage {
    width: calc(100% - 497px);
  }
}

@media all and (min-width: 1700px) {
  .specialsSlide .itemBoxes,
  .specialsSlide .itemBoxes.boxWrapper {
    width: 20%;
    /* max-width: 275px; */
  }
}

/* @media all and (min-width:1600px)  {
    .deliveryWrap .pickupboxInnerRight{width:72%}
    .deliveryWrap .pickupboxInnerLeft{width:28%}
}

@media all and (min-width:1700px)  {
    .deliveryWrap .pickupboxInnerRight{width:75%}
    .deliveryWrap .pickupboxInnerLeft{width:25%}
} */

@media all and (min-width: 992px) and (max-width: 1360px) {
  /* .deliveryWrap .pickupboxInnerRight{width:calc(100% - 123px)}
    .deliveryWrap .pickupboxInnerLeft{width:123px} */
}

@media all and (max-width: 1300px) {
  span.iconWala {
    margin-left: 10px;
  }
  /* .clickBtn{margin-right:5px;} */
  .col-md-4.customBar {
    width: 416px;
  }
  .col-md-8.customWidth {
    width: calc(100% - 419px);
    z-index: 30;
    background-color: white;
  }
}

@media all and (max-width: 1250px) {
  .specialsSlide .itemBoxes,
  .specialsSlide .itemBoxes.boxWrapper {
    /* width: 50% !important;
    max-width: none; */
  }
}
@media all and (max-width: 1023px) {
  .spBtn {
    padding-top: 10px;
  }
}

@media all and (min-width: 992px) and (max-width: 1023px) {
  .glutenFree {
    font-size: 1.5em;
  }
}
@media all and (min-width: 992px) and (max-height: 739px) {
  .halfDiv {
    margin-top: 0;
  }
}

body:not(.iPadPro) .col-md-4.customBar {
  /*transform: translateX(1px) !important;*/
}
@media all and (min-width: 992px) {
  /* .pickupboxInnerRight {
        position: relative;
    } */
  /* .map-items {
        min-height: calc(100vh - 73px) !important;
    } */
  .halfDivs {
    overflow: hidden;
  }
  .halfDiv {
    width: 48%;
    max-height: 337px;
    height: 100%;
  }
  .halfDiv > div > img {
    height: 100%;
    width: auto;
    display: block;
  }
  .halfDiv > div {
    width: 100%;
    float: left;
    height: 100%;
  }
  tr.cartRow.oneword td.cItemName,
  tr.cartRow.vtop td.cItemName {
    padding-top: 10px;
  }
  .deliveryCost {
    top: 12px;
    right: 132px;
    font-size: 1em;
  }

  .deliveryCost ~ .geosuggest.deliverySearchBox {
    padding-right: 42px;
  }
  .orderViews.specialView {
    z-index: 100;
    /* transition-delay: 0.3s; */
  }

  .spItemD.orderViews {
    z-index: 101;
  }

  .orderViews.halfView {
    z-index: 102;
    transition-delay: 0.2s;
  }

  .orderViews.halfD {
    z-index: 103;
  }

  .active:not(.stWrap) ~ .orderViews.specialView,
  .active:not(.stWrap) ~ .orderViews.halfView {
    transform: translateX(-20px);
  }

  .detailsConfirm.detailFinal.active:not(.voucher) .reviewOrderWrap,
  .detailsConfirm.detailFinal.active:not(.specialsA) .reviewOrderWrap {
    height: calc(100% - 150px);
  }

  .detailsConfirm.detailFinal.active:not(.voucher)
    .voucherBox.active
    ~ .reviewOrderWrap,
  .detailsConfirm.detailFinal.active:not(.specialsA)
    .voucherBox.active
    ~ .reviewOrderWrap {
    height: calc(100% - 224px);
  }

  .detailsConfirm.detailFinal.active:not(.voucher)
    .codeSuccess.match
    ~ .reviewOrderWrap.match,
  .detailsConfirm.detailFinal.active:not(.specialsA)
    .codeSuccess.match
    ~ .reviewOrderWrap.match {
    height: calc(100% - 292px);
  }

  .detailsConfirm.detailFinal.active.voucher .reviewOrderWrap,
  .detailsConfirm.detailFinal.active.specialsA .reviewOrderWrap {
    height: calc(100% - 183px);
  }

  .detailsConfirm.detailFinal.active.voucher
    .voucherBox.active
    ~ .reviewOrderWrap,
  .detailsConfirm.detailFinal.active.specialsA
    .voucherBox.active
    ~ .reviewOrderWrap {
    height: calc(100% - 257px);
  }

  .detailsConfirm.detailFinal.active.voucher
    .codeSuccess.match
    ~ .reviewOrderWrap.match,
  .detailsConfirm.detailFinal.active.specialsA
    .codeSuccess.match
    ~ .reviewOrderWrap.match {
    height: calc(100% - 325px);
  }

  .detailsConfirm.detailFinal.active.specialsA.voucher .reviewOrderWrap {
    height: calc(100% - 216px);
  }

  .detailsConfirm.detailFinal.active.specialsA.voucher
    .voucherBox.active
    ~ .reviewOrderWrap {
    height: calc(100% - 290px);
  }

  .detailsConfirm.detailFinal.active.specialsA.voucher
    .codeSuccess.match
    ~ .reviewOrderWrap.match {
    height: calc(100% - 358px);
  }
}
@media all and (min-width: 1366px) {
  body.iPadPro .col-md-4.customBar {
    /*transform: translateX(1px) !important;*/
  }
}
@media all and (min-width: 1024px) and (max-width: 1024px) and (max-height: 768px) {
  body.iPadPro .col-md-4.customBar {
    /*transform: translateX(1px) !important;*/
  }
}
@media all and (min-width: 1024px) and (max-width: 1024px) and (min-height: 1250px) {
  body.iPadPro .col-md-4.customBar {
    top: 0% !important;
    left: auto;
    width: 100%;
    z-index: 9999;
    padding-top: 0;
    box-shadow: none;
    transform: translateX(100%);
    transition: transform 0.3s linear;
  }
  body.iPadPro .container-fluid.shadowIt {
    height: auto !important;
    overflow-x: hidden;
  }
  body.iPadPro .rp1 .visible-xs.visible-sm.addressMobileBar {
    display: block !important;
  }
  body.iPadPro .shadowIt {
    /* box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1); */
  }
  body.iPadPro .container-fluid > .navbar-header {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    text-align: center;
  }
  body.iPadPro .itemDet.active {
    bottom: 0;
    /* min-height: 330px; */
  }
  body.iPadPro .logoWrap {
    margin-left: auto;
    margin-right: auto;
  }
  body.iPadPro ul.nav.navbar-nav.pull-right {
    display: none;
  }
  body.iPadPro .col-md-8.customWidth {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  body.iPadPro .itemBoxes,
  body.iPadPro .itemBoxes.boxWrapper {
    width: 25%;
  }
  body.iPadPro .specialsSlide .itemBoxes,
  body.iPadPro .specialsSlide .itemBoxes.boxWrapper {
    width: 50%;
  }
  body.iPadPro .visible-xs.bubble.white {
    left: calc(50% - 102px);
    display: block !important;
  }
  body.iPadPro .visible-xs.bubble {
    display: block !important;
  }
  body.iPadPro #sticky div.stWrap {
    left: -100%;
    z-index: 999;
    opacity: 0;
    transform: translateY(100%);
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    width: 100%;
    transition: left 0.2s linear, transform 0.2s linear, opacity 0.2s linear;
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }
  body.iPadPro #sticky div.stWrap.active {
    transform: translateY(0);
    opacity: 1;
  }
  body.iPadPro .visible-xs.close-x.thickcon-X {
    position: absolute;
    font-size: 2em;
    top: 0;
    padding-top: 26px;
    padding-left: 20px;
    left: -2px;
    padding-right: 25px;
    padding-bottom: 20px;
    cursor: pointer;
    display: block !important;
  }
  body.iPadPro ul.fright span.swipeBar.hidden-xs {
    display: none;
  }
  body.iPadPro .fright li:nth-child(2) {
    padding-right: 15px;
    margin-right: -15px;
  }
  body.iPadPro .fright li {
    padding-bottom: 10px;
    padding-top: 5px;
    margin-bottom: 0;
    cursor: pointer;
  }
  body.iPadPro .fright li:first-child {
    margin-right: 15px;
    padding-left: 25px;
  }
  body.iPadPro ul.fright {
    margin-top: 6px;
  }
  body.iPadPro .pickupbox {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-bottom: 15px;
  }
  body.iPadPro .deliveryWrap .pickupboxInnerRight {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
  body.iPadPro .pickupboxInnerLeft.hidden-xs.hidden-sm {
    display: none;
  }
  body.iPadPro .pickRestName.robMedium {
    padding-top: 11px;
    padding-bottom: 10px;
  }
  body.iPadPro .pickImg span {
    font-size: 19px;
    padding-right: 10px;
    padding-left: 4px;
  }
  body.iPadPro .appleDevice .deliverySearchBox input {
    padding-top: 16px;
    padding-bottom: 15px;
  }
  body.iPadPro .deliverySearchBox input {
    font-size: 1.363em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding-top: 15px;
    padding-bottom: 14px;
    width: 100%;
    padding-right: 65px;
  }
  body.iPadPro .autoSuggestionWrap {
    right: 0;
    /* box-shadow: none; */
    border: 0 none;
    padding-top: 0;
    border-radius: 0;
    padding: 0 15px;
  }
  body.iPadPro .geosuggest__suggests-wrapper ul.autoSuggestionWrap {
    position: absolute;
    width: calc(100% + 2px);
    background-color: #fff;
    height: 100vh;
    left: -1px;
    z-index: 999;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
    top: calc(100% - 1px);
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #dbdbdb;
  }
  body.iPadPro ul.autoSuggestionWrap li {
    font-size: 1.363em;
    line-height: normal;
    padding-left: 31px;
    border-top: 1px solid #f6f6f6;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-bottom: 0;
  }
  body.iPadPro .pickupboxInnerRight ul.autoSuggestionWrap li:before {
    font-size: 22px;
    top: 12px;
  }
  body.iPadPro ul.autoSuggestionWrap li:last-child {
    border-bottom: 1px solid #f6f6f6;
  }
  body.iPadPro .pickOrOrderWrap,
  body.iPadPro .fixHeightIssue.delcost .pickOrOrderWrap {
    padding-top: 0;
    height: calc(100% - 208px);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
  }
  body.iPadPro .sidefixe1 {
    overflow: hidden;
  }
  body.iPadPro table.cartTable {
    display: block;
    width: 100%;
  }
  body.iPadPro table.cartTable > tbody {
    width: 100%;
    display: block;
  }
  body.iPadPro tr.cartRow {
    display: block;
    width: 100%;
    position: relative;
  }
  body.iPadPro table tr.cartRow > td.cItemImg {
    height: 215px;
  }
  body.iPadPro table tr.cartRow > td.cItemImg {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      color-stop(40%, rgba(0, 0, 0, 0)),
      color-stop(99%, rgba(0, 0, 0, 0.5)),
      to(rgba(0, 0, 0, 0.5))
    );
    background: -o-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
  }
  body.iPadPro .cItemImg img {
    top: -100%;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: -100%;
    margin: auto;
    z-index: -1;
  }
  body.iPadPro tr.cartRow.halfHalfOK > td.cItemName,
  body.iPadPro table tr.cartRow > td.cItemName.bigName,
  body.iPadPro table tr.cartRow > td.cItemName {
    top: 173px;
  }
  body.iPadPro table tr.cartRow > td.cItemName {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    color: #fff;
    font-size: 2.25em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding: 0 77px 0 13px;
  }
  body.iPadPro span.cItemPrice {
    display: inline-block;
    margin-top: 0;
    padding: 2px 6px 1px 6px;
    font-size: 0.689em;
    border-radius: 3px;
    background-color: #fff;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
    position: absolute;
    right: 13px;
    bottom: 3px;
  }
  body.iPadPro tr.cartRow > td.cItemQuantity {
    width: 100%;
    display: block;
    padding-bottom: 20px;
    padding-left: 13px;
    padding-right: 13px;
  }
  body.iPadPro .cItemQuantity .cMinusPlusBtn > svg,
  body.iPadPro .cItemQuantity .cMinusPlusBtn > img {
    top: 5px;
    right: 5px;
  }
  body.iPadPro span.cartCount {
    bottom: -3px;
  }
  body.iPadPro .cItemQuantity span.cartCount {
    font-size: 1.625em;
    width: 42px;
  }
  body.iPadPro .cMinusPlusBtn {
    width: 27px;
    height: 27px;
    border-radius: 15px;
  }
  body.iPadPro .cItemMoreInfo > div.cItemCategory {
    width: calc(100% - 96px);
  }
  body.iPadPro .cItemMoreInfo {
    margin-top: 13px;
  }
  body.iPadPro tr.cartRow > td.cItemQuantity {
    width: 100%;
    display: block;
    padding-bottom: 20px;
    padding-left: 13px;
    padding-right: 13px;
  }
  body.iPadPro .cItemCategory {
    text-align: left;
    font-size: 1.875em;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  body.iPadPro tr.cartRow.halfHalfOK .cItemImg img {
    top: -65px;
    width: 50%;
    float: left;
    position: relative;
    bottom: auto;
  }
  body.iPadPro .visible-xs.discountBar {
    display: flex !important;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    display: block !important;
  }
  body.iPadPro .visible-xs.discountBar.hideSpec > div.specialDisc {
    display: none;
  }
  body.iPadPro .discountBar > div.specialDisc:not(.equal) {
    width: 45%;
    padding-right: 8px;
  }
  body.iPadPro .discountBar > div.specialDisc {
    border-right: 1px solid #ebebeb;
    /* padding: 12px 14px 8px 18px; */
    text-align: center;
  }
  body.iPadPro .discountBar > div {
    width: 100%;
    font-size: 1.575em;
    float: left;
    padding: 13px 14px 11px 12px;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-light);
    border-top: 1px solid #ebebeb;
    --current-tracking-Roboto: 0.015em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
    );
    background-color: #fff;
  }
  body.iPadPro .specialDisc span {
    margin-left: 8px;
  }
  body.iPadPro
    .visible-xs.discountBar.hideSpec
    > div.specialDisc:not(.equal)
    ~ div.timeDisc,
  .visible-xs.discountBar.hideSpec > div.timeDisc {
    width: 100%;
    text-align: center;
  }
  body.iPadPro .discountBar > div.specialDisc:not(.equal) ~ div.timeDisc {
    width: 55%;
    padding-left: 0px;
    padding-right: 6px;
  }
  body.iPadPro .discountBar > div {
    width: 100%;
    font-size: 1.575em;
    float: left;
    padding: 13px 14px 11px 12px;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-light);
    border-top: 1px solid #ebebeb;
    --current-tracking-Roboto: 0.015em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
    );
    background-color: #fff;
  }

  body.iPadPro .visible-xs.timeSlots {
    width: 100%;
    position: absolute;
    bottom: -100%;
    z-index: -5;
    left: 0;
    transition: all 0.3s linear;
    background-color: #fff;
    display: block !important;
  }
  body.iPadPro .swiperWeek {
    border-bottom: 2px solid #e4e4e4;
  }
  body.iPadPro .swiper-container,
  body.iPadPro .swiper-wrapper {
    overflow: visible !important;
    transition: transform 0.3s linear !important;
  }
  body.iPadPro .swiperWeek .swiper-slide.tabSUL.active {
    color: var(--second-theme-color);
  }
  body.iPadPro .swiperWeek .tabSUL:first-child {
    margin-left: 15px;
  }
  body.iPadPro .swiperWeek .swiper-slide.tabSUL {
    font-size: 1.168em;
    line-height: normal;
    margin-top: 15px;
    padding-bottom: 14px;
    padding-top: 0;
  }
  body.iPadPro .swiper-slide.subTimeXS.swiper-slide-active {
    max-height: 175px;
    overflow: hidden;
    min-height: 175px;
  }
  body.iPadPro .mbsc-w-p.mbsc-sc-whl-gr-c {
    top: -21px;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l {
    border-color: rgba(0, 0, 0, 0) !important;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-gr {
    padding: 0 !important;
    max-width: 100% !important;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l:before,
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l:after {
    position: absolute;
    content: '';
    width: 9999px;
    height: 1px;
    background-color: #ebebeb;
    top: 0;
    left: -999px;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l:after {
    top: auto;
    bottom: 0;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-itm {
    font-size: 18.73px !important;
    --current-tracking-Roboto: 0.93px;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    color: #4b4a4a;
    line-height: normal !important;
    padding-top: 11px !important;
  }

  body.iPadPro .selectTime {
    background-color: var(--main-theme-color);
    color: #fff;
    text-align: center;
    font-size: 1.625em;
    line-height: normal;
    padding: 16px 5px;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    position: relative;
    z-index: 9;
  }
  body.iPadPro .visible-xs.overlayTime {
    display: block;
    position: absolute;
    width: 100%;
    background-color: #535151;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    z-index: -999;
    display: block !important;
    transition: opacity 0.3s linear;
  }
  body.iPadPro .visible-xs.overlayTime.active {
    opacity: 0.24;
    z-index: 1;
    visibility: visible;
  }
  body.iPadPro #sticky .totalbottom {
    border-top: 0 none;
    padding: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  body.iPadPro .progressIcons > div.store i,
  body.iPadPro .progressIcons > div.moped i {
    top: 3px;
  }
  body.iPadPro .halfDiv > .halfHalfwImage > span {
    left: 0 !important;
  }
  body.iPadPro .detailsConfirm.detailFinal.detailPayment .detailsTitle div.img {
    top: 16px;
    right: 17px;
    position: fixed;
  }
  body.iPadPro
    .detailsConfirm.detailFinal.detailPayment
    .detailsTitle
    div.img
    img {
    width: 33px;
    margin-right: 3px;
  }
  /*----temp----*/
  body.iPadPro .comGo {
    position: absolute;
    top: 21px;
    left: 15px;
  }
  body.iPadPro .voucherBox.active {
    margin-top: 0;
  }
  body.iPadPro .codeSuccess.match ~ .reviewOrderWrap.match {
    height: calc(100% - 442px);
  }
  body.iPadPro .match ~ .visible-xs.visible-sm.addressMobileBar {
    margin-top: 16px;
  }
  body.iPadPro .codeSuccess {
    border-bottom: 0 none;
  }
  body.iPadPro .voucherBox.active ~ .reviewOrderWrap {
    margin-top: 22px;
    height: calc(100% - 370px);
  }
  /*----temp----*/
  body.iPadPro .detailsConfirm.detailFinal {
    padding-top: 46px;
  }
  body.iPadPro .detailsConfirm.detailFinal .detailsTitle {
    padding-bottom: 18px;
  }

  body.iPadPro .detailsConfirm.detailFinal span.voucher {
    font-size: 12.45px;
  }
  body.iPadPro .navWrap ul li a:hover {
    color: #4b4a4a;
  }

  body.iPadPro .detailsTitle span {
    top: 67px;
  }
  /* body.iPadPro .halfDivs{
        max-width: 332px;
        overflow: hidden;
    }
body.iPadPro .halfDiv{
        width: 48%;
        /* max-height: 328px; */
  /* height: 81%;
    } */
  body.iPadPro .halfDivs {
    max-width: 611px;
    margin-top: 150px;
  }

  body.iPadPro .halfDiv {
    max-height: 600px;
  }

  body.iPadPro .halfDiv:first-child > div:not(.halfHalfwImage) > span {
    left: 33%;
    top: 47%;
  }

  body.iPadPro .halfDiv:not(:first-child) > div:not(.halfHalfwImage) > span {
    left: 27%;
    top: 47%;
  }
  body.iPadPro .halfHalfwImage {
    background-size: 235% auto;
  }
  body.iPadPro .phoneRe {
    width: auto;
    text-transform: uppercase;
    font-size: 1.309em;
    border-color: var(--main-theme-color);
    color: var(--main-theme-color);
    padding: 5px;
    float: left;
    cursor: pointer;
    margin-top: 20px;
  }

  body.iPadPro .phoneRe span {
    display: inline !important;
  }
  body.iPadPro .detailsConfirmWrap {
    left: 0;
    position: fixed;
    transform: translateX(100%);
    transition: transform 0.25s linear;
  }
  body.iPadPro .detailsConfirmWrap.active {
    transform: translateX(-100%);
  }
  body.iPadPro .detailsConfirm.mobileScr .detailsTitle {
    line-height: 40px;
  }
  body.iPadPro .detailsConfirm.mobileScr .inputGroup {
    margin-top: 0;
  }
  body.iPadPro .detailsConfirm.detailMobileVer .detailsTitle {
    padding-right: 25px;
  }
  body.iPadPro .detailsConfirm {
    left: 0;
    top: 0;
    transform: translateX(100%);
    position: fixed;
    padding-top: 26px;
    transition: transform 0.25s linear;
  }

  body.iPadPro .detailsConfirm.active {
    transform: translateX(0);
  }

  body.iPadPro .backToOrder.thickcon-Left-Chevron,
  body.iPadPro .backToOrder.thickcon-Left-Chevron.active {
    top: -4px;
  }

  body.iPadPro .detailsTitle {
    font-size: 2.999em;
    padding-bottom: 35px;
  }
  body.iPadPro .visible-xs.timeSlots {
    width: 100%;
    position: absolute;
    bottom: -100%;
    z-index: -5;
    left: 0;
    transition: all 0.3s linear;
    background-color: #fff;
  }
  body.iPadPro .visible-xs.timeSlots.active {
    bottom: 0;
    z-index: 9999;
  }

  body.iPadPro .swiperTime .picker-container .picker-inner {
    font-size: 1.873em;
    --current-tracking-Roboto: 0.05em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    -webkit-mask-box-image: none;
  }
  body.iPadPro .selectTime {
    background-color: var(--main-theme-color);
    color: #fff;
    text-align: center;
    font-size: 1.625em;
    line-height: normal;
    padding: 16px 5px;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    position: relative;
    z-index: 9;
  }
  body.iPadPro .picker-column:after,
  body.iPadPro .swiper-slide.subTimeXS:after {
    content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 90px;
    margin: 0 auto;
    right: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0.8) 74%,
      rgba(255, 255, 255, 0.46) 85%,
      rgba(255, 255, 255, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0.8) 74%,
      rgba(255, 255, 255, 0.46) 85%,
      rgba(255, 255, 255, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8) 74%,
      rgba(255, 255, 255, 0.46) 85%,
      rgba(255, 255, 255, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=0 );
  }
  body.iPadPro .mbsc-w-p.mbsc-sc-whl-gr-c {
    top: -21px;
  }
  body.iPadPro .swiperTime .picker-container .picker-column .picker-scroller {
    transition: transform 0.3s linear 0s;
  }
  body.iPadPro .swiperWeek .swiper-slide.tabSUL.active {
    color: var(--second-theme-color);
  }
  body.iPadPro .swiper-slide.tabSUL:hover {
    color: #4b4a4a;
  }
  body.iPadPro .discountBar > div.specialDisc:not(.equal) {
    width: 45%;
    padding-right: 8px;
  }
  body.iPadPro .discountBar > div.specialDisc:not(.equal) ~ div.timeDisc {
    width: 55%;
    padding-left: 0px;
    padding-right: 6px;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-itm.mbsc-disabled {
    visibility: hidden;
  }
  body.iPadPro .swiper-slide .picker-item {
    color: #6f6f6f;
  }

  body.iPadPro
    .swiper-slide
    .picker-container
    .picker-column
    .picker-item.picker-item-selected {
    color: #4b4a4a;
    line-height: 45px !important;
  }

  body.iPadPro .picker-column:before,
  body.iPadPro .swiper-slide.subTimeXS:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 99;
    height: 15px;
    max-width: 90px;
    margin: 0 auto;
    right: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 63%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 63%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 63%,
      rgba(255, 255, 255, 0.6) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#99ffffff',GradientType=0 );
  }
  body.iPadPro .visible-xs.overlayTime {
    display: block;
    position: absolute;
    width: 100%;
    background-color: #535151;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    z-index: -999;
    transition: opacity 0.3s linear;
  }
  body.iPadPro .visible-xs.overlayTime.active {
    opacity: 0.24;
    z-index: 1;
    visibility: visible;
  }
  body.iPadPro .swiper-slide.subTimeXS.swiper-slide-active {
    max-height: 175px;
    overflow: hidden;
    min-height: 175px;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-gr {
    padding: 0 !important;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-itm {
    font-size: 18.73px !important;
    --current-tracking-Roboto: 0.93px;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    color: #4b4a4a;
    line-height: normal !important;
    padding-top: 11px !important;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-fr-w {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l {
    border-color: rgba(0, 0, 0, 0) !important;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-itm.mbsc-active {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l:before,
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l:after {
    position: absolute;
    content: '';
    width: 9999px;
    height: 1px;
    background-color: #ebebeb;
    top: 0;
    left: -999px;
  }
  body.iPadPro .mbsc-mobiscroll .mbsc-sc-whl-l:after {
    top: auto;
    bottom: 0;
  }

  body.iPadPro .swiperWeek .swiper-slide.tabSUL {
    font-size: 1.168em;
    line-height: normal;
    margin-top: 15px;
    padding-bottom: 14px;
    padding-top: 0;
  }

  body.iPadPro .swiperWeek {
    border-bottom: 2px solid #e4e4e4;
  }

  body.iPadPro .swiperWeek .tabSUL:first-child {
    margin-left: 15px;
  }

  body.iPadPro .singleSize.itemDet:not(.orderBtnP) .proceedWrap,
  body.iPadPro .singleSize.itemDet:not(.orderBtnP) .customiseView {
    bottom: -48px;
  }
  body.iPadPro .itemDet .proceedWrap {
    transition: all 0.2s ease;
  }

  body.iPadPro .singleSize.itemDet {
    bottom: 0;
  }

  body.iPadPro .customiseView {
    position: relative;
    transition: all 0.2s ease;
    bottom: 0;
    clear: both;
  }

  body.iPadPro .stWrap {
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }
  body.iPadPro table tr.cartRow > td.cItemName {
    position: absolute;
    top: 137px;
    left: 0;
    right: 0;
    width: 100%;
    color: #fff;
    font-size: 2.25em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding: 0 77px 0 13px;
  }
  body.iPadPro span.cItemPrice {
    display: inline-block;
    margin-top: 0;
    padding: 2px 6px 1px 6px;
    font-size: 0.689em;
    border-radius: 3px;
    background-color: #fff;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
    position: absolute;
    right: 13px;
    bottom: 3px;
  }
  body.iPadPro tr.cartRow > td.cItemQuantity {
    width: 100%;
    display: block;
    padding-bottom: 20px;
    padding-left: 13px;
    padding-right: 13px;
  }
  body.iPadPro .cItemCategory {
    text-align: left;
    font-size: 1.875em;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  body.iPadPro .cItemMoreInfo {
    margin-top: 13px;
  }
  body.iPadPro .cItemQuantity .cMinusPlusBtn > svg,
  body.iPadPro .cItemQuantity .cMinusPlusBtn > img {
    top: 5px;
    right: 5px;
  }
  body.iPadPro .cItemMoreInfo:not(:only-child):not(:first-child) {
    margin-top: 16px;
  }
  body.iPadPro .cItemQuantity span.cartCount {
    font-size: 1.625em;
    width: 42px;
  }
  body.iPadPro tr.cartRow {
    display: block;
    width: 100%;
    position: relative;
  }
  body.iPadPro table tr.cartRow > td.cItemImg {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 173px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
  }
  body.iPadPro .deliverySearchBox input {
    font-size: 1.363em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    /* padding-top: 5px;
        padding-bottom: 5px; */
    padding-top: 15px;
    padding-bottom: 14px;
    width: 100%;
    padding-right: 65px;
  }
  body.iPadPro .navbar-header.spHide .logoWrap {
    visibility: hidden;
  }
  body.iPadPro .appleDevice .deliverySearchBox input {
    padding-top: 16px;
    padding-bottom: 15px;
  }
  body.iPadPro .loadBoxWrap > div > div {
    margin-bottom: 61px;
  }
  body.iPadPro .loadBoxWrap > div > div:before {
    top: calc(100% + 26px);
  }
  body.iPadPro .fright__item .sideicon.icon-Scooter2 {
    font-size: 16px;
  }
  body.iPadPro .deliveryWrap .pickupboxInnerRight {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
  body.iPadPro .pickImg span {
    font-size: 19px;
    padding-right: 10px;
    padding-left: 4px;
  }
  body.iPadPro .orderViews.halfView.active {
    z-index: 101;
  }
  body.iPadPro .innerSP > div {
    top: 51px;
  }
  body.iPadPro .sP.detailsTitle span {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  body.iPadPro .deliveryCost {
    top: 14px;
    right: 14px;
  }
  body.iPadPro #sticky .totalbottom .confirmBtn > span {
    display: inline-block !important;
    margin-left: 10px;
  }
  body.iPadPro .visible-xs.visible-sm.addressMobileBar {
    display: block !important;
  }

  body.iPadPro .visible-xs.visible-sm.reviewBarM {
    display: block !important;
  }

  body.iPadPro .visible-xs.visible-sm.notesOverlay {
    display: block !important;
  }

  body.iPadPro .visible-xs.visible-sm.timeSlots {
    display: block !important;
  }
  body.iPadPro .discountBar > div {
    width: 100%;
    font-size: 1.575em;
    float: left;
    padding: 13px 14px 11px 12px;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-light);
    border-top: 1px solid #ebebeb;
    --current-tracking-Roboto: 0.015em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
    );
    background-color: #fff;
  }
  body.iPadPro .discountBar > div.specialDisc {
    border-right: 1px solid #ebebeb;
    /* padding: 12px 14px 8px 18px; */
    text-align: center;
  }
  body.iPadPro .specialDisc span {
    margin-left: 8px;
  }
  body.iPadPro .specialDisc i {
    float: right;
    font-size: 23px;
    position: relative;
    top: -2px;
    /* color: var(--second-theme-color); */
  }
  body.iPadPro .timeDisc span {
    color: var(--second-theme-color);
    font-size: 10px;
    text-transform: uppercase;
    padding: 2px 5px 1px 5px;
    border: 1px solid;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    border-radius: 12px;
    cursor: pointer;
    float: right;
    position: relative;
    top: 2px;
  }
  body.iPadPro .visible-xs.discountBar:before {
    content: '';
    height: 73px;
    width: 100%;
    top: -34px;
    position: absolute;
    display: block;
    z-index: -1;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
  }
  body.iPadPro .timeDisc i {
    position: relative;
    top: 2px;
    font-size: 18px;
    margin-right: 10px;
  }
  body.iPadPro .visible-xs.discountBar.hideSpec > div.specialDisc {
    display: none;
  }
  body.iPadPro
    .visible-xs.discountBar.hideSpec
    > div.specialDisc:not(.equal)
    ~ div.timeDisc,
  body.iPadPro .visible-xs.discountBar.hideSpec > div.timeDisc {
    width: 100%;
    text-align: center;
  }
  body.iPadPro .visible-xs.discountBar.hideSpec > div.timeDisc span {
    float: none;
    top: -2px;
    margin-left: 15px;
    padding-bottom: 2px;
  }
  body.iPadPro .fright__item {
    font-size: 1.125em;
  }
  body.iPadPro .fright li {
    margin-right: 0;
  }
  body.iPadPro .fright li:last-child {
    margin-right: 0;
    padding-left: 25px;
  }
  body.iPadPro .pickOrOrderWrap,
  body.iPadPro .fixHeightIssue.delcost .pickOrOrderWrap {
    padding-top: 0;
    height: calc(100% - 208px);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
  }
  body.iPadPro .sidefixe1 {
    overflow: hidden;
  }
  body.iPadPro .pickupbox {
    margin-bottom: 15px;
  }
  body.iPadPro .deliveryCost.visible-xs > span {
    font-size: 0.9em;
    padding: 0px 0px 1px;
    display: inline-block;
  }
  body.iPadPro .cItemMoreInfo .cItemCategory.spName {
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
    text-align: left;
    font-size: 2em;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  body.iPadPro span.visible-xs.visible-sm {
    display: inline !important;
  }

  body.iPadPro .cItemMoreInfo > div.cItemCategory {
    width: calc(100% - 96px);
  }
  body.iPadPro .fright li {
    padding-bottom: 10px;
    padding-top: 5px;
    margin-bottom: 0;
    cursor: pointer;
  }
  body.iPadPro .fright li:nth-child(2) {
    padding-right: 15px;
    margin-right: -15px;
  }
  body.iPadPro .pickupbox {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  body.iPadPro #sticky .totalbottom .confirmBtn {
    border-radius: 0;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    font-size: 1.625em;
    padding-top: 16px;
    padding-bottom: 15px;
  }
  body.iPadPro .spWrap,
  body.iPadPro .spTotal {
    display: none;
  }
  body.iPadPro .visible-xs.close-x.thickcon-X {
    position: absolute;
    font-size: 2em;
    top: 0;
    padding-top: 26px;
    padding-left: 20px;
    left: -2px;
    padding-right: 25px;
    padding-bottom: 20px;
    cursor: pointer;
  }
  body.iPadPro #sticky .totalbottom {
    border-top: 0 none;
    padding: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  body.iPadPro .fright__item .sideicon {
    font-family: 'icomoon' !important;
    font-size: 15px;
    top: 3px;
  }
  body.iPadPro ul.fright {
    margin-top: 6px;
  }
  body.iPadPro .fright .icon-Store2:before {
    /* content: '\e901'; */
  }
  body.iPadPro .icon-Scooter2:before {
    /* content: '\e900'; */
  }
  body.iPadPro .cItemImg img {
    top: -100%;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: -100%;
    margin: auto;
    z-index: -1;
  }
  body.iPadPro table.cartTable {
    display: block;
    width: 100%;
  }
  body.iPadPro table.cartTable > tbody {
    width: 100%;
    display: block;
  }
  body.iPadPro .clickBtn > span {
    position: relative;
  }
  body.iPadPro .container-fluid.shadowIt {
    height: auto !important;
    overflow-x: hidden;
  }
  body.iPadPro .col-md-4.customBar.ordercartActive {
    /*transform: translateX(1px);*/
  }
  body.iPadPro .col-md-8.customWidth {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  body.iPadPro .itemWrap .swiper-container-autoheight {
    padding-left: 0;
    padding-right: 0;
  }
  body.iPadPro .orderViews {
    padding-top: 0;
    top: 0;
    position: fixed;
    /* overflow-y: scroll; */
    overflow: hidden;
    height: 100%;
    transition: all 0.2s ease 0s;
  }
  body.iPadPro .orderViews.active {
    -webkit-overflow-scrolling: touch;
  }

  body.iPadPro .order-items {
    height: calc(100%);
    /* transform: translateZ(0); */
  }
  body.iPadPro .shadowIt {
    /* box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1); */
  }
  body.iPadPro .order-items > div {
    top: 0;
    height: 100%;
    transition: none;
  }
  body.iPadPro .orderViews {
    left: 0;
    transform: translateX(100%);
    position: absolute;
  }
  body.iPadPro .orderViews.active {
    transform: translateX(0);
  }
  body.iPadPro .itemView span.cartCount {
    width: 45px;
    bottom: -1px;
    font-size: 0.875em;
  }
  body.iPadPro .qViewItem:last-child {
    margin-bottom: 13px;
  }
  body.iPadPro .glutenFree.visible-xs > img {
    width: 9px;
    height: auto;
    position: relative;
    top: -1px;
    margin-right: 4px;
    left: 1px;
  }
  body.iPadPro .cMinusPlusBtn {
    width: 27px;
    height: 27px;
    border-radius: 15px;
  }
  body.iPadPro .itemDet.orderBtnP {
    bottom: 0;
  }
  body.iPadPro .orderPrice.hidden-xs.hidden-sm {
    display: none;
  }
  body.iPadPro .itemView .cMinusPlusBtn > svg,
  body.iPadPro .itemView .cMinusPlusBtn > img {
    right: -5px;
    top: 5px;
  }
  body.iPadPro .col-md-4.customBar {
    top: 0% !important;
    left: auto;
    width: 100%;
    z-index: 9999;
    padding-top: 0;
    /* background-color: white; */
    box-shadow: none;
    transform: translateX(100%);
    transition: transform 0.3s linear;
  }
  body.iPadPro #sticky div.stWrap {
    left: -100%;
    z-index: 999;
    opacity: 0;
    transform: translateY(100%);
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    width: 100%;
    transition: left 0.2s linear, transform 0.2s linear, opacity 0.2s linear;
  }
  body.iPadPro #sticky div.stWrap.active {
    transform: translateY(0);
    opacity: 1;
  }
  body.iPadPro .itemView hr,
  body.iPadPro .customiseView span.viewTitle {
    display: none;
  }
  body.iPadPro span.nameView {
    font-size: 2.264em;
    margin-bottom: 6px;
  }
  body.iPadPro span.ingView {
    font-size: 1.576em;
  }
  body.iPadPro span.viewTitle {
    font-size: 2.268em;
  }
  body.iPadPro .itemDet {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    bottom: -48px;
    padding-bottom: 48px;
    min-height: 265px;
  }
  body.iPadPro .activeCount {
    top: -8px;
    right: -21px;
  }
  html.page--has-modal body.iPadPro {
    overflow: auto !important;
    height: 100% !important;
    /* width: 100%;
        -webkit-overflow-scrolling: touch !important; */
  }
  body.iPadPro .quantityView {
    margin-top: 19px;
  }
  body.iPadPro .quantityView > div:not(:first-child) {
    overflow: hidden;
  }
  body.iPadPro span.cartCount {
    bottom: -3px;
  }
  body.iPadPro .quantityView > div span.viewTitle {
    margin-bottom: 24px;
  }
  body.iPadPro .glutenFree.visible-xs {
    font-size: 1.2em;
    top: 116px;
    --current-tracking-Roboto: 0;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding-top: 4px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 6px;
    top: -1px;
    right: 0;
  }
  body.iPadPro .glutenFree.visible-xs i.thickcon-Plus,
  body.iPadPro .glutenFree.visible-xs i.thickcon-Checkmark {
    font-size: 10px;
  }
  body.iPadPro .qViewItem > * {
    font-size: 1.87em;
  }
  body.iPadPro .qCategory.robMedium {
    --current-tracking-Roboto: 0;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    min-width: 96px;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
  }
  body.iPadPro .qCategory.robMedium.gFree {
    width: 175px;
  }
  body.iPadPro .qViewItem {
    margin-bottom: 23px;
  }
  body.iPadPro .customiseView {
    margin: 0 -25px;
  }
  body.iPadPro .qCategory,
  body.iPadPro .qPrice {
    padding-top: 2px;
  }
  body.iPadPro .clickBtn {
    width: 50%;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    padding-top: 12px;
    padding-bottom: 11px;
    text-align: center;
    border-bottom: 0 none;
    border-left: 0 none;
    font-size: 1.628em;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  body.iPadPro .clickBtn.full {
    width: 100%;
  }
  body.iPadPro .itemDet span.visible-xs {
    display: inline !important;
    margin-left: 5px;
  }
  body.iPadPro .editIng.clickBtn {
    border-right: 0 none;
  }
  body.iPadPro .itemDet .proceedBtn {
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-medium);
    font-size: 1.625em;
    border-radius: 0;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding: 13px 0 13px;
  }
  body.iPadPro itemDet .proceedBtn > span {
    display: inline !important;
  }
  body.iPadPro .itemDet .proceedOrder {
    padding: 0;
    border-top: 0 none;
    margin: 0;
  }
  body.iPadPro .itemDet .proceedWrap {
    left: 0;
    right: 0;
  }
  body.iPadPro .swiper-container,
  body.iPadPro .swiper-wrapper {
    overflow: visible !important;
    transition: transform 0.3s linear !important;
  }
  body.iPadPro .orderViews.specialView,
  body.iPadPro .orderViews.halfView {
    z-index: 100;
  }
  body.iPadPro .itemWrap .swiper-container-autoheight .swiper-slide {
    overflow: visible !important;
    padding-left: 19px;
    padding-right: 19px;
  }
  body.iPadPro .boxesSP {
    margin: auto -10px;
  }

  body.iPadPro .bSp {
    padding: 0 10px;
    min-height: 150px;
    margin-bottom: 20px;
  }

  body.iPadPro .innerSP {
    height: 157.5px;
  }
  body.iPadPro .orderViews.spItemD.active {
    z-index: 101;
  }
  body.iPadPro .orderViews.active.halfD {
    z-index: 103;
  }
  /* body.iPadPro   tr.cartRow.halfHalfOK > td.cItemName, body.iPadPro table tr.cartRow>td.cItemName.bigName {
        top: 110px;
    }
  body.iPadPro   tr.cartRow.halfHalfOK .cItemImg  img {
        width: 50%;
        float: left;
        position: relative;
        top: auto;
        bottom: auto;
    } */
  body.iPadPro .orderViews.spItemD span.ingView,
  body.iPadPro .orderViews.halfD span.ingView {
    min-height: 99px;
  }
  body.iPadPro .active ~ .orderViews.halfView.active {
    transform: translateX(100%);
  }
  body.iPadPro .halfDiv:first-child > div > span {
    transform: rotateY(0deg);
  }
  /* body.iPadPro .halfDiv:first-child>div>span{left: 30%;}
 body.iPadPro .halfDiv:not(:first-child)>div>span{left:25%}
 body.iPadPro .halfDiv .halfHalfwImage span {
        top: 39%;
        font-size: 2em;
    }
 body.iPadPro .halfDiv span img{width: 20px;}
 body.iPadPro .halfDiv span {
        font-size: 3.0em;
        top: 44%;
    }  */
  body.iPadPro .navWrap ul.nav li {
    margin-top: -15px;
    padding-top: 15px;
    cursor: pointer;
    padding-bottom: 15px;
    margin-bottom: -15px;
    margin-right: 50px;
  }
  body.iPadPro body,
  .visible-xs.bubble,
  body.iPadPro .visible-xs.bubble *,
  body.iPadPro .visible-xs.bubble li,
  body.iPadPro .footerWrap *,
  body.iPadPro img.logo,
  body.iPadPro .cMinusPlusBtn {
    touch-action: manipulation !important;
  }
  body.iPadPro .reviewOrderWrap {
    margin-top: 22px;
    height: calc(100% - 313px);
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
  }

  body.iPadPro .reviewItemO > div {
    font-size: 1.647em;
    --current-tracking-Roboto: 0.018em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    vertical-align: top;
  }

  body.iPadPro .revieLeft span:first-child {
    margin-top: 8px;
  }
  body.iPadPro .reviewItemO.visible-xs {
    display: table !important;
  }
  body.iPadPro .re-center,
  body.iPadPro .threeDel {
    display: none;
  }
  body.iPadPro .progressIcons > div.moped {
    margin-left: 90px;
    margin-right: 90px;
  }

  body.iPadPro .progressIcons > div {
    width: 34px;
    font-size: 19px;
    height: 34px;
  }

  i.delivery svg,
  i.delivery img {
    width: 22px;
  }

  body.iPadPro span.progressbar {
    max-width: 90px;
    top: 16px;
    left: calc(50% + 17px);
  }

  body.iPadPro .deliveryWrapFinal > div.progressIcons {
    top: -19px;
  }

  body.iPadPro .progressIcons > div.store.active:after,
  body.iPadPro .progressIcons > div.store.del3:after,
  body.iPadPro .progressIcons > div.store.del4:after,
  body.iPadPro .progressIcons > div.store.del25:after,
  .progressIcons > div.store.del12:after,
  .progressIcons > div.store.del13:after {
    top: 15px;
    width: 91px;
  }
  body.iPadPro .deliveryWrapFinal {
    left: 12px;
    right: 12px;
  }

  body.iPadPro .deliveryWrapFinal.active {
    bottom: 14px;
    z-index: 999;
  }
  body.iPadPro .deliveryWrapFinal > div.oneDel {
    width: 100%;
    padding: 24px 15px 0;
    position: relative;
  }

  body.iPadPro .oneDel > p:first-child {
    font-size: 1.875em;
    text-align: center;
  }

  body.iPadPro .oneDel > p:last-child {
    font-size: 1.408em;
    float: none;
    position: absolute;
    right: 15px;
    left: 15px;
    text-align: center;
    bottom: -120%;
  }

  body.iPadPro .oneDel > p:last-child > i {
    font-size: 19px;
    top: 0;
  }

  body.iPadPro .twoDel > p {
    font-size: 1.408em;
    padding-top: 0;
    text-align: center;
    padding-bottom: 40px;
  }

  body.iPadPro .deliveryWrapFinal > div.twoDel {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  body.iPadPro .twoDel > p > i {
    font-size: 20px;
    top: 0;
    margin-right: 9px;
  }
  body.iPadPro .oneDel > p:last-child > i,
  body.iPadPro .twoDel > p > i {
    top: 3px;
  }
  body.iPadPro .oneDel > p:first-child {
    margin-bottom: 6px;
  }
  body.iPadPro .addB2 i.icon-Home {
    left: 21px;
  }
  /*---boundry--*/
  body.iPadPro .android .navbar-fixed-top {
    touch-action: none;
  }
  body.iPadPro .android .timeDisc span {
    padding-top: 4px;
    padding-bottom: 1px;
  }
  body.iPadPro .nextDetails {
    bottom: auto;
    top: 90%;
  }
  /* .android .detailsConfirm.detailMobileVer.active.truekeyBoard ~ .nextDetails {bottom: -100px; top: auto;} */
  body.iPadPro .appleDevice .moveNext0.detailsConfirm.active ~ .nextDetails {
    bottom: auto;
    top: 65%;
  }
  body.iPadPro .appleDevice .detailsConfirm.mobileScr.active ~ .nextDetails {
    bottom: auto;
    top: 50%;
  }
  body.iPadPro .spItemD.orderViews {
    transition-delay: 0.2s;
  }
  body.iPadPro .extraScreen span.thickcon-Plus {
    width: 60px;
    height: 49px;
    margin-left: 7px;
    padding-left: 20px;
    margin-top: 20px;
    padding-top: 26px;
  }
  body.iPadPro .extraScreen h2 {
    /*margin-top: 9px;*/
  }

  body.iPadPro ul.nav.navbar-nav.pull-right {
    display: none;
  }
  body.iPadPro .qCategory.robMedium.gFree {
    max-width: none;
    width: auto;
    min-width: auto;
    margin-right: 20px;
  }

  body.iPadPro .detailsConfirm.detailFinal > div.fixedDiv {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 20px;
    padding-top: 14px;
  }

  body.iPadPro .halfDiv > .halfHalfwImage > span {
    top: 46%;
    font-size: 2.8em;
  }
  body.iPadPro .backToOrder.thickcon-Left-Chevron.visible-xs.visible-sm {
    display: block !important;
  }
  body.iPadPro .halfDiv:first-child .halfHalfwImage.half1:not(.false) span {
    max-width: 100%;
  }
  body.iPadPro .itemBoxes,
  body.iPadPro .itemBoxes.boxWrapper {
    width: 25%;
  }
  body.iPadPro .container-fluid > .navbar-header {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    text-align: center;
  }

  body.iPadPro .logoWrap {
    margin-left: auto;
    margin-right: auto;
  }
  /* body.iPadPro .halfDiv span {
        top: 45%;
        left: 58px;
        font-size: 4.5em
    }
 body.iPadPro .halfDiv span img{width:30px;}
 body.iPadPro .halfDivs {
        max-width: 520px;
        margin-top: 42px;
    }
 body.iPadPro .halfDiv {
        height: 513px;
    } */

  body.iPadPro .halfHalfwImage span {
    left: 0;
    font-size: 2.5em;
    top: 234px;
  }

  /*  body.iPadPro .orderViews .imgView {
    height: 75%;
  }*/

  body.iPadPro tr.cartRow.halfHalfOK > td.cItemName,
  body.iPadPro table tr.cartRow > td.cItemName.bigName,
  body.iPadPro table tr.cartRow > td.cItemName {
    top: 173px;
  }

  body.iPadPro tr.cartRow.halfHalfOK .cItemImg img {
    top: -65px;
  }

  body.iPadPro table tr.cartRow > td.cItemImg {
    height: 215px;
  }

  body.iPadPro .detailsConfirm.detailFinal.detailPayment .detailsTitle img {
    width: auto;
  }
  body.iPadPro .halfDiv:first-child > div > span {
    left: 25%;
  }
  body.iPadPro .halfDiv:not(:first-child) > div > span {
    left: 20%;
  }
}
/*---Tablet media queries--*/

@media all and (max-width: 400px) {
  .itemWrap .mbsc-scv-item {
    max-width: 385px;
  }
}

@media all and (max-width: 420px) and (min-width: 400px) {
  .itemWrap .mbsc-scv-item {
    max-width: 424px;
  }
}

@media all and (max-width: 770px) and (min-width: 420px) {
  .itemWrap .mbsc-scv-item {
    max-width: 778px;
  }
}

@media all and (max-width: 1025px) and (min-width: 770px) {
  .itemWrap .mbsc-scv-item {
    max-width: 1034px;
  }
}

@media all and (max-width: 1880px) and (min-width: 991px) {
  .itemWrap .mbsc-scv-item {
    max-width: 1450px;
  }
}

@media all and (max-width: 2501px) and (min-width: 1921px) {
  .itemWrap .mbsc-scv-item {
    max-width: none;
  }
}

@media screen and (min-width: 2502px) {
  .itemWrap .mbsc-scv-item {
    max-width: none;
  }
}

@media all and (max-width: 1023px) {
  .detailsConfirm {
    padding-top: 12px !important;
  }
}
@media all and (max-width: 991px) {
  .itemWrap .mbsc-scv-item {
    transition-delay: 1s;
  }
  .active.mbsc-scv-item {
    transition-delay: 0s;
  }
  .progressIcons > div.store i,
  .progressIcons > div.moped i {
    top: 3px;
  }
  .halfDiv > .halfHalfwImage > span {
    left: 0 !important;
  }
  /*---boundry--*/
  .android .visible-xs.bubble {
    bottom: calc(-100vh + 180px);
  }
  .detailsConfirm.detailFinal.detailPayment .detailsTitle div.img {
    top: 16px;
    right: 17px;
    position: fixed;
  }
  .detailsConfirm.detailFinal.detailPayment .detailsTitle div.img img {
    width: 33px;
    margin-right: 3px;
  }
  /*----temp----*/
  .comGo {
    position: absolute;
    top: 14px;
    left: 0;
  }
  .voucherBox.active {
    margin-top: 0;
  }
  .codeSuccess.match ~ .reviewOrderWrap.match {
    height: calc(100% - 442px);
  }
  .match ~ .visible-xs.visible-sm.addressMobileBar {
    margin-top: 16px;
  }
  .codeSuccess {
    border-bottom: 0 none;
  }
  .voucherBox.active ~ .reviewOrderWrap {
    margin-top: 22px;
    height: calc(100% - 370px);
  }
  /*----temp----*/
  .detailsConfirm.detailFinal {
    padding-top: 46px;
  }
  .detailsConfirm.detailFinal .detailsTitle {
    padding-bottom: 18px;
  }

  .detailsConfirm.detailFinal span.voucher {
    font-size: 12.45px;
  }
  .navWrap ul li a:hover {
    color: #4b4a4a;
  }

  .detailsTitle span {
    top: 67px;
  }
  .halfDivs {
    max-width: 332px;
    overflow: hidden;
  }
  .halfDiv {
    width: 48%;
    /* max-height: 328px; */
    height: 81%;
  }

  .halfHalfwImage {
    background-size: 235% auto;
  }
  .phoneRe {
    width: auto;
    text-transform: uppercase;
    font-size: 1.309em;
    border-color: var(--main-theme-color);
    color: var(--main-theme-color);
    padding: 5px;
    float: left;
    cursor: pointer;
    margin-top: 20px;
  }

  .phoneRe span {
    display: inline !important;
  }
  .detailsConfirmWrap {
    left: 0;
    position: fixed;
    transform: translateX(100%);
    transition: transform 0.25s linear;
  }
  .detailsConfirmWrap.active {
    transform: translateX(-100%);
  }
  .detailsConfirm.mobileScr .detailsTitle {
    line-height: 40px;
  }
  .detailsConfirm.mobileScr .inputGroup {
    margin-top: 0;
  }
  .detailsConfirm.detailMobileVer .detailsTitle {
    padding-right: 25px;
  }
  .detailsConfirm {
    left: 0;
    top: 0;
    transform: translateX(100%);
    position: fixed;
    padding-top: 26px;
    transition: transform 0.25s linear;
  }

  .detailsConfirm.active {
    transform: translateX(0);
  }

  .backToOrder.thickcon-Left-Chevron,
  .backToOrder.thickcon-Left-Chevron.active {
    padding: 20px;
    top: 4px;
    left: 4px;
  }

  .detailsTitle {
    font-size: 2.499em;
    padding-bottom: 35px;
  }
  .visible-xs.timeSlots {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    z-index: -5;
    transition: transform 0.3s linear;
    background-color: #fff;
    visibility: hidden;
  }
  .visible-xs.timeSlots.active {
    bottom: 0;
    z-index: 9999;
    transform: translateY(0%);
    visibility: visible;
  }

  .swiperTime .picker-container .picker-inner {
    font-size: 1.873em;
    --current-tracking-Roboto: 0.05em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    -webkit-mask-box-image: none;
  }
  .selectTime {
    background-color: var(--main-theme-color);
    color: #fff;
    text-align: center;
    font-size: 1.625em;
    line-height: normal;
    padding: 16px 5px;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    position: relative;
    z-index: 9;
  }
  .picker-column:after,
  .swiper-slide.subTimeXS:after {
    content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 90px;
    margin: 0 auto;
    right: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0.8) 74%,
      rgba(255, 255, 255, 0.46) 85%,
      rgba(255, 255, 255, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0.8) 74%,
      rgba(255, 255, 255, 0.46) 85%,
      rgba(255, 255, 255, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8) 74%,
      rgba(255, 255, 255, 0.46) 85%,
      rgba(255, 255, 255, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=0 );
  }
  .mbsc-w-p.mbsc-sc-whl-gr-c {
    top: -21px;
  }
  .swiperTime .picker-container .picker-column .picker-scroller {
    transition: transform 0.3s linear 0s;
  }
  .swiperWeek .swiper-slide.tabSUL.active {
    color: var(--second-theme-color);
  }
  .swiper-slide.tabSUL:hover {
    color: #4b4a4a;
  }
  .discountBar > div.specialDisc:not(.equal) {
    width: 45%;
    padding-right: 8px;
  }
  .discountBar > div.specialDisc:not(.equal) ~ div.timeDisc {
    width: 55%;
    padding-left: 0px;
    padding-right: 6px;
  }
  .mbsc-mobiscroll .mbsc-sc-itm.mbsc-disabled {
    visibility: hidden;
  }
  .swiper-slide .picker-item {
    color: #6f6f6f;
  }

  .swiper-slide
    .picker-container
    .picker-column
    .picker-item.picker-item-selected {
    color: #4b4a4a;
    line-height: 45px !important;
  }

  .picker-column:before,
  .swiper-slide.subTimeXS:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 99;
    height: 15px;
    max-width: 90px;
    margin: 0 auto;
    right: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 63%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 63%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 63%,
      rgba(255, 255, 255, 0.6) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#99ffffff',GradientType=0 );
  }
  .visible-xs.overlayTime {
    display: block;
    position: absolute;
    width: 100%;
    background-color: #535151;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    z-index: -999;
    transition: opacity 0.3s linear;
  }
  .visible-xs.overlayTime.active {
    opacity: 0.24;
    z-index: 1;
    visibility: visible;
  }
  .swiper-slide.subTimeXS.swiper-slide-active {
    max-height: 175px;
    overflow: hidden;
    min-height: 175px;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-gr {
    padding: 0 !important;
  }
  .mbsc-mobiscroll .mbsc-sc-itm {
    font-size: 18.73px !important;
    --current-tracking-Roboto: 0.93px;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    color: #4b4a4a;
    line-height: normal !important;
    padding-top: 11px !important;
  }
  .mbsc-mobiscroll .mbsc-fr-w {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-l {
    border-color: rgba(0, 0, 0, 0) !important;
  }
  .mbsc-mobiscroll .mbsc-sc-itm.mbsc-active {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-l:before,
  .mbsc-mobiscroll .mbsc-sc-whl-l:after {
    position: absolute;
    content: '';
    width: 9999px;
    height: 1px;
    background-color: #ebebeb;
    top: 0;
    left: -999px;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-l:after {
    top: auto;
    bottom: 0;
  }

  .swiperWeek .swiper-slide.tabSUL {
    font-size: 1.168em;
    line-height: normal;
    margin-top: 15px;
    padding-bottom: 14px;
    padding-top: 0;
  }

  .swiperWeek {
    border-bottom: 2px solid #e4e4e4;
  }

  .swiperWeek .tabSUL:first-child {
    margin-left: 15px;
  }

  .singleSize.itemDet:not(.orderBtnP) .proceedWrap,
  .singleSize.itemDet:not(.orderBtnP) .customiseView {
    bottom: -48px;
  }
  .itemDet .proceedWrap {
    transition: all 0.2s ease;
  }

  .singleSize.itemDet {
    bottom: 0;
  }

  .horizontal-ruler {
    display: none;
  }

  .customiseView {
    bottom: 0 !important;
  }
  .customiseView {
    margin: 0 -25px 15px;
  }

  .itemDet.orderBtnP > .quantityView > .proceedWrap {
    bottom: 8px;
  }

  .stWrap {
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }
  table tr.cartRow > td.cItemName {
    position: absolute;
    top: 137px;
    left: 0;
    right: 0;
    width: 100%;
    color: #fff;
    font-size: 2.25em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding: 0 77px 0 13px;
  }
  span.cItemPrice {
    display: inline-block;
    margin-top: 0;
    padding: 2px 6px 1px 6px;
    font-size: 0.689em;
    border-radius: 3px;
    background-color: #fff;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
    position: absolute;
    right: 13px;
    bottom: 3px;
  }
  tr.cartRow > td.cItemQuantity {
    width: 100%;
    display: block;
    padding-bottom: 20px;
    padding-left: 13px;
    padding-right: 13px;
  }
  .cItemCategory {
    text-align: left;
    font-size: 1.875em;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  .cItemMoreInfo {
    margin-top: 13px;
  }
  .cItemQuantity .cMinusPlusBtn > svg,
  .cItemQuantity .cMinusPlusBtn > img {
    top: 5px;
    right: 5px;
  }

  .icon-button-group {
    top: 5px;
    right: -5px;
  }

  .to-right {
    right: 5px;
  }

  .cItemMoreInfo:not(:only-child):not(:first-child) {
    margin-top: 16px;
  }
  .cItemQuantity span.cartCount {
    font-size: 1.625em;
    width: 42px;
  }
  tr.cartRow {
    display: block;
    width: 100%;
    position: relative;
  }
  table tr.cartRow > td.cItemImg {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 173px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
  }
  .deliverySearchBox input {
    font-size: 1.363em;
    --current-tracking-Roboto: 0.02em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding-top: 15px;
    padding-bottom: 14px;
    width: 100%;
    padding-right: 65px;
  }
  .navbar-header.spHide .logoWrap {
    visibility: hidden;
  }
  .appleDevice .deliverySearchBox input {
    padding-top: 16px;
    padding-bottom: 15px;
  }
  .loadBoxWrap > div > div {
    margin-bottom: 61px;
  }
  .loadBoxWrap > div > div:before {
    top: calc(100% + 26px);
  }
  .fright__item .sideicon.icon-Scooter2 {
    font-size: 16px;
  }
  .deliveryWrap .pickupboxInnerRight {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
  .pickImg span {
    font-size: 19px;
    padding-right: 10px;
    padding-left: 4px;
  }
  .innerSP > div {
    top: 51px;
  }
  .sP.detailsTitle span {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .geosuggest__suggests-wrapper ul.autoSuggestionWrap {
    position: absolute;
    width: calc(100% + 2px);
    background-color: #fff;
    height: 100vh;
    left: -1px;
    z-index: 999;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
    top: calc(100% - 1px);
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #dbdbdb;
  }

  .autoSuggestionWrap {
    right: 0;
    border: 0 none;
    padding-top: 0;
    border-radius: 0;
    padding: 0 15px;
  }

  ul.autoSuggestionWrap li {
    font-size: 1.363em;
    line-height: normal;
    padding-left: 31px;
    border-top: 1px solid #f6f6f6;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-bottom: 0;
  }

  .pickupboxInnerRight ul.autoSuggestionWrap li:before {
    font-size: 22px;
    top: 12px;
  }

  ul.autoSuggestionWrap li:last-child {
    border-bottom: 1px solid #f6f6f6;
  }
  .visible-xs.bubble.white {
    left: calc(50% - 148px);
  }
  #sticky .totalbottom .confirmBtn > span {
    display: inline-block !important;
    margin-left: 10px;
  }
  .pickRestName.robMedium {
    padding-top: 11px;
    padding-bottom: 10px;
  }
  .visible-xs.discountBar {
    display: flex !important;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
  }
  .discountBar > div {
    width: 100%;
    font-size: 1.575em;
    float: left;
    padding: 13px 14px 11px 5px;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-light);
    border-top: 1px solid #ebebeb;
    --current-tracking-Roboto: 0.015em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-light, 0em)
    );
    background-color: #fff;
  }
  .discountBar > div.specialDisc {
    border-right: 1px solid #ebebeb;
    /* padding: 12px 14px 8px 18px; */
    text-align: center;
  }
  .specialDisc span {
    margin-left: 8px;
  }
  .specialDisc i {
    float: right;
    font-size: 23px;
    position: relative;
    top: -2px;
    /* color: var(--second-theme-color); */
  }
  .timeDisc span {
    color: var(--second-theme-color);
    font-size: 10px;
    text-transform: uppercase;
    padding: 2px 5px 1px 5px;
    border: 1px solid;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    border-radius: 12px;
    cursor: pointer;
    float: right;
    position: relative;
    top: 2px;
  }
  .visible-xs.discountBar:before {
    content: '';
    height: 73px;
    width: 100%;
    top: -34px;
    position: absolute;
    display: block;
    z-index: -1;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 99%,
      rgba(0, 0, 0, 0.5) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
  }
  .timeDisc i {
    position: relative;
    top: 2px;
    font-size: 18px;
    margin-right: 10px;
  }
  .visible-xs.discountBar.hideSpec > div.specialDisc {
    display: none;
  }
  .visible-xs.discountBar.hideSpec > div.specialDisc:not(.equal) ~ div.timeDisc,
  .visible-xs.discountBar.hideSpec > div.timeDisc {
    width: 100%;
    text-align: center;
  }
  .visible-xs.discountBar.hideSpec > div.timeDisc span {
    float: none;
    top: -2px;
    margin-left: 15px;
    padding-bottom: 2px;
  }
  .fright__item {
    font-size: 1.125em;
    padding-bottom: 18px;
  }
  .fright li {
    margin-right: 15px;
  }
  .fright li:last-child {
    margin-right: 0;
    padding-left: 25px;
  }
  .pickOrOrderWrap,
  .fixHeightIssue.delcost .pickOrOrderWrap {
    padding-top: 0;
    height: calc(100% - 220px);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pickOrOrderWrap.singleItem {
    height: calc(100% - 175px);
  }

  .sidefixe1 {
    overflow: hidden;
  }
  .pickupbox {
    margin-bottom: 15px;
  }
  .deliveryCost.visible-xs > span {
    font-size: 0.9em;
    padding: 0px 0px 1px;
    display: inline-block;
  }
  .cItemMoreInfo .cItemCategory.spName {
    font-weight: var(--old-font-medium);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-medium, 0em)
    );
    text-align: left;
    font-size: 2em;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  span.visible-xs.visible-sm {
    display: inline !important;
  }

  .cItemMoreInfo > div.cItemCategory {
    width: calc(100% - 96px);
  }
  .fright li {
    padding-bottom: 10px;
    padding-top: 5px;
    margin-bottom: 0;
    cursor: pointer;
  }
  /* .fright li:nth-child(2) {
    padding-right: 15px;
    margin-right: -15px;
  } */
  .pickupbox {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  #sticky .totalbottom .confirmBtn {
    border-radius: 0;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    font-size: 1.625em;
    padding-top: 16px;
    padding-bottom: 15px;
  }
  .spWrap,
  .spTotal {
    display: none;
  }
  .visible-xs.close-x.thickcon-X {
    position: absolute;
    font-size: 2em;
    top: 0;
    padding-top: 26px;
    padding-left: 20px;
    left: -2px;
    padding-right: 25px;
    padding-bottom: 20px;
    cursor: pointer;
  }
  #sticky .totalbottom {
    border-top: 0 none;
    padding: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .fright__item .sideicon {
    font-family: 'icomoon' !important;
    font-size: 15px;
    top: 3px;
  }
  ul.fright {
    margin-top: 6px;
  }
  .cItemImg img {
    top: -100%;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: -100%;
    margin: auto;
    z-index: -1;
  }
  table.cartTable {
    display: block;
    width: 100%;
  }
  table.cartTable > tbody {
    width: 100%;
    display: block;
  }
  .clickBtn > span {
    position: relative;
  }
  .container-fluid.shadowIt {
    overflow-x: hidden;
  }
  .col-md-4.customBar.ordercartActive {
    /*transform: translateX(1px);*/
  }
  .col-md-8.customWidth {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .itemWrap .swiper-container-autoheight {
    padding-left: 0;
    padding-right: 0;
  }
  .orderViews {
    padding-top: 0;
    top: 0;
    position: fixed;
    /* overflow-y: scroll; */
    overflow: hidden;
    height: 100%;
    /* transition: all 0.2s ease 0s; */
  }
  .orderViews.active {
    -webkit-overflow-scrolling: touch;
  }

  .order-items {
    height: calc(100%);
    /* transform: translateZ(0); */
  }
  .shadowIt {
    /* box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1); */
  }
  .order-items > div {
    top: 0;
    height: 100%;
    transition: none;
  }
  .orderViews {
    left: 0;
    transform: translateX(100%);
    position: absolute;
  }
  .orderViews.active {
    transform: translateX(0);
  }
  .itemView span.cartCount {
    width: 45px;
    font-size: 1.714em;
  }
  .qViewItem:last-child {
    margin-bottom: 23px;
  }
  .glutenFree.visible-xs > img {
    width: 9px;
    height: auto;
    position: relative;
    top: -1px;
    margin-right: 4px;
    left: 1px;
  }
  .cMinusPlusBtn {
    width: 27px;
    height: 27px;
    border-radius: 15px;
  }
  .itemView .cMinusPlusBtn > svg,
  .itemView .cMinusPlusBtn > img {
    right: -5px;
    top: 5px;
  }
  .col-md-4.customBar {
    top: 0% !important;
    left: auto;
    width: 100%;
    z-index: 9999;
    padding-top: 0;
    /* background-color: white; */
    box-shadow: none;
    transform: translateX(100%);
    transition: transform 0.3s linear;
  }
  #sticky div.stWrap {
    left: -100%;
    z-index: 999;
    opacity: 0;
    transform: translateY(100%);
    /* overflow: hidden; */
    background-color: #fff;
    position: absolute;
    width: 100%;
    transition: left 0.2s linear, transform 0.2s linear, opacity 0.2s linear;
  }
  #sticky div.stWrap.active {
    transform: translateY(0);
    opacity: 1;
  }

  span.nameView {
    font-size: 2.264em;
    margin-bottom: 6px;
  }
  span.ingView {
    font-size: 1.576em;
  }
  span.viewTitle {
    font-size: 2.268em;
  }
  .itemDet {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    bottom: -48px;
    padding-bottom: 48px;
    min-height: 265px;
  }
  .activeCount {
    top: -8px;
    right: -21px;
  }
  /*html.page--has-modal,*/
  /*html.page--has-modal body {*/
  /*  height: 100vh;*/
  /*  overflow: hidden;*/
  /* width: 100%;
        -webkit-overflow-scrolling: touch !important; */
  /*}*/
  /*html.page--has-modal .itemView{
        -webkit-overflow-scrolling: auto;
        height: calc(100% + 1px);
    } */
  .quantityView {
    margin-top: 19px;
  }
  .quantityView > div:not(:first-child) {
    overflow: hidden;
  }
  .quantityView > div span.viewTitle {
    margin-bottom: 24px;
  }
  .glutenFree.visible-xs {
    font-size: 1.2em;
    top: 116px;
    --current-tracking-Roboto: 0;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding-top: 4px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 6px;
    top: -1px;
    right: 0;
  }
  .glutenFree.visible-xs i.thickcon-Plus,
  .glutenFree.visible-xs i.thickcon-Checkmark {
    font-size: 10px;
  }
  .qViewItem > * {
    font-size: 1.87em;
  }
  .qViewItem > div:first-child {
    flex-grow: 1;
  }
  .qViewItem > div:nth-child(2) {
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding-right: 20px;
  }
  .qCategory.robMedium {
    --current-tracking-Roboto: 0em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    min-width: 96px;
    max-width: 120px;
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-normal);
  }
  .qCategory.robMedium.gFree {
    display: flex;
    flex-flow: column nowrap;
  }
  .qCategory.robMedium.gFree > span:nth-child(2) {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
  }
  .qViewItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
  }
  .qCategory,
  .qPrice {
    padding-top: 2px;
  }
  .itemDet span.visible-xs {
    display: inline !important;
    margin-left: 5px;
  }
  .itemDet .proceedBtn {
    font-family: var(--old-font-Roboto);
    font-weight: var(--old-font-medium);
    font-size: 1.625em;
    border-radius: 8px;
    --current-tracking-Roboto: 0.014em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    padding: 13px 0 13px;
  }
  itemDet .proceedBtn > span {
    display: inline !important;
  }
  .itemDet .proceedOrder {
    padding: 0;
    border-top: 0 none;
    margin: 0;
  }
  .itemDet .proceedWrap {
    left: 20px;
    right: 20px;
  }
  .swiper-container,
  .swiper-wrapper {
    overflow: visible !important;
    transition: transform 0.3s linear !important;
  }
  .orderViews.specialView,
  .orderViews.halfView {
    z-index: 100;
  }
  .itemWrap .swiper-container-autoheight .swiper-slide {
    overflow: visible !important;
    padding-left: 19px;
    padding-right: 19px;
  }
  .boxesSP {
    margin: auto -10px;
  }

  .bSp {
    padding: 0 10px;
    min-height: 150px;
    margin-bottom: 20px;
  }

  .innerSP {
    height: 157.5px;
  }
  .orderViews.spItemD.active {
    z-index: 101;
  }
  .orderViews.active.halfD {
    z-index: 103;
  }
  tr.cartRow.halfHalfOK > td.cItemName,
  table tr.cartRow > td.cItemName.bigName {
    top: 110px;
  }
  tr.cartRow.halfHalfOK .cItemImg img {
    width: 50%;
    float: left;
    position: relative;
    top: auto;
    bottom: auto;
  }
  .orderViews.spItemD span.ingView,
  .orderViews.halfD span.ingView {
    min-height: 99px;
  }
  .active ~ .orderViews.halfView.active {
    transform: translateX(100%);
  }
  .halfDiv:first-child > div > span {
    transform: rotateY(0deg);
  }
  .halfDiv:first-child > div > span {
    left: 30%;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 25%;
  }
  .halfDiv .halfHalfwImage span {
    top: 39%;
    font-size: 2em;
  }
  .halfDiv span img {
    width: 20px;
  }
  .halfDiv span {
    font-size: 3em;
    top: 44%;
  }
  .navWrap ul.nav li {
    margin-top: -15px;
    padding-top: 15px;
    cursor: pointer;
    padding-bottom: 15px;
    margin-bottom: -15px;
  }
  body,
  .visible-xs.bubble,
  .visible-xs.bubble *,
  .visible-xs.bubble li,
  .footerWrap *,
  img.logo,
  .cMinusPlusBtn {
    touch-action: manipulation !important;
  }
  .reviewOrderWrap {
    margin-top: 22px;
    height: calc(100% - 313px);
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
  }

  .reviewItemO > div {
    font-size: 1.647em;
    --current-tracking-Roboto: 0.018em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    vertical-align: top;
  }

  .revieLeft span:first-child {
    margin-top: 8px;
  }
  .reviewItemO.visible-xs {
    display: table !important;
  }
  .re-center,
  .threeDel {
    display: none;
  }
  .progressIcons > div.moped {
    margin-left: 90px;
    margin-right: 90px;
  }

  .progressIcons > div {
    width: 34px;
    font-size: 19px;
    height: 34px;
  }

  i.delivery svg,
  i.delivery img {
    width: 22px;
    top: -9px;
  }

  span.progressbar {
    max-width: 90px;
    top: 16px;
    left: calc(50% + 17px);
  }

  .deliveryWrapFinal > div.progressIcons {
    top: -19px;
  }

  .progressIcons > div.store.active:after,
  .progressIcons > div.store.del3:after,
  .progressIcons > div.store.del4:after,
  .progressIcons > div.store.del25:after,
  .progressIcons > div.store.del12:after,
  .progressIcons > div.store.del13:after {
    top: 15px;
    width: 91px;
  }

  .deliveryWrapFinal {
    left: 12px;
    right: 12px;
  }

  .deliveryWrapFinal.dine-in {
    zoom: 1.2;
  }

  .deliveryWrapFinal.active {
    transform: none !important;
    bottom: 14px;
    z-index: 999;
  }
  .deliveryWrapFinal > div.oneDel {
    width: 100%;
    padding: 24px 15px 10px;
    position: relative;
  }

  .lottie-animation > div[role='button'] {
    width: 90% !important;
    height: auto !important;
  }

  .oneDel > p:first-child {
    font-size: 1.875em;
    text-align: center;
  }

  .oneDel > p:last-child {
    font-size: 1.408em;

    text-align: center;
    /* float: none;
    position: absolute;
    right: 15px;
    left: 15px;
    text-align: center;
    bottom: -120%; */
  }

  .oneDel > p:last-child > i {
    font-size: 19px;
    top: 0;
  }

  .twoDel > p {
    font-size: 1.408em;
    padding-top: 0;
    text-align: center;
    padding-bottom: 60px;
  }

  .deliveryWrapFinal > div.twoDel {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .twoDel > p > i {
    font-size: 20px;
    top: 0;
    margin-right: 9px;
  }
  .oneDel > p:last-child > i,
  .twoDel > p > i {
    top: 3px;
  }
  .oneDel > p:first-child {
    margin-bottom: 12px;
  }
  .addB2 i.icon-Home {
    left: 21px;
  }

  .status-text {
    padding: 20% 8% 10%;
    text-align: left;
  }

  /*---boundry--*/
  .android .navbar-fixed-top {
    touch-action: none;
  }
  .android .timeDisc span {
    padding-top: 4px;
    padding-bottom: 1px;
  }

  .nextDetails {
    bottom: auto;
  }

  .android .visible-xs.discountBar.hideSpec > div.timeDisc span {
    padding-bottom: 2px;
  }

  .android .foucusedT ~ .reviewBarM,
  .android .foucusedT ~ .totalbottom {
    bottom: -90px !important;
  }
  .android .moveNext1.detailsConfirm.active ~ .nextDetails {
    bottom: auto;
    top: 80%;
  }
  .android .moveNextDown.true ~ .nextDetails,
  .android .detailsConfirm.mobileScr.active ~ .nextDetails {
    bottom: auto;
    top: 80%;
  }

  .appleDevice .moveNext0.detailsConfirm.active ~ .nextDetails {
    bottom: auto;
    top: 65%;
  }
  .appleDevice .detailsConfirm.mobileScr.active ~ .nextDetails {
    bottom: auto;
    top: 50%;
  }
  .spItemD.orderViews {
    transition-delay: 0.2s;
  }
  .extraScreen span.thickcon-Plus {
    width: 60px;
    height: 49px;
    margin-left: 7px;
    padding-left: 20px;
    margin-top: 20px;
    padding-top: 26px;
  }
  .extraScreen h2 {
    /*margin-top: 9px;*/
  }

  ul.nav.navbar-nav.pull-right {
    display: none;
  }
  .qCategory.robMedium.gFree {
    max-width: none;
    width: auto;
    min-width: auto;
    margin-right: 20px;
  }
  body.android .phoneRe {
    padding-bottom: 3px;
  }
  body.android .moveNext11.detailsConfirm.detailFinal.detailPayment > div {
    top: -125px;
    padding-bottom: 125px;
  }

  body.android
    #sticky
    .moveNext11.detailsConfirm.detailFinal.detailPayment
    ~ .totalbottom {
    bottom: -125px;
  }

  body.android .backToOrder.detail11.thickcon-Left-Chevron {
    top: -125px;
  }
  body.android #root {
    /*height: 100%;*/
  }
  body.android.beforeScroll #root {
    height: auto;
    padding-bottom: 0;
  }
  body.android #root {
    /*overflow-y: auto;*/
    /*-webkit-overflow-scrolling: touch;*/
    /*overscroll-behavior-y: contain;*/
  }
  body.android,
  body.android .stWrap,
  body.android .orderViews,
  body.android .detailsConfirm,
  body.android .detailsConfirmWrap {
    overscroll-behavior-y: contain;
  }
  .android .detailsConfirmWrap:not(.active) .backToOrder {
    visibility: hidden;
  }
  .detailsConfirm.detailFinal > div.fixedDiv {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 20px;
    padding-top: 14px;
  }
}
@media all and (min-width: 768px) and (max-width: 991px) {
  .halfDiv > .halfHalfwImage > span {
    top: 44%;
    font-size: 2.8em;
  }
  .itemBoxes,
  .mbsc-lv .itemBoxes.boxWrapper {
    /* width: 25% !important; */
    /* max-width: 182px; */
  }
  .container-fluid > .navbar-header {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    text-align: center;
  }

  .logoWrap {
    margin-left: auto;
    margin-right: auto;
  }
  .halfDiv span {
    top: 45%;
    left: 58px;
    font-size: 4.5em;
  }
  .halfDiv span img {
    width: 30px;
  }
  .halfDivs {
    max-width: 520px;
    margin-top: 42px;
  }
  .halfDiv {
    height: 513px;
  }

  .halfHalfwImage span {
    left: 0;
    font-size: 2.5em;
    top: 234px;
  }

  /*  .orderViews .imgView {
    height: 75%;
  }*/

  tr.cartRow.halfHalfOK > td.cItemName,
  table tr.cartRow > td.cItemName.bigName,
  table tr.cartRow > td.cItemName {
    top: 173px;
  }

  tr.cartRow.halfHalfOK .cItemImg img {
    top: -65px;
  }

  table tr.cartRow > td.cItemImg {
    height: 215px;
  }

  .detailsConfirm.detailFinal.detailPayment .detailsTitle img {
    width: auto;
  }
  .halfDiv:first-child > div > span {
    left: 25%;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 20%;
  }
}
/*--mobile media queries--*/

@media all and (max-width: 767px) {
  .halfDiv > .halfHalfwImage {
    float: right;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 19%;
  }
  .halfDiv:first-child > div > span {
    left: 23%;
  }
  .nav.nav-tabs .swiper-wrapper {
    transition: all 0.2s ease !important;
  }
  .detailsConfirm.active.true {
    top: -125px;
    height: calc(100% + 125px);
  }
  /* .detailsConfirm.active.true ~ .nextDetails {
        visibility: hidden;
    } */

  .backToOrder.thickcon-Left-Chevron.true {
    top: -50px;
  }
  .halfDiv {
    width: 48%;
    max-height: 328px;
    height: 81%;
  }
  .halfDiv > div > img {
    height: 100%;
    width: auto;
    display: block;
  }
  .halfDiv > div {
    width: 100%;
    float: left;
    height: 100%;
  }
  /* .itemWrap .swiper-container-autoheight{overflow-x: visible;} */
  /* .itemWrap2 .swiper-slide:not(.swiper-slide-visible) {
        transition: all 0s linear !important;
    }  */
  .halfDiv {
    margin-top: 15px;
  }
  .logoWrap {
    margin-left: auto;
    margin-right: auto;
  }
  img.logo {
    object-position: center;
  }
  ul.nav.nav-tabs {
    margin-top: 3px;
  }
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    width: 33.33%;
  }
  .slidebar,
  .slidebarInner.swiper-slide {
    bottom: 0px;
  }
  .appleDevice .slidebarInner.swiper-slide {
    bottom: 1px;
  }
  .navWrap ul li a {
    font-size: 1.176em;
    --current-tracking-Roboto: 0.05em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  .prodPrice {
    font-size: 10.82px;
  }
  .prodname {
    font-size: 1.946em;
    --current-tracking-Roboto: 0.021em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  span.innerIng {
    font-size: 1.3em;
    --current-tracking-Roboto: 0.01em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    min-height: 61px;
  }
}

@media all and (max-width: 340px) {
  .halfDiv {
    margin-top: 10px;
    max-height: 277px;
  }
  .loadBoxWrap > div > div {
    margin-bottom: 56px;
  }
  .loadBoxWrap > div > div img {
    min-height: 135px;
  }
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    min-height: 190px;
  }
  span.innerIng {
    padding-bottom: 4px;
    min-height: 57px;
  }
  .halfDiv span {
    font-size: 2.5em;
    top: 44%;
  }
  .imgWrap {
    max-height: 135px;
  }
  .navWrap ul li a {
    font-size: 1.05em;
  }
  span.orderBubble {
    padding: 8px 13px;
    font-size: 1.3em;
  }
  span.orderBubble > i {
    padding-top: 4px;
  }
  .specialsSlide .imgWrap {
    max-height: 150px;
  }
  .specialsSlide .itemBoxes,
  .specialsSlide .itemBoxes.boxWrapper {
    max-height: 190px;
  }
  .discountBar > div.specialDisc.equal {
    padding-left: 5px;
    padding-right: 5px;
    width: 45%;
  }
  .discountBar > div.specialDisc.equal ~ .timeDisc {
    width: 55%;
  }
  .discountBar > div {
    font-size: 1.35em;
  }
  .discountBar.hideSpec > div.specialDisc.equal ~ .timeDisc {
    width: 100%;
  }
  .nav.nav-tabs .swiper-wrapper {
    margin-top: 1px;
  }
  .discountBar > div.specialDisc:not(.equal) {
    padding-right: 5px;
    --current-tracking-Roboto: 0em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
    width: 42%;
  }
  .discountBar > div.specialDisc:not(.equal) ~ div.timeDisc {
    width: 58%;
    padding-left: 0px;
    padding-right: 0px;
    --current-tracking-Roboto: 0em;
    letter-spacing: calc(
      var(--current-tracking-Roboto) - var(--tracking-offset-Roboto-normal, 0em)
    );
  }
  .timeDisc i {
    margin-right: 5px;
    margin-left: 5px;
    top: 3px;
  }
  .timeDisc span {
    right: -6px;
    top: 3px;
  }
  .discountBar > div.specialDisc:not(.equal) ~ div.timeDisc span {
    right: 5px;
  }
  .discountBar > div.timeDisc {
    padding-top: 10px;
  }
  .innerSP {
    height: 130px;
  }

  .detailsTitle,
  .detailsTitle.sP.half {
    font-size: 2.5em;
    line-height: 28px;
  }

  .innerSP > div {
    font-size: 2em;
  }

  .innerSP > span {
    font-size: 2em;
  }

  .innerSP > span img {
    top: -2px;
    width: 14px;
  }
  table tr.cartRow > td.cItemImg {
    height: 145px;
  }

  tr.cartRow.halfHalfOK > td.cItemName,
  table tr.cartRow > td.cItemName.bigName {
    top: 85px;
  }

  table tr.cartRow > td.cItemName {
    font-size: 2em;
  }

  table tr.cartRow > td.cItemName {
    top: 109px;
  }
  .prodname {
    font-size: 1.8em;
  }
  .detailsTitle.sP.half img {
    top: -1px;
    right: 2px;
  }
  .detailsTitle.sP.half span.voucher i.thickcon-Checkmark {
    top: 1px;
  }
  .cardCommon.card .cardNumber input {
    width: 78%;
  }
  .oneDel > p:first-child {
    font-size: 15px;
  }
  .innerSP > div.activeName {
    font-size: 1.6em;
    padding: 20px 7px 5px;
  }
  .bSp {
    margin-bottom: 0;
  }
}
@media all and (max-width: 375px) {
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    width: 50%;
    /* max-width: 167px; */
  }
}
@media all and (max-width: 500px) {
  .itemBoxes,
  .tabsSlider--tab .itemBoxes.boxWrapper {
    width: 50%;
  }
  .tabsSlider--tab .specialsSlide .itemBoxes,
  .tabsSlider--tab .specialsSlide .itemBoxes.boxWrapper {
    width: 100% !important;
    max-width: none;
  }
}
@media all and (max-height: 645px) {
  .halfDiv > div.halfHalfwImage {
    width: 95%;
  }
  .halfDiv:not(:first-child) > div.halfHalfwImage {
    float: left;
  }
}
@media all and (max-height: 630px) {
  .halfDiv > div.halfHalfwImage {
    width: 88%;
  }
}
@media all and (max-height: 600px) {
  .halfDiv span {
    font-size: 2.5em;
    top: 44%;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 17%;
  }
  .halfDiv:first-child > div > span {
    left: 37%;
  }
  .halfDiv span img {
    margin-right: 5px;
  }
  .halfDiv > div.halfHalfwImage {
    width: 81%;
  }
  .halfDiv:first-child > div.halfHalfwImage {
    float: right;
  }
  .halfDiv .halfHalfwImage span {
    top: 36.5%;
  }
}
@media all and (max-height: 555px) {
  .halfDiv > div.halfHalfwImage {
    width: 72%;
  }
}

@media all and (max-height: 542px) {
  .halfDiv span {
    font-size: 1.8em;
    top: 46%;
  }
  .halfDiv:first-child > div.halfHalfwImage {
    float: right;
  }
  .halfDiv > div.halfHalfwImage {
    width: 68%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1.5em;
    top: 35%;
  }
  .halfDiv span img {
    width: 15px;
    margin-right: 5px;
  }
  .halfDiv:first-child > div > span {
    left: 75px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 16%;
  }
}
@media all and (max-height: 510px) {
  .halfDiv span {
    font-size: 1.6em;
    top: 47%;
  }
  .halfDiv:first-child > div > span {
    left: 55%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1.2em;
    top: 34.5%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 60%;
  }
  .halfDiv span img {
    width: 10px;
    margin-right: 5px;
    top: -1px;
  }

  .halfDiv:not(:first-child) > div > span {
    left: 14%;
  }
}

@media all and (max-height: 473px) {
  .halfDiv span {
    font-size: 1.4em;
  }
  .halfDiv:first-child > div > span {
    left: 61%;
  }
  .halfDiv > .halfHalfwImage > span {
    top: 32%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 49%;
  }
  .halfDiv span img {
    width: 10px;
    margin-right: 5px;
    top: -1px;
  }

  .halfDiv:not(:first-child) > div > span {
    left: 12%;
  }
}
@media all and (max-width: 320px) and (max-height: 500px) {
  .halfDiv:first-child > div > span {
    left: 50%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 63%;
  }
}
@media all and (min-width: 992px) and (max-height: 700px) {
  .halfDiv span {
    font-size: 3em;
    top: 46%;
  }
  .halfDiv span img {
    width: 19px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 18%;
  }
  .halfDiv:first-child > div > span {
    left: 28%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 95%;
  }
  .halfDiv:first-child > div.halfHalfwImage {
    float: right;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1.8em;
    top: 41%;
    left: 0 !important;
  }
}
@media all and (min-width: 992px) and (max-height: 650px) {
  .halfDiv span {
    top: 45%;
  }
  .halfDiv span img {
    width: 17px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 12%;
  }
  .halfDiv:first-child > div > span {
    left: 35%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 80%;
  }
}
@media all and (min-width: 992px) and (max-height: 600px) {
  .halfDiv span {
    top: 45%;
    font-size: 2.5em;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 10%;
  }
  .halfDiv:first-child > div > span {
    left: 45%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 68%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1.5em;
    top: 38%;
  }
}
@media all and (min-width: 992px) and (max-height: 560px) {
  .halfDiv span {
    top: 45%;
    font-size: 2em;
  }
  .halfDiv span img {
    width: 14px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 10%;
  }
  .halfDiv:first-child > div > span {
    left: 54%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 55%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1.4em;
    top: 35%;
  }
}
@media all and (min-width: 992px) and (max-height: 535px) {
  .halfDiv span {
    top: 45%;
    font-size: 1.5em;
  }
  .halfDiv span img {
    width: 11px;
  }
  .halfDiv:first-child > div > span {
    left: 62%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 48%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1.2em;
    top: 30%;
  }
}
@media all and (min-width: 992px) and (max-height: 500px) {
  .halfDiv span {
    top: 45%;
    font-size: 1.3em;
  }
  .halfDiv span img {
    width: 9px;
    top: -1px;
    margin-right: 5px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 7%;
  }
  .halfDiv:first-child > div > span {
    left: 69%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 37%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1.1em;
    top: 24%;
  }
}
@media all and (min-width: 992px) and (max-height: 480px) {
  .halfDiv span {
    top: 47%;
    font-size: 1.1em;
  }
  .halfDiv span img {
    width: 9px;
    top: -1px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 5%;
  }
  .halfDiv:first-child > div > span {
    left: 72%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 35%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 1em;
    top: 23%;
  }
}
@media all and (min-width: 992px) and (max-height: 460px) {
  .halfDiv span {
    top: 47%;
    font-size: 0.7em;
  }
  .halfDiv span img {
    width: 7px;
    top: -1px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 3%;
  }
  .halfDiv:first-child > div > span {
    left: 78%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 22%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 0.5em;
    top: 8%;
  }
}
@media all and (min-width: 992px) and (max-height: 430px) {
  .halfDiv span {
    top: 47%;
    font-size: 0.5em;
  }
  .halfDiv span img {
    width: 3px;
    top: -1px;
  }
  .halfDiv:not(:first-child) > div > span {
    left: 2%;
  }
  .halfDiv:first-child > div > span {
    left: 85%;
  }
  .halfDiv > div.halfHalfwImage {
    width: 15%;
  }
  .halfDiv > .halfHalfwImage > span {
    font-size: 0.3em;
    top: -20%;
  }
}
/* @media all and (max-height:400px) {
    .halfDiv span {
        font-size: 1.4em;
        top: 47%;
    }
    .halfDiv:first-child>div>span{left:120px; top: 32%}
    .halfDiv:first-child > .halfHalfwImage > span{font-size: 1.2em;top: 15%}
    .halfDiv > div.halfHalfwImage{width: 30%}
    .halfDiv span img{width:10px; margin-right:5px;top: -1px;}

    .halfDiv:not(:first-child)>div>span{left:8px; max-width: 20px;top: 32%}
} */

@media (max-width: 1250px) and (min-width: 600px) {
  .specialsSlide .itemBoxes,
  .specialsSlide .itemBoxes.boxWrapper {
    width: 50%;
  }
}
@media (max-width: 1300px) and (min-width: 1251px) {
  .specialsSlide .itemBoxes,
  .specialsSlide .itemBoxes.boxWrapper {
    width: 33.33%;
  }
}
@media (max-width: 600px) {
  .specialsSlide .itemBoxes,
  .specialsSlide .itemBoxes.boxWrapper {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .container-menu-item .powered-by-container {
    display: none;
  }
}
