.geosuggest-ui {
}

.geosuggest-ui .geosuggest__input-wrapper {
  @apply overflow-hidden relative z-15;
}

.geosuggest-ui .geosuggest__input {
  @apply relative z-3  flex w-full items-center justify-start truncate font-normal leading-none   outline-primary-outline h-13.25  sm:h-12.5 rounded-2.5 border-0  py-1 pl-14.5 placeholder:tracking-s-tight pr-2 text-base placeholder:text-base placeholder:text-gray-500  md:placeholder:text-3.75;
}

.geosuggest-ui .geosuggest__input[aria-expanded='true'] {
  @apply rounded-b-none;
}

.geosuggest-ui .geosuggest__suggests-wrapper {
  @apply relative z-10 w-[calc(100%_+_2px)] -ml-px -mr-px;
}

.geosuggest-ui .geosuggest__suggests {
  @apply block  max-h-75  absolute top-0 z-10 w-full overflow-x-hidden  overflow-y-auto rounded-2.5 rounded-t-none bg-white border border-t-0 border-secondary-darker shadow-[0px_2px_4px_-8px_rgba(0,0,0,0.02),0px_5px_8px_-5px_rgba(0,0,0,0.02),0px_0px_8px_-2px_rgba(108,108,108,0.28)];
}

.geosuggest-ui .geosuggest__suggests.geosuggest__suggests--hidden {
  @apply hidden;
}

.geosuggest-ui .geosuggest__item {
  @apply border-b border-gray-200  first-of-type:border-t last-of-type:border-b-0;
}
